import css from "./emoji.scss?inline";
import globalStyles from "../../index.scss?inline";
import { ArgSpecDictionary } from "../component-utils";
import { EmojiType } from "./EmojiType";
import { Component } from "../../utils/Component";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "[HHDSEmoji]";
export const HHDSEmojiTagName: string = "hhds-emoji";

export class HHDSEmoji extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    let emoji: string = "";
    const emojiType = this.vars.get<EmojiType>("type");
    switch (emojiType) {
      case EmojiType.Angry:
        emoji = "😡";
        break;
      case EmojiType.Sad:
        emoji = "😢";
        break;
      case EmojiType.Laugh:
        emoji = "😂";
        break;
      case EmojiType.Smile:
      default:
        emoji = "😊";
        break;
    }
    this.shadow.innerHTML = `
				<style>${globalStyles}</style>
				<style>${css}</style>
				<div class="hhds-emoji">${emoji}</div>
		`;
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  type: {
    description: "Type of emoji to display.",
    defaultValue: EmojiType.Smile,
    typeString: "EmojiType",
    type: EmojiType,
  },
};
