import css from './mastheadandimage.scss?inline';
import globalStyles from '../../index.scss?inline';
import { ArgSpecDictionary, TypedVars } from "../component-utils";
import { HHDSButton, HHDSButtonEvent } from "../Button/Button";

// modal
import { HHDSModal, HHDSModalEvent } from "../Modal/Modal";


const DEBUG_VERBOSE: boolean  = false;
const CLASS_NAME: string = 'HHDSMastheadAndImage';
const TAG_NAME: string = 'hhds-mastheadandimage';
export const HHDSMastheadAndImageTagName: string = "hhds-mastheadandimage";

import { HHDSMastheadAndImageType } from './MastheadAndImageType';

// ////////////////////////////////////////////////////////////////////

export class HHDSMastheadAndImage extends HTMLElement {
	private vars: TypedVars = new TypedVars(this);
	private shadow: ShadowRoot;

	private exploreButton!: HHDSButton;
	private playButton!: HHDSButton;
	private anchor!: HTMLDivElement;

	// modal
	private buttonClickFunc: any;
	private videoButtonClickFunc: any;
	private modalHideFunc: any;

	constructor() {
		super();
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'constructed');

		this.shadow = this.attachShadow({ mode: 'open' });
		if (!this.shadow) {
			return;
		}

		this.buttonClickFunc = (_event: Event) => {
			this.scroll();
		};

		this.videoButtonClickFunc = (_event: Event) => {
			let modal = this.shadow.querySelector("hhds-modal") as HHDSModal;
			if (modal) {
			  if (!modal.coordinator) {
				console.log("Creating coordinator");
				modal.createCoordinator();
			  }
			  this.updateModalVisibility(true, true);
			}
		};

		this.modalHideFunc = (_event: Event) => {
			this.updateModalVisibility(false, false);
		  };
	}

	static get observedAttributes() {
		return Object.keys(ArgSpecs);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// Lifecycle Methods
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.
	connectedCallback() {

		this.parseAttributes();

		this.render();

		console.log(`[${TAG_NAME}] Initialised`);

		this.exploreButton = this.shadow.querySelector(`hhds-button[href="#mastheadandimage"]`) as HHDSButton;
		this.exploreButton?.addEventListener(HHDSButtonEvent.click, this.buttonClickFunc);

		this.anchor = this.shadow.querySelector(`#mastheadandimage`) as HTMLDivElement;

		// modal
		this.playButton = this.shadow.querySelector(`hhds-button[data-type="video"]`) as HHDSButton;
		this.playButton?.addEventListener(HHDSButtonEvent.click, this.videoButtonClickFunc);

		const modal = this.shadow.querySelector("hhds-modal") as HHDSModal;
		modal?.addEventListener(HHDSModalEvent.hide, this.modalHideFunc);
	}

	scroll(): void {
		this.anchor.scrollIntoView({ behavior: "smooth", block: "start" });
	}

	parseAttributes() {
		this.vars.parse(this, ArgSpecs);
	}

	updateModalVisibility(visible: boolean, applyToModal: boolean): void {
		const modal = this.shadow.querySelector("hhds-modal") as HHDSModal;
		console.log("[MastheadAndImage] updateModalVisibility, visible=" + visible);
		if (!modal) return;

	
		if (applyToModal) {
		  if (visible) {
			modal.show();
			modal.setVideoPlaying(true, false);
		  } else {
			modal.hide();
			modal.setVideoPlaying(false);
		  }
		}
	  }

	render() {
		const type = this.vars.get<HHDSMastheadAndImageType>('type'),
			src = this.vars.get<string>('src'),
			cover = this.vars.get<string>('cover');

		let headerColumnClasses,
			imageColumnClasses,
			footerColumnClasses;

		switch (type) {
			case 'building':
				headerColumnClasses = 'col-span-6 sm:col-span-4 md:col-span-5',
				imageColumnClasses = 'sm:col-start-5 md:col-start-9 col-span-6 sm:col-span-4',
				footerColumnClasses = 'col-span-6 sm:col-span-4 md:col-span-5';

				break;
			case 'story':
				headerColumnClasses = 'col-span-6 sm:col-span-4 md:col-span-6',
				imageColumnClasses = 'sm:col-start-5 md:col-start-7 col-span-6 sm:col-span-4 md:col-span-6',
				footerColumnClasses = 'col-span-6 sm:col-span-4 md:col-span-5 lg:col-span-4';

				break;
			default:
				headerColumnClasses = 'col-span-6 sm:col-span-4 md:col-span-7 lg:col-span-8',
				imageColumnClasses = 'col-span-6 sm:col-start-5 md:col-start-9 sm:col-span-4',
				footerColumnClasses = 'col-span-6 sm:col-span-4 md:col-span-5 lg:col-span-4';

				break;
		}

		this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="hhds-mastheadandimage">
				<div class="container">
					<div class="grid grid--mastheadandimage">
						<div class="${headerColumnClasses}">
							<div class="hhds-mastheadandimage__info">
								<slot name="tags"></slot>
								<slot name="heading"></slot>
								<slot name="header-text"></slot>
							</div>
						</div>
						<div class="${imageColumnClasses}">
							<slot name="image"></slot>
						</div>
						<div class="${footerColumnClasses}">
							<slot name="footer-text"></slot>
							<slot name="buttons"></slot>
							<hhds-buttongroup orientation="horizontal" style="--buttongroup-gap: var(--spacing-spacer-12);">
								<slot name="contact-button"></slot>
								<slot name="download-button"></slot>
								${ type == 'location' && src.length > 0 ? `<hhds-button data-type="video" type="secondary">PLAY VIDEO<hhds-icon slot="start" type="play"></hhds-icon></hhds-button>` : ``}
								${ type == 'location' ? `<hhds-button href="#mastheadandimage" target="_self" type="secondary">EXPLORE<hhds-icon slot="end" type="arrowdown"></hhds-icon></hhds-button>` : ``}
							</hhds-buttongroup>
						</div>
					</div>
				</div>
				<hhds-modal>
					<hhds-video-vimeo
						id="in-modal"
						src="${src}"
						autoplay="false"
						loop="false"
						controls="true"
						hide-play-button="true"
						cover="${cover}">
					</hhds-video-vimeo>
				</hhds-modal>
				<div id="mastheadandimage"></div>
			</div>
		`;
	}

	// Invoked each time the custom element is disconnected from the document's DOM.
	disconnectedCallback() {
		this.exploreButton?.removeEventListener(HHDSButtonEvent.click, this.buttonClickFunc);
	}

	// Invoked each time the custom element is moved to a new document.
	adoptedCallback() {}

	// Invoked each time one of the custom element's attributes is added, removed, or changed.
	attributeChangedCallback(name: string, oldValue: string, newValue: string) {
		DEBUG_VERBOSE && console.log(`Attribute ${name} has changed from ${oldValue} to ${newValue}.`);
		this.parseAttributes();
		this.render();
	}

}

export const ArgSpecs: ArgSpecDictionary = {
	type: {
		description: `Type of masthead and image: "story", "building", "location".`,
		defaultValue: HHDSMastheadAndImageType.Story,
		type: HHDSMastheadAndImageType,
	},
	src: {
		description: `The URL of the vimeo video`,
		defaultValue: "",
		type: String,
	},
	cover: {
		description: `The URL of the cover image for the modal, if applicable.`,
		defaultValue: "",
		type: String,
	}
};

