import css from "./accordion.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";
import {
  HHDSAccordionItem,
  HHDSAccordionItemEvent,
  HHDSAccordionItemTagName,
} from "../AccordionItem/AccordionItem";

const DEBUG_VERBOSE: boolean = false;
export const HHDSAccordionTagName: string = "hhds-accordion";
const TAG_NAME: string = HHDSAccordionTagName;
const LOG_PREFIX: string = `[${TAG_NAME}]`;

export const HHDSAccordionAttrNames = {};

//const Attrs = HHDSAccordionAttrNames;

export class HHDSAccordion extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(LOG_PREFIX, "init");

    /*this.shadow.innerHTML = `
			<style>${css}</style>
      <div class="container">
        <div class="grid">
          <div class="col-span-4">
			      <div class="${TAG_NAME}">
              <div class="items"><slot></slot></div>
            </div>
          </div>
        </div>
      </div>
		`;*/

    this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
      <div class="${TAG_NAME}">
        <div class="items"><slot></slot></div>
      </div>
		`;

    this.observeSlotChanges(true);
  }

  private addSlotListeners(_slot: HTMLSlotElement, elements: Element[]): void {
    //console.log("[Accordion] 'slotchange'", slot, elements);
    const items = elements.filter((el) => el.tagName.toLowerCase() === HHDSAccordionItemTagName);
    const onItemOpened = (item: HHDSAccordionItem, event: any): void => {
      DEBUG_VERBOSE && console.log(event);
      this.emitEvent(HHDSAccordionItemEvent.open, { item, userInvoked: event.detail.userInvoked });
      (items as HHDSAccordionItem[]).forEach((otherItem: HHDSAccordionItem) => {
        if (otherItem !== item) otherItem.setExpanded(false, true);
      });
    };

    items.forEach((item) => {
      item.addEventListener(HHDSAccordionItemEvent.open, (event: Event) => {
        onItemOpened(event.target as HHDSAccordionItem, event);
      });
      item.addEventListener(HHDSAccordionItemEvent.close, (_event: Event) => {
        this.emitEvent(HHDSAccordionItemEvent.close, item);
      });
    });

    if (items.length > 0) {
      DEBUG_VERBOSE && console.log(LOG_PREFIX, "Will set item 0 to expanded");
      (items[0] as HHDSAccordionItem).setExpanded(true, false);
    }
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(LOG_PREFIX, "destroy");
    this.observeSlotChanges(false);
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(LOG_PREFIX, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(slot: HTMLSlotElement, elements: Element[]): void {
    /*if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }*/
    this.addSlotListeners(slot, elements);
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  /*[Attrs.myNumericArg]: {
    description: "An argument to demonstrate the typed args system.",
    defaultValue: 42,
    type: Number,
  },*/
};
