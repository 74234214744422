import css from "./videovimeo.scss?inline";
import globalStyles from "../../index.scss?inline";
import Player from "@vimeo/player";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSVideoEvent } from "../../utils/VideoTypes";
import { HHDSButton, HHDSButtonEvent } from "../Button/Button";
import { Component } from "../../utils/Component";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "[HHDSVideoVimeo]";
export const HHDSVideoVimeoTagName: string = "hhds-video-vimeo";
const TAG_NAME: string = HHDSVideoVimeoTagName;

export const HHDSVideoVimeoAttrNames = {
  src: "src",
  cover: "cover",
  controls: "controls",
  autoplay: "autoplay",
  loop: "loop",
  aspect: "aspect",
  loopPoint: "loop-point",
  startPoint: "start-point",
  hidePlayButton: "hide-play-button",
  recreatePlayer: "recreate-player",
  mute: "mute",
};

const Attrs = HHDSVideoVimeoAttrNames;

export class HHDSVideoVimeo extends Component {
  private listenerFuncs: { [key: string]: EventListener } = {};
  private player: Player | null = null;
  private loopPoint: number = 0;
  private startPoint: number = 0;
  private started: boolean = false;
  private coverElement: HTMLElement | null = null;

  constructor() {
    super();
    this.createEventCallbacks();
  }

  private createEventCallbacks(): void {
    this.listenerFuncs["click"] = () => this.play();
    this.listenerFuncs["loaded"] = () => this.onVideoLoaded();
    this.listenerFuncs["bufferstart"] = () => this.onVideoBufferStart();
    this.listenerFuncs["timeupdate"] = (event: Event) => this.onVideoTimeUpdate(event);
    this.listenerFuncs["play"] = () => this.onVideoPlay();
    this.listenerFuncs["ended"] = () => this.onVideoEnded();
    this.listenerFuncs["pause"] = () => this.onVideoPaused();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    this.loopPoint = this.vars.get<number>(Attrs.loopPoint);
    this.startPoint = this.vars.get<number>(Attrs.startPoint);

    const src = this.vars.get<string>(Attrs.src);
    if (!src) return;

    const aspect = this.vars.get<string>(Attrs.aspect);

    let styles: string[] = [];
    styles.push("--button-min-width: 64px;");
    styles.push("--button-overlay-min-height: 60px;");
    styles.push("--button-overlay-min-height-md: 60px;");
    styles.push("--button-overlay-min-height-lg: 60px;");
    styles.push("--button-overlay-min-height-xl: 60px;");
    styles.push("--button-overlay-radius: 50%;");
    let iconHtml = `<hhds-icon type="play" slot="start" style="--icon-color: var(--color-neutral-white);"></hhds-icon>`;
    const buttonHtml: string = `<hhds-button type="overlay" style="${styles.join(" ")}">${iconHtml}</hhds-button>`;

    this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="${TAG_NAME}" style="--video-aspect-ratio: ${aspect};">
				<div class="${TAG_NAME}__container"></div>
				<div class="${TAG_NAME}__cover">
					${buttonHtml}
				</div>
			</div>
		`;

    if (this.vars.get<boolean>(Attrs.autoplay) || this.vars.get<boolean>(Attrs.hidePlayButton)) {
      const button = this.shadow.querySelector(`hhds-button`) as HHDSButton;
      button?.classList.toggle("hidden", true);
    }

    this.coverElement = this.shadow.querySelector(`.${TAG_NAME}__cover`) as HTMLElement;
    const coverImageUrlString = this.vars.get<string>(Attrs.cover),
      coverImageSrcSet = this.getAttribute("srcSet");
    if (coverImageUrlString) {
      this.coverElement.style.backgroundImage = `url('${coverImageUrlString}')`;
      this.coverElement.style.backgroundImage = `
        image-set(
          ${coverImageSrcSet}
        )
      `;
    } else {
      this.coverElement.remove();
    }

    if (!this.vars.get<boolean>(Attrs.recreatePlayer)) {
      this.createPlayer();
    }

    this.addGeneralListeners();
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    this.destroyPlayer();
    this.removeGeneralListeners();
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }

  /*private async getVideoInfo(player: Player): Promise<VideoInfo> {
    let width = await player.getVideoWidth();
    let height = await player.getVideoHeight();
    // const gcd = (a: number, b: number): number => (b ? gcd(b, a % b) : a);
    // const divisor = gcd(width, height);
    // width /= divisor;
    // height /= divisor;
    // console.log("Aspect ratio:", width + ":" + height);
    return { width, height, aspect: width / height };
  }*/

  /*private applyCoverFitStyling(width: number, height: number, element: HTMLElement): void {
    // See: https://codepen.io/bmcharg/pen/poVzWBq
    const cssWidth = `${100}%`;
    const cssMinWidth = `${(100 * width) / height}%`;
    const cssHeight = `${(100 * height) / width}%`;
    const cssMinHeight = `${100}%`;
    element.style.width = cssWidth;
    element.style.minWidth = cssMinWidth;
    element.style.height = cssHeight;
    element.style.minHeight = cssMinHeight;
    //element.style.aspectRatio = `${width} / ${height}`;
    console.log("---- Cover fit -------------------");
    console.log("width: ", width);
    console.log("height: ", height);
    console.log("aspect:", width / height);
    console.log("css width: ", cssWidth);
    console.log("css min-width: ", cssMinWidth);
    console.log("css height: ", cssHeight);
    console.log("css min-height: ", cssMinHeight);
    console.log("----------------------------------");
  }*/

  async isPlaying(): Promise<boolean> {
    if (!this.player) return Promise.resolve(false);
    let isPaused = await this.player.getPaused();
    return !isPaused;
  }

  pause(hideVideoContainer: boolean = false, showCover: boolean = false): void {
    this.player?.pause();
    if (hideVideoContainer) {
      const container = this.shadow.querySelector(`.${TAG_NAME}__container`) as HTMLElement;
      if (container) container.style.display = "none";
    }
    if (showCover) {
      this.toggleCover(true);
    }
  }

  unload(): void {
    this.player?.unload();
  }

  play(): void {
    const container = this.shadow.querySelector(`.${TAG_NAME}__container`) as HTMLElement;
    if (container) container.style.display = "block";
    this.player?.ready().then(() => {
      DEBUG_VERBOSE && console.log("[HHDSVideoVimeo] Ready to play: " + this.id);
      this.player?.play();
    });
  }

  destroyPlayer(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Destroying player");
    this.player && this.removeVideoListeners(this.player);
    this.player?.destroy();
    this.player = null;
  }

  createPlayer(): void {
    const container = this.shadow.querySelector(`.${TAG_NAME}__container`) as HTMLElement;
    const src = this.vars.get<string>(Attrs.src);
    const autoplay = this.vars.get<boolean>(Attrs.autoplay);
    const showControls = this.vars.get<boolean>(Attrs.controls);
    let muted: boolean = autoplay ? true : false;
    if (this.vars.get<boolean>(Attrs.mute) != undefined) {
      muted = this.vars.get<boolean>(Attrs.mute);
    }
    DEBUG_VERBOSE &&
      console.log(
        CLASS_NAME,
        "Creating Vimeo player API instance; autoplay=" + autoplay + ", muted=" + muted
      );
    this.player = new Player(container, {
      url: src,
      autoplay: autoplay,
      controls: showControls,
      loop: this.vars.get<boolean>(Attrs.loop),
      muted: muted,
    });
    this.addVideoListeners(this.player);
    this.toggleCover(true);
  }

  addVideoListeners(player: Player): void {
    if (!player) return;
    player.on("loaded", this.listenerFuncs["loaded"]);
    player.on("bufferstart", this.listenerFuncs["bufferstart"]);
    player.on("timeupdate", this.listenerFuncs["timeupdate"]);
    player.on("play", this.listenerFuncs["play"]);
    player.on("ended", this.listenerFuncs["ended"]);
    player.on("pause", this.listenerFuncs["pause"]);
  }

  removeVideoListeners(player: Player): void {
    if (!player) return;
    player.off("loaded", this.listenerFuncs["loaded"]);
    player.off("bufferstart", this.listenerFuncs["bufferstart"]);
    player.off("timeupdate", this.listenerFuncs["timeupdate"]);
    player.off("play", this.listenerFuncs["play"]);
    player.off("ended", this.listenerFuncs["ended"]);
    player.off("pause", this.listenerFuncs["pause"]);
  }

  addGeneralListeners(): void {
    const button = this.shadow.querySelector(`hhds-button`) as HHDSButton;
    button?.addEventListener(HHDSButtonEvent.click, this.listenerFuncs["click"]);
  }

  removeGeneralListeners(): void {
    const button = this.shadow.querySelector(`hhds-button`) as HHDSButton;
    button?.removeEventListener(HHDSButtonEvent.click, this.listenerFuncs["click"]);
  }

  toggleCover(visible: boolean) {
    DEBUG_VERBOSE && console.log("[HHDSVideoVimeo] Toggling cover: " + this.id, "visible=", visible);
    if (this.coverElement) this.coverElement.style.display = visible ? "flex" : "none";
  }

  onVideoLoaded() {
    DEBUG_VERBOSE && console.log("[HHDSVideoVimeo] Video loaded: " + this.id);
    this.emitEvent(HHDSVideoEvent.loaded);
  }

  onVideoBufferStart() {
    DEBUG_VERBOSE && console.log("[HHDSVideoVimeo] Video started to buffer: " + this.id);
    this.emitEvent(HHDSVideoEvent.bufferStart);
  }

  onVideoTimeUpdate(update: any) {
    if (!this.started) {
      this.started = true;
    }
    if (this.loopPoint > 0 && parseInt(update.seconds, 10) >= this.loopPoint) {
      this.loopPlayback();
    }
    this.emitEvent(HHDSVideoEvent.timeUpdate);
  }

  onVideoPlay() {
    DEBUG_VERBOSE && console.log("[HHDSVideoVimeo] Video playing: " + this.id);
    this.toggleCover(false);
    this.emitEvent(HHDSVideoEvent.play);
  }

  onVideoEnded() {
    DEBUG_VERBOSE && console.log("[HHDSVideoVimeo] Video ended: " + this.id);
    if (!this.vars.get<boolean>(Attrs.controls)) {
      this.loopPlayback();
    } else {
      const button = this.shadow.querySelector(`hhds-button`) as HHDSButton;
      button?.classList.toggle("hidden", false);
      this.emitEvent(HHDSVideoEvent.ended);
      this.toggleCover(true);
    }
  }

  onVideoPaused() {
    DEBUG_VERBOSE && console.log("[HHDSVideoVimeo] Video paused: " + this.id);
    this.emitEvent(HHDSVideoEvent.pause);
  }

  async playAtTime(time: number): Promise<boolean> {
    try {
      await this.player?.setCurrentTime(time);
      this.player?.play();
      return true;
    } catch (error) {
      console.error("Error playing video at time: ", error);
    }
    return false;
  }

  loopPlayback() {
    DEBUG_VERBOSE && console.log(`Should loop at ${this.loopPoint} to ${this.startPoint}`);
    this.playAtTime(this.startPoint).then((success) => {
      if (success) this.emitEvent(HHDSVideoEvent.loop);
    });
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.src]: {
    description: "The Vimeo video URL.",
    defaultValue: "",
    type: String,
  },
  [Attrs.cover]: {
    description: "The URL of the cover image.",
    defaultValue: "",
    type: String,
  },
  [Attrs.controls]: {
    description: "Whether to show the video controls.",
    defaultValue: true,
    type: Boolean,
  },
  [Attrs.autoplay]: {
    description: "Whether to autoplay the video.",
    defaultValue: false,
    type: Boolean,
  },
  [Attrs.loop]: {
    description: "Whether to loop the video.",
    defaultValue: false,
    type: Boolean,
  },
  [Attrs.aspect]: {
    description: "The aspect ratio of the video.",
    defaultValue: "16/9",
    type: String,
  },
  [Attrs.loopPoint]: {
    description: "A custom time (instead of the end of the video) at which to loop the video.",
    defaultValue: 0,
    type: Number,
  },
  [Attrs.startPoint]: {
    description: "The time to which the video should be rewinded after looping.",
    defaultValue: 0,
    type: Number,
  },
  [Attrs.hidePlayButton]: {
    description:
      "Whether to hide the play button _(used by parent components that don't want a video to autoplay)_",
    defaultValue: false,
    type: Boolean,
  },
  [Attrs.recreatePlayer]: {
    description: "Whether to destroy & recreate the player on uses, including pause/play cycles.",
    defaultValue: false,
    type: Boolean,
  },
  [Attrs.mute]: {
    description: "Whether mute the video. If undefined, this is determined by autoplay status.",
    defaultValue: undefined,
    type: Boolean,
  },
};
