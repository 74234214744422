import css from './checkboxbutton.scss?inline';
import globalStyles from '../../index.scss?inline';
import { ArgSpecDictionary, TypedVars } from "../component-utils";
import { HHDSCheckboxButtonEvents } from './CheckboxButtonEvents';

const DEBUG_VERBOSE: boolean  = false;
const CLASS_NAME: string = 'HHDSCheckboxButton';
const TAG_NAME: string = 'hhds-checkboxbutton';
export const HHDSCheckboxButtonTagName: string = "hhds-checkboxbutton";

// ////////////////////////////////////////////////////////////////////

export class HHDSCheckboxButton extends HTMLElement {

  private vars: TypedVars = new TypedVars(this);
	private shadow: ShadowRoot;
	private _checkbox: HTMLInputElement | null = null;

	public checked!: boolean | string;
	public value: string = "";

	constructor() {
		super();
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'constructed');

		this.shadow = this.attachShadow({ mode: 'open' });
		if (!this.shadow) {
			return;
		}

	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// Lifecycle Methods
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.
	connectedCallback() {

		this.parseAttributes();

		this.render();

		this.attachFormDataListener();

	}

  	parseAttributes() {
    	this.vars.parse(this, ArgSpecs);
	}

	render() {
		const checked = this.checked !== undefined ? this.checked : this.vars.get<boolean>("checked");
		this.value = this.vars.get<string>("value");

		this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="hhds-checkboxbutton">
				${this.renderInputEl(checked, this.value)}
				<span class="hhds-checkboxbutton__checkmark"></span>
				<label class="hhds-checkboxbutton__label ui" for="checkbox"><slot></slot></label>
			</div>
		`;

		this._checkbox = this.shadow.querySelector('input[type="checkbox"]');
		this._checkbox?.addEventListener('change', this.onCheckboxUpdated.bind(this));

	}

    renderInputEl(checked: string | boolean, value : string){
        return `<input type="checkbox" class="hhds-checkboxbutton__input" ${checked ? 'checked' : ''} value="${checked ? value : false }" id="checkbox"/>`
    }

	attachFormDataListener() {
		const form = this.closest('form');

        DEBUG_VERBOSE && console.log(CLASS_NAME, this.attachFormDataListener.name, form);

		if (form) {
            form.addEventListener('formdata', (event) => {
                DEBUG_VERBOSE && console.log(CLASS_NAME, this.attachFormDataListener.name, {event});

                const data = this.checked ? this.checked.toString() : 'false';

				event.formData.append(this.vars.get<string>("name"), data);
                DEBUG_VERBOSE && console.log(CLASS_NAME, this.attachFormDataListener.name, event.formData);
			});
		}
	}

	onCheckboxUpdated() {
		this.checked = this._checkbox?.checked ? this.value : false;
		DEBUG_VERBOSE && console.log(`[${TAG_NAME}] Checkbox status updated to ${this.checked}`);

        const detail = { target: this, checked: this.checked };

        // Create a hook so we can tell when value has changed
        const customEvent = new CustomEvent(HHDSCheckboxButtonEvents.Changed, { detail });

        console.log({customEvent});


        this.dispatchEvent(customEvent);
	}

    resetCheckedState(){
        DEBUG_VERBOSE && console.log(CLASS_NAME, this.resetCheckedState.name);

        this.checked = false;

        this.render();
    }

	// Invoked each time the custom element is disconnected from the document's DOM.
	disconnectedCallback() {}

	// Invoked each time the custom element is moved to a new document.
	adoptedCallback() {}

	// Invoked each time one of the custom element's attributes is added, removed, or changed.
	attributeChangedCallback() {}

}

export const ArgSpecs: ArgSpecDictionary = {
	"name": {
		description: "The name that is assigned to the checkbox",
		defaultValue: "",
		type: String,
	},
	"value": {
		description: "The value that is assigned when the checkbox is checked",
		defaultValue: "",
		type: String,
	},
	"checked": {
		description: "Whether the checkbox is checked or not",
		defaultValue: "",
		type: Boolean,
	}
};

