export enum HHDSTileGridv2Source {    
    People = 'people',
    Office = 'office',
    Location = 'location',  
    Community = 'community',
    Home = 'home',
    Press = 'press',
    Insight = 'insight',
    Default = 'custom'
}

export enum HHDSTileGridv2Type {
    Posts = 'dynamic',
    SelectedPosts = 'filtered',
    Category = 'category',
    Custom = 'custom' 
}

export enum HHDSTileGridv2Columns {    
    Three = 3,
    Four = 4
}

export enum HHDSTileAspectRatio {
    Intrinsic = "unset",
    Square = "1/1",
    ThreeByTwo = "3/2", 
    FourByThree = "4/3",
    SixteenByTen = "16/10",
    SixteenByNine = "16/9"
}