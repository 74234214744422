import css from './carousel.scss?inline';
import globalStyles from '../../index.scss?inline';
import { ArgSpecDictionary, TypedVars } from '../component-utils';
import swiperStyles from "../../../node_modules/swiper/swiper-bundle.min.css?inline";
import Swiper from 'swiper';
import { CarouselType } from './CarouselType';

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = 'HHDSCarousel';
const TAG_NAME: string = 'hhds-carousel';
export const HHDSCarouselTagName: string = 'hhds-carousel';

// ////////////////////////////////////////////////////////////////////

export class HHDSCarousel extends HTMLElement {
	private vars: TypedVars = new TypedVars(this);
	private shadow: ShadowRoot;

	private swiperContainer: HTMLElement | null = null;
	private swiperInstance: Swiper | null = null;

	private navVisible: boolean = false;

	private _prevButton: HTMLElement | null = null;
	private _nextButton: HTMLElement | null = null;

	private type: CarouselType | null = null;

	constructor() {
		super();
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'constructed');

		this.shadow = this.attachShadow({ mode: 'open' });

		if (!this.shadow) throw new Error('Unable to attach shadowRoot');

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

	}

	static get observedAttributes() {
		return Object.keys(ArgSpecs);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// Lifecycle Methods
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.
	connectedCallback() {
		this.parseAttributes();

		this.render();

		requestAnimationFrame(() => {
			this.initializeSwiper();
		});

		window.addEventListener('resize', () => this.checkNavVisibility());

		DEBUG_VERBOSE && console.log(`[${TAG_NAME}] Initialised`);
	}

	checkNavVisibility(forceUpdate: boolean = false) {	

			if (this.swiperInstance) {

				if (window.innerWidth >= 1024) {

					if (this.vars.get('nav') === 'false') {

						this.setNavVisibility(false, true);

					} else {

						const numSlides = typeof this.swiperInstance.slides.length === 'number' ? this.swiperInstance.slides.length : 0;
						const slidesInView = typeof this.swiperInstance.params.slidesPerView === 'number' ? this.swiperInstance.params.slidesPerView : numSlides;
						const visible = (slidesInView < numSlides);
						DEBUG_VERBOSE && console.log(`There is space for ${slidesInView} slide, and ${numSlides} slides`);
						DEBUG_VERBOSE && console.log(`Visibilty should be ${visible}`);
						this.setNavVisibility(visible, forceUpdate);

					}

				} else {

					this.setNavVisibility(false, true);

				}

			} else {

				DEBUG_VERBOSE && console.error("No swiper instance available");

			}

	}

	setNavVisibility(visible: boolean, forceUpdate: boolean) {

		if(this.navVisible !== visible || forceUpdate) {

			DEBUG_VERBOSE && console.log(`Setting nav visibility to ${visible}`);

			this.navVisible = visible;
			const nav = this.shadow.querySelector('hhds-buttongroup') as HTMLElement;
			if (nav) { 
				nav.style.display = visible ? 'flex' : 'none';	
			}
		}

	}

	parseAttributes() {
		this.vars.parse(this, ArgSpecs);

		this.type = this.vars.get('type');	

		DEBUG_VERBOSE && console.log(`this.vars`, this.vars);
	}

	render() {
		
		this.shadow.innerHTML = /* html */ `
		<style>${swiperStyles}</style>
		<style>${globalStyles}</style>
		<style>${css}</style>
		<div class="hhds-carousel">
			<div class="container">
				<slot class="slides"></slot>
				<div class="container hhds-carouselnav" style="${this.vars.get('nav') === 'false' ? '--margin-bottom: 0;' : ''}">
					<div class="grid">

						<hhds-richtext style="--rich-text-spacing-lg: 0px;" class="col-span-4  sm:col-span-7 md:col-span-8">
							<h5>${this.vars.get('title')}</h5>
						</hhds-richtext>	

						<hhds-buttongroup class="col-span-2 ${!this.vars.get('link')  ? `col-start-11 stats` : ''}" type="toolbar" orientation="horizontal">
							<hhds-button type="secondary" class="button-prev">
								<hhds-icon type="arrowleft" slot="start" style="--icon-color: var(--color-neutral-grey-900);"></hhds-icon>
							</hhds-button>
							<hhds-button type="secondary" class="button-next">
								<hhds-icon type="arrowright" slot="start" style="--icon-color: var(--color-neutral-grey-900);"></hhds-icon>
							</hhds-button>	
						</hhds-buttongroup>

						${this.viewAllLink}

					</div>
				</div>
			</div>
		</div>

		<div class="container">
			<div class="wrapper hhds-carousel-slider">
				<div class="swiper ${HHDSCarouselTagName}">
					<div class="swiper-wrapper"></div>
					<!-- Add Pagination -->
					<div class="swiper-pagination"></div>
				</div>
			</div>
		</div>
		`;

		this.swiperContainer = this.shadowRoot?.querySelector('.swiper') as HTMLElement;

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
		// Copy all elements in the slot into .swiper-wrapper

		const slidesEl = this.shadowRoot?.querySelector('.slides');

		if (slidesEl && slidesEl instanceof HTMLSlotElement) {
			const swiperWrapperSelector = '.swiper-wrapper';
			const swiperWrapper = this.shadowRoot?.querySelector(swiperWrapperSelector);

            if (!swiperWrapper) throw new Error(`Unable to derive selector: ${swiperWrapperSelector}`);
            
            // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            
			const elements = slidesEl.assignedElements() as HTMLElement[];

			DEBUG_VERBOSE && console.log(`elements`, elements);

			elements.forEach((element) => {
				element.classList.add('swiper-slide');
                element.addEventListener("focusin", (event: FocusEvent) => {
                    const relatedTarget = event.relatedTarget as Node;

                    if (!element.contains(relatedTarget)) {
                        this.handleContentFocus(element);
                    }
                });
				swiperWrapper.appendChild(element);
			});
		}

		this._prevButton = this.shadow.querySelector('.button-prev');
		this._nextButton = this.shadow.querySelector('.button-next');

	}

    handleContentFocus = (element: HTMLElement) => {
        if(!this.swiperInstance) throw new Error("No swiper instance!");
        
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        
        const { slides, activeIndex } = this.swiperInstance;

        // Get the index of the slide which has focus
        const focusIndex = slides.indexOf(element);

        // If the slide with focus is not the active slide then we move to the slide with focus
        if (activeIndex !== focusIndex) {
            this.swiperInstance.slideTo(focusIndex);
        }
        
        DEBUG_VERBOSE && console.log(CLASS_NAME, "handleContentFocus", element, `focusIndex`, focusIndex, activeIndex === focusIndex);
    }

	// Invoked each time the custom element is disconnected from the document's DOM.
	disconnectedCallback() {
		this.swiperDestroy();
	}

	get viewAllLink(): string {

		const link = this.vars.get('link');
		const label = this.vars.get('link-label');
		const target = this.vars.get('link-target');

		if (link) {
			return `
				<hhds-link class="col-span-2 col-start-11" href="${link}" target="${target ? target : ''}" >
					<hhds-icon type="${target == '_blank' ? 'arrowupright' : 'arrowright'}" slot="end"></hhds-icon>
					${label ?? 'View All'}
				</hhds-link>`;
		} else {
			return '';
		}

		// return `
		// 	${(this.vars.get('link') && this.vars.get('link-label')) ? `
		// 		<hhds-link class="col-span-2 col-start-11" href="${this.vars.get('link')}" target="${this.vars.get('link-target') ? this.vars.get('link-target') : ''}" ><hhds-icon type="arrowright" slot="end"></hhds-icon>${this.vars.get('link-label') ?? 'View All'}</hhds-link>
		// 	` : ''}		
		// `;

	}

	swiperDestroy() {
		if (this.swiperInstance) {
			this.swiperInstance.destroy(true, true);
		}
	}

	// Invoked each time the custom element is moved to a new document.
	adoptedCallback() {}

	// Invoked each time one of the custom element's attributes is added, removed, or changed.
	attributeChangedCallback(name: string, oldValue: string, newValue: string) {
		DEBUG_VERBOSE && console.log(`Attribute ${name} has changed from ${oldValue} to ${newValue}.`);

		/**
		 * Commented out as causing issue with some default attributes triggering
		 * attributeChangedCallback and the followup code not working as expected.
		 * The swiper instance was being removed and not re-instantiated correctly.
		 * Also unsure as to why some attributes were triggering attributeChangedCallback
		 */

		// this.swiperDestroy();
		// 	this.parseAttributes();
		// 	this.render();
		// 	this.initializeSwiper();
	}

	initializeSwiper() {

		const slidesPerView = this.vars.get<number>(ArgSpecsKey.SlidesPerView);
		const speed = this.vars.get<number>(ArgSpecsKey.TransitionSpeed);

		if (!this.swiperContainer) throw new Error('No swiperContainer');

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

		this.swiperInstance = new Swiper(this.swiperContainer, {
			loop: false,
			// Temp disabled pagination
			// pagination: {
			// 	el: this.swiperContainer.querySelector('.swiper-pagination') as HTMLElement,
			// },
			slideFullyVisibleClass: 'swiper-slide-visible',
			slidesPerView: this.type == CarouselType.Stats ? 2.4 : 1.1, // Set for smallest size
			spaceBetween: 16, // Set for smallest size
			speed,
			breakpoints: {
				// when window width is >=
				640: {
					slidesPerView: this.type == CarouselType.Stats ? 4 : 2,
					spaceBetween: 16,
				},
				1024: {
					slidesPerView: slidesPerView,
					spaceBetween: 20,
				},
				// when window width is >=
				1440: {
					slidesPerView: slidesPerView,
					spaceBetween: 24,
				},
			},
			on: {
				init: () => {					
					this.setButtonDisabledState();
					requestAnimationFrame(() => {
						this.handleVisibleSlides();
					});
				},
				slideChange: (swiper: Swiper) => {
					this.setButtonDisabledState();
					this.handleVisibleSlides();
					DEBUG_VERBOSE && console.log('slideChange', swiper);
				},
				resize: () => {
					this.handleVisibleSlides();
				},
			},
		});

		this.swiperInstance.init();

		this._nextButton?.addEventListener('click', () => {
			this.swiperInstance?.slideNext();
		});

		this._prevButton?.addEventListener('click', () => {
			this.swiperInstance?.slidePrev();
		});

		this.checkNavVisibility(true);

	}

	setButtonDisabledState() {
		if (this.swiperInstance?.isBeginning) {
			this._prevButton?.setAttribute('disabled', 'true');
		} else {
			this._prevButton?.removeAttribute('disabled');
		}

		if (this.swiperInstance?.isEnd) {
			this._nextButton?.setAttribute('disabled', 'true');
		} else {
			this._nextButton?.removeAttribute('disabled');
		}
	}

	handleVisibleSlides() {

		if (!this.swiperInstance) throw new Error('No swiper instance!');

		const { realIndex, slides } = this.swiperInstance;

		const slidesPerView = this.swiperInstance.params.slidesPerView as number;

		slides.forEach((slide, index) => {

			const min = realIndex;
			const max = realIndex + slidesPerView;
			let state = index >= min && index < max;

			if(window.innerWidth < 640) {
				state = index == realIndex;
			}

			slide.classList.toggle('swiper-slide-visible', state);
		});
	}
}

export const enum ArgSpecsKey {
	SlidesPerView = 'slidesPerView',
	TransitionSpeed = 'transitionSpeed',
	Type = 'type',
	Title = 'title',
	Nav = 'nav',
	Link = 'link',	
	LinkLabel = 'link-label',
	LinkTarget = 'link-target'
}

export const ArgSpecs: ArgSpecDictionary = {
	[ArgSpecsKey.Type]: {
		description: `The type of carousel.`,
		defaultValue: CarouselType.Community,
		type: CarouselType,
	},
	[ArgSpecsKey.Title]: {
		description: `The title of the carousel.`,
		defaultValue: '',
		type: String,
	},
	[ArgSpecsKey.Link]: {
		description: `The URL of the View All page.`,
		defaultValue: '',
		type: String,
	},
	[ArgSpecsKey.LinkLabel]: {
		description: `The Label of the View All link.`,
		defaultValue: '',
		type: String,
	},
	[ArgSpecsKey.LinkTarget]: {
		description: `The target of the View All link.`,
		defaultValue: '_self',
		type: String,
	},
	[ArgSpecsKey.Nav]: {
		description: `Whether or not to show the navigation.`,
		defaultValue: 'true',
		type: String,
	},
	[ArgSpecsKey.SlidesPerView]: {
		description: `Number of slides per view (slides visible at the same time on slider's container).`,
		defaultValue: 1,
		type: Number,
	},

	[ArgSpecsKey.TransitionSpeed]: {
		description: `Duration of transition between slides (in ms)`,
		defaultValue: 300,
		type: Number,
	},


};
