import css from './peoplegrid.scss?inline';
import globalStyles from '../../index.scss?inline';
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";

import gsap from 'gsap';
import PeopleModalCoordinator from '../../utils/PeopleModalCoordinator';

const DEBUG_VERBOSE: boolean  = false;
const CLASS_NAME: string = 'HHDSPeopleGrid';
export const HHDSPeopleGridTagName: string = "hhds-peoplegrid";

export const HHDSPeopleGridAttrNames = {
  title: "title",
};

const Attrs = HHDSPeopleGridAttrNames;

interface PersonAJAXResponse {
    id: string,
    name: string;
    thumbnail: string;
    job_title: string;
    post: string;
    description: string;
    content: string;
}

interface WPAjaxObject {
  ajax_url: string;
  nonce: string;
}

declare var ajax_obj: WPAjaxObject;

export class HHDSPeopleGrid extends Component {

  private _modal: HTMLDivElement | null = null;
  private _postIds: string[] = [];

  private scrollContainerEl! : HTMLElement;
  private blockColumn1El! : HTMLElement;
  private blockTitleEl! : HTMLElement;
  private blockImageEl! : HTMLElement;

  private peopleModalCoordinator! : PeopleModalCoordinator;

  constructor() {
    super();
    // The base class's constructor handles attachmennt of a shadow root and
    // adopted global styles. Access the shadow root via this.shadow.
    //
    // Use the constructor only for anything that will never need to be destroyed as part of the
    // component's update lifecycle. init() and destroy() are called for connectedCallback and
    // disconnectedCallback, and a destroy() init() pair is called if reinit() is utilised.


  }

  protected override init(): void {
    // The base class responds to connectedCallback() by collecting attributes into
    // this.vars, then calling init(). A call to super.init() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    // const argValue = this.vars.get<number>(Attrs.myNumericArg);
    // console.log(`${Attrs.myNumericArg}: ${argValue} (type: ${typeof argValue})`);

    // For some components, re-assigning innerHTML may be appropriate on attribute and slot changes,
    // and without the need for a reinit(). In other cases, assigning innerHTML explicitly as part of
    // the init() routine is more appropriate.

    const title = this.vars.get<string>(Attrs.title);
    const titleHTML = title ? `<hhds-richtext class="col-span-6 sm:col-span-8 md:col-span-12"><h3>${title}</h3></hhds-richtext>` : ``; 

		this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
      <div class="container">
        <div class="grid grid--people grid--people--spacing" ${title ? 'style="--padding-top: 0;"' : ''}>
          ${titleHTML}
          <slot></slot>
        </div>
      </div>
    `;

    // Add our grid classes to the slot elements
    const slot = this.shadow.querySelector('slot');
    const assignedNodes = slot?.assignedNodes({ flatten: true });    
    assignedNodes?.forEach(node => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          const element = node as HTMLElement;
          this._postIds.push(element.getAttribute('post') || '');
          element.classList.add('col-span-3', 'sm:col-span-2', 'md:col-span-3');
          element.addEventListener('click', this.onTileClick);  
        }
    });

    // If the component uses slots, use observeSlotChanges().
    // this.observeSlotChanges(true);

    // this.onTileClick(new Event('click'));

    if (window.location.hash && !document.body.classList.contains('modal-loading') && !this._modal) {

      document.body.classList.toggle('modal-loading', true);

      const postSlug = window.location.hash.replace('#', '');
      this.fetchPersonBySlug(postSlug).then((data) => {   
        this.createModal(data).then(() => {
          this.showModal();
        });
      });
    }



  }

  protected override destroy(): void {
    // The base class responds to disconnectedCallback() by collecting attributes into
    // this.vars, then calling destroy(). A call to super.destroy() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    // If the component uses slots, stop observing slot changes.
    // this.observeSlotChanges(false);
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    // Either call reinit() to have the component's destroy and init methods each be called,
    // or skip this step and handle update of the attribute directly. 'this.vars' will already
    // have been updated by the base Component class, so it can be immediately used to access
    // the new value.
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    // The base Component class must have access to this superclass's ArgSpecs.
    return ArgSpecs;
  }

  addClickHandlers() {
    const tiles = this.shadow.querySelectorAll('hhds-tile');
    tiles.forEach(tile => {
      tile.addEventListener('click', this.onTileClick);
    }); 
  }

  addModalClickHandlers() {
    const modal = this._modal;

    // Close button click handler
    const closeBtn = modal?.querySelector('hhds-link') as HTMLElement;

    if (closeBtn) {
        closeBtn.addEventListener('click', this.closeModal);
        closeBtn.addEventListener('keydown', this.closeModalKeyboard);
    }

    // Backdrop click handler
    const backdrop = modal?.querySelector('.hhds-peoplegrid--modal') as HTMLElement;
    

    if (backdrop) {
        backdrop.addEventListener('click', (event) => {
            if (event.target === backdrop) {
                this.closeModal();
            }
        });
    }

    // Escape keydown handler
    const handleEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape' || event.key === 'Esc') {  
            this.closeModal();
            window.removeEventListener('keydown', handleEscape);
        }
    };
    
    window.addEventListener('keydown', handleEscape);

    // Next button click handler
    const nextBtn = modal?.querySelector('hhds-link:last-of-type') as HTMLElement;

    if (nextBtn) {
        nextBtn.addEventListener('click', this.nextPerson);
        nextBtn.addEventListener('keydown', this.nextPersonKeyboard);
    }
  }



  // ////////////////////////////////////////////////////////////////////

  removeModalClickHandlers() {

    const closeBtn = this._modal?.querySelector('hhds-link') as HTMLElement;

    if (closeBtn) {
        closeBtn.removeEventListener('click', this.closeModal);
        closeBtn.removeEventListener('keydown', this.closeModalKeyboard);  
    }

    const nextBtn = this._modal?.querySelector('hhds-link:last-of-type') as HTMLElement;

    if (nextBtn) {
        nextBtn.removeEventListener('click', this.nextPerson);
        nextBtn.removeEventListener('keydown', this.nextPersonKeyboard);
        
    }

  }

  // ////////////////////////////////////////////////////////////////////

  killPeopleModalCoordinator() {
    if(this.peopleModalCoordinator) this.peopleModalCoordinator.kill();
  }

  // ////////////////////////////////////////////////////////////////////

  closeModalKeyboard = (event: KeyboardEvent) => {
    DEBUG_VERBOSE && console.log(CLASS_NAME, 'closeModalKeyboard');

    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      this.closeModal();
    }
  }

  closeModal = () => {

    this.killPeopleModalCoordinator();

    if (window.location.hash) {
      history.replaceState(
        null,
        document.title,
        window.location.pathname + window.location.search
      );      
    }

    window.removeEventListener('resize', this.modalOpenWindowResizeListener);

    this.removeModalClickHandlers();

    const wrapper = this._modal?.querySelector('#modal_content');
    const backdrop = this._modal?.querySelector('#modal_backdrop'); 

    // Check screen width
    if (window.innerWidth < 640) {
        // If screen width is less than 1024px, animate in from the bottom
        gsap.to([wrapper, backdrop], { transform: 'translateY(100%)', duration: 1, ease: 'power2.in', onComplete: () => {
            if (this._modal) this.shadow.removeChild(this._modal);
            this._modal = null;
            document.documentElement.classList.toggle('menu-open-no-scroll', false);
          } });
    } else {
        // Default animation (slide in from the right)
        gsap.to([wrapper, backdrop], { transform: 'translateX(100%)', duration: 1, ease: 'power2.in', onComplete: () => {
          if (this._modal) this.shadow.removeChild(this._modal);
          this._modal = null;
          document.documentElement.classList.toggle('menu-open-no-scroll', false);
        } });
    }

  }


  async fetchPersonBySlug(postSlug: string): Promise<PersonAJAXResponse | null> { 

    if (postSlug) {

      try {

        const response = await fetch(ajax_obj.ajax_url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
              action: 'fetch_person_by_slug',
              post_slug: `${postSlug}`,
              nonce: ajax_obj.nonce,
          })
        });

        const result = await response.json();

        if (result.success) {
            return result.data;
        } else {
          console.error("Fetch request failed.");  
          return null;
        }

      } catch (error) {
        console.error("Failed to fetch person data.", error);  
        return null;
      }

    } else {
      console.error("No postId specified. Nothing I can do.");  
      return null;
    }

  }

  async fetchPerson(postId: string): Promise<PersonAJAXResponse | null> { 

    if (postId) {

      try {

        const response = await fetch(ajax_obj.ajax_url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
              action: 'fetch_person',
              post_id: postId,
              nonce: ajax_obj.nonce,
          })
        });

        const result = await response.json();

        if (result.success) {
            return result.data;
        } else {
          console.error("Fetch request failed.");  
          return null;
        }

      } catch (error) {
        console.error("Failed to fetch person data.", error);  
        return null;
      }

    } else {
      console.error("No postId specified. Nothing I can do.");  
      return null;
    }

  }

  async createModal(data: any): Promise<void> {

    document.body.classList.toggle('modal-loading', true); 

    return new Promise(async (resolve, reject) => { 

      // const data: PersonAJAXResponse | null = await this.fetchPerson(postId); 
      if (data) {

        if (!this._modal) {
          this._modal = document.createElement('div');
          this._modal.setAttribute('tabindex', "0");
        }

        // this._modal.classList.toggle('hhds-peoplegrid--modal', true);    
        this._modal.setAttribute('post', data.id);
        this._modal.innerHTML = /* html */ `
        <div class="hhds-peoplegrid--modal">
            <div id="modal_backdrop" class="hhds-peoplegrid--modal--wrapper">      
                <div class="grid">
                    <div class="hhds-peoplegrid--modal--backdrop col-span-6 sm:col-span-8 md:col-start-2 md:col-span-11 lg:col-start-4 lg:col-span-9"></div>
                </div>
            </div>
  
            <div id="modal_content" class="hhds-peoplegrid--modal--wrapper">
                <div class="grid modal">
                    
                    <div class="hhds-peoplegrid--modal--title col-span-6 sm:col-span-8 md:col-start-2 md:col-span-11 lg:col-start-4 lg:col-span-9">
                        <hhds-link tabindex="0" class="md:col-start-3 md:col-start-5 lg:col-start-5 lg:col-span-2"><hhds-icon type="cross" slot="start"></hhds-icon>Close</hhds-link>
                        <hhds-link tabindex="0" class="col-span-6 sm:col-span-4 sm:col-start-5 md:col-start-8 md:col-span-5 lg:col-start-12 lg:col-span-1"><hhds-icon type="arrowright" slot="end"></hhds-icon>Next</hhds-link>
                    </div>
                
                    <!-- Scroll Container -->
                    
                    <div class="hhds-peoplegrid--modal--content-wrapper col-span-6 sm:col-span-8 md:col-start-2 md:col-span-11 lg:col-start-4 lg:col-span-9"  ref="scroll-container">
                        
                        <!-- Details -->
                        <div class="hhds-peoplegrid--modal--content-col-1" ref="col-1">
                            <div ref="block-title">
                                <hhds-badge>BIO</hhds-badge>
                                <hhds-richtext>
                                <h2 class="heading heading--01">${data.name}<br/>${data.job_title ? '<em>' + data.job_title + '</em>' : '' }</h2>
                                </hhds-richtext>
                            </div>
                            <div ref="block-image">
                                <hhds-image alt="A portrait photograph of ${data.name}, ${data.job_title}" style="--aspect-ratio:1/1" src="${data.thumbnail}"></hhds-image>
                            </div>
                        </div>

                        <!-- Copy -->
                        <div class="hhds-peoplegrid--modal--content-col-2">
                            <hhds-richtext>${data.description}</hhds-richtext>
                        </div>
                        
                    </div>

                    <!-- / Scroll Container -->
                </div>
            </div>
        </div>`;
    
        this.addModalClickHandlers();        
        resolve();
  
      } else {
        console.error("Failed to create modal.");
        document.body.classList.toggle('modal-loading', false); 
        reject();
      }

    });

  } 

  nextPersonKeyboard = (event: KeyboardEvent) => {
    DEBUG_VERBOSE && console.log(CLASS_NAME, 'nextPersonKeyboard');

    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      this.nextPerson();
    }
  }

  nextPerson = () => {
    this.killPeopleModalCoordinator();

    let currentId = this._modal?.getAttribute('post');  
    let nextId: string; 

    if (currentId) {

      const currentIndex = this._postIds.indexOf(currentId);
      if (currentIndex !== -1) {
        const nextIndex = (currentIndex + 1) % this._postIds.length;
        nextId = this._postIds[nextIndex];

          this.fetchPerson(nextId).then((data) => {
            this.createModal(data).then(() => { 
              this.showModal();
            });
          });

      }

    }
  }

  onTileClick = (event: any) => {  

    const el = event.target as HTMLElement;
    const postId = el.closest('hhds-tile')?.getAttribute('post');  

    if (postId) {
      this.fetchPerson(postId).then((data) => {
        this.createModal(data).then(() => { 
          this.showModal();
        });
      });
   }
  }

  // ////////////////////////////////////////////////////////////////////

  get blockTitleHeight(){
    return this.blockTitleEl.clientHeight + 24;
  }

  get blockImageHeight(){
    return this.blockImageEl.clientHeight;
  }

  isOverflowing() {

    // If mobile size then we want to return true so the CSS uses the default relative position for the `block-title`
    if (window.innerWidth < 640) return true;

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const computedStyle = window.getComputedStyle(this.blockColumn1El);

    const { paddingBottom, paddingTop } = computedStyle;

    const columnPaddingTop = parseFloat(paddingTop);
    const columnPaddingBottom = parseFloat(paddingBottom);

	const leftColContentHeight = columnPaddingTop + this.blockImageHeight + this.blockTitleHeight + columnPaddingBottom; // Excluding gap between title and image

	const delta = this.scrollContainerEl.clientHeight - leftColContentHeight;

	return delta < 0;
}

  // ////////////////////////////////////////////////////////////////////

  showModal() {

    document.body.classList.toggle('modal-loading', false); 
    document.documentElement.classList.toggle('menu-open-no-scroll', true);

    const wrapper = this._modal?.querySelector('#modal_content');
    const backdrop = this._modal?.querySelector('#modal_backdrop');

    if (this._modal) this.shadow.appendChild(this._modal);

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const selectorScrollContainer = '[ref="scroll-container"]';

    this.scrollContainerEl = this.shadow.querySelector(selectorScrollContainer) as HTMLElement;

    if (!this.scrollContainerEl) throw new Error(`Unable to derive selector:  ${selectorScrollContainer}`);

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const selectorColumn1 = '[ref="col-1"]';

    this.blockColumn1El = this.shadow.querySelector(selectorColumn1) as HTMLElement;

    if (!this.scrollContainerEl) throw new Error(`Unable to derive selector:  ${selectorColumn1}`);

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const selectorBlockTitle = '[ref="block-title"]';

    this.blockTitleEl = this.shadow.querySelector(selectorBlockTitle) as HTMLElement;

    if (!this.blockTitleEl) throw new Error(`Unable to derive selector: ${selectorBlockTitle}`);

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -/

    const selectorBlockImage = '[ref="block-image"]';

    this.blockImageEl = this.shadow.querySelector(selectorBlockImage) as HTMLElement;

    if (!this.blockImageEl) throw new Error(`Unable to derive selector: ${selectorBlockImage}`);

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    window.addEventListener('resize', this.modalOpenWindowResizeListener);

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    const initModalA11y = () => {

        if(!this._modal) throw new Error("No modal!");

        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        

        const focusableItems = Array.from(this._modal?.querySelectorAll('[tabindex="0"]')) as HTMLElement[];

        this.peopleModalCoordinator = new PeopleModalCoordinator(this._modal, focusableItems);

        DEBUG_VERBOSE && console.log(CLASS_NAME, 'initModalA11y', focusableItems);
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    requestAnimationFrame(() => {
        // A little time to re-render
        this.updateImageTopProperty();
        this.updateOverflowState();
        initModalA11y();
    });

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    // Check screen width
    if (window.innerWidth < 640) {
        // If screen width is less than 1024px, animate in from the bottom
        gsap.set([wrapper, backdrop], { transform: 'translateY(100%)' });  
        gsap.to([wrapper, backdrop], { transform: 'translateY(0)', duration: 1, ease: 'power2.out', delay: 0.2 });
    } else {
        // Default animation (slide in from the right)
        gsap.set([wrapper, backdrop], { transform: 'translateX(100%)' });  
        gsap.to([wrapper, backdrop], { transform: 'translateX(0)', duration: 1, ease: 'power2.out', delay: 0.2 });
    }
  }

  // ////////////////////////////////////////////////////////////////////

  updateImageTopProperty(){

    const computedStyle = window.getComputedStyle(this.blockColumn1El);

    const { paddingBottom } = computedStyle;

    const imagePaddingBottom = parseFloat(paddingBottom);
    
    const imageTop = this.scrollContainerEl.clientHeight - this.blockImageEl.clientHeight - imagePaddingBottom;

	this.style.setProperty('--image-top', `${imageTop}px`);

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

	const imageTopPortrait = this.blockTitleHeight + 48;

	this.style.setProperty('--image-top-portrait', `${imageTopPortrait}px`);
    
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    
    const imageTopOverflow = this.scrollContainerEl.clientHeight - this.blockImageHeight - imagePaddingBottom;

	this.style.setProperty('--image-top-overflow', `${imageTopOverflow}px`);
  }

  updateOverflowState(){

    if (this._modal) {
        this._modal.setAttribute('overflow', this.isOverflowing() ? 'true' : 'false');
    }
  }

  // ////////////////////////////////////////////////////////////////////

  modalOpenWindowResizeListener = () => {
    this.updateImageTopProperty();
	this.updateOverflowState();
	this.scrollContainerEl.scrollTop = 0;
  }

}



export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.title]: {
    description: "An optional title for the grid",
    defaultValue: '',
    type: String,
  },
};

