import { ArgSpecDictionary, TypedVars } from "../components/component-utils";

export class Component extends HTMLElement {
  vars: TypedVars = new TypedVars(this);
  protected shadow: ShadowRoot;
  private _initialised: boolean = false;
  private _slotChangeFuncs: any = {};

  protected observer: MutationObserver | null = null;

  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: "open" });
    if (!this.shadow) return;
    // const styleSheet = new CSSStyleSheet();
    // styleSheet.replaceSync(globalStyles);
    // this.shadow.adoptedStyleSheets = [styleSheet];
  }

  get initialised(): boolean {
    return this._initialised;
  }

  setSlotContent(innerHtml: string, slotName?: string): void {
    let slot: HTMLSlotElement | null = null;
    if (slotName) {
      slot = this.shadow.querySelector(`slot[name="${slotName}"]`);
    } else {
      slot = this.shadow.querySelector("slot");
    }
    if (!slot) throw new Error("Slot not found.");
    slot.innerHTML = innerHtml;
  }

  getSlotContent(slotName?: string): string {
    if (slotName) {
      const slot = this.shadow.querySelector(`slot[name="${slotName}"]`);
      if (!slot) throw new Error("Slot not found.");
      return slot.innerHTML;
    } else {
      const slot = this.shadow.querySelector("slot");
      if (!slot) throw new Error("Slot not found.");
      return slot.innerHTML;
    }
  }

  observeSlotChanges(toggle: boolean): void {
    const slots = this.shadow.querySelectorAll("slot") as NodeListOf<HTMLSlotElement>;
    if (toggle) {
      slots?.forEach((slot) => {
        const slotName = slot.name ?? "unnamed";
        if (this._slotChangeFuncs[slotName]) return;
        const slotChangeFunc = (event: Event) => {
          const slot = event.target as HTMLSlotElement;
          this.onSlotChange(slot, slot.assignedElements({ flatten: true }));
        };
        slot.addEventListener("slotchange", slotChangeFunc);
        this._slotChangeFuncs[slotName] = slotChangeFunc;
      });
    } else {
      slots?.forEach((slot) => {
        const slotName = slot.name ?? "unnamed";
        const slotChangeFunc = this._slotChangeFuncs[slotName];
        if (!slotChangeFunc) return;
        slot.removeEventListener("slotchange", slotChangeFunc);
        delete this._slotChangeFuncs[slotName];
      });
    }
  }

  connectedCallback(): void {
    let argSpecs = (this.constructor as any).argSpecs();
    this.vars.parse(this, argSpecs);
    this.init();
    this._initialised = true;
  }

  disconnectedCallback(): void {
    this.destroy();
    this.vars?.destroy();
    this.observeSlotChanges(false);
    this._initialised = false;
  }

  protected emitEvent(eventName: string, data: any = null) {
    const event = new CustomEvent(eventName, { detail: data });
    this.dispatchEvent(event);
  }

  getSlotted<T extends HTMLElement>(selector: string, slotName?: string): T | null {
    let slot: HTMLSlotElement | null = null;
    if (slotName) {
      slot = this.shadow.querySelector(`slot[name="${slotName}"]`) as HTMLSlotElement;
    } else {
      slot = this.shadow.querySelector("slot") as HTMLSlotElement;
    }
    if (!slot) return null;
    const assignedElements = slot.assignedElements();
    return assignedElements.find((el) => el.matches(selector)) as T;
  }

  static get observedAttributes() {
    return Object.keys(this.argSpecs());
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (this.initialised) {
      let argSpecs = (this.constructor as any).argSpecs();
      this.vars.update(name, newValue, argSpecs, this.vars);
      this.onAttributeChanged(name, oldValue, newValue);
    }
  }

  protected reinit(reparseArgs: boolean = false): void {
    this.destroy();
    if (reparseArgs) {
      let argSpecs = (this.constructor as any).argSpecs();
      this.vars.parse(this, argSpecs);
    }
    this.init();
  }

  onAttributeChanged(_name: string, _oldValue: string, _newValue: string): void {
    throw new Error("Method must be overridden.");
  }

  onSlotChange(_slot: HTMLSlotElement, _elements: Element[]): void {
    throw new Error("Method must be overridden.");
  }

  static argSpecs(): ArgSpecDictionary {
    throw new Error("Method must be overridden.");
  }

  protected init(): void {
    throw new Error("Method must be overridden.");
  }

  protected destroy(): void {
    throw new Error("Method must be overridden.");
  }

  static getAriaDescriptionAttributeValueFromId(id: string){
    return `${id}-description`;
  }
}
