import css from './peoplecarousel.scss?inline';
import globalStyles from '../../index.scss?inline';
import { ArgSpecDictionary, TypedVars } from '../component-utils';
import swiperStyles from "../../../node_modules/swiper/swiper-bundle.min.css?inline";
import Swiper from 'swiper';
import { CarouselType } from '../Carousel/CarouselType';

import gsap from 'gsap';
import PeopleModalCoordinator from '../../utils/PeopleModalCoordinator';

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = 'HHDSPeopleCarousel';
const TAG_NAME: string = 'hhds-peoplecarousel';

export const HHDSPeopleCarouselTagName: string = 'hhds-peoplecarousel';

interface PersonAJAXResponse {
    name: string;
    thumbnail: string;
    job_title: string;
    post: string;
    description: string;
    content: string;
}

interface WPAjaxObject {
	ajax_url: string;
	nonce: string;
  }
  
  declare var ajax_obj: WPAjaxObject;
  

// ////////////////////////////////////////////////////////////////////

export class HHDSPeopleCarousel extends HTMLElement {
	private vars: TypedVars = new TypedVars(this);
	private shadow: ShadowRoot;

	private swiperContainer: HTMLElement | null = null;
	private swiperInstance: Swiper | null = null;

	private navVisible: boolean = false;

	private _prevButton: HTMLElement | null = null;
	private _nextButton: HTMLElement | null = null;

	private type: CarouselType | null = null;

	private _modal: HTMLDivElement | null = null;
	private _postIds: string[] = [];  

    private scrollContainerEl! : HTMLElement;
    private blockColumn1El! : HTMLElement;
    private blockTitleEl! : HTMLElement;
    private blockImageEl! : HTMLElement;

    private peopleModalCoordinator! : PeopleModalCoordinator;

	constructor() {
		super();
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'constructed');

		this.shadow = this.attachShadow({ mode: 'open' });

		if (!this.shadow) throw new Error('Unable to attach shadowRoot');

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

	}

	static get observedAttributes() {
		return Object.keys(ArgSpecs);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// Lifecycle Methods
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.
	connectedCallback() {
		this.parseAttributes();

		this.render();

		requestAnimationFrame(() => {
			this.initializeSwiper();
		});

		window.addEventListener('resize', () => this.checkNavVisibility());

		DEBUG_VERBOSE && console.log(`[${TAG_NAME}] Initialised`);
	}

	checkNavVisibility(forceUpdate: boolean = false) {	

			if (this.swiperInstance) {

				if (window.innerWidth >= 1024) {

					if (this.vars.get('nav') === 'false') {

						this.setNavVisibility(false, true);

					} else {

						const numSlides = typeof this.swiperInstance.slides.length === 'number' ? this.swiperInstance.slides.length : 0;
						const slidesInView = typeof this.swiperInstance.params.slidesPerView === 'number' ? this.swiperInstance.params.slidesPerView : numSlides;
						const visible = (slidesInView < numSlides);
						DEBUG_VERBOSE && console.log(`There is space for ${slidesInView} slide, and ${numSlides} slides`);
						DEBUG_VERBOSE && console.log(`Visibilty should be ${visible}`);
						this.setNavVisibility(visible, forceUpdate);

					}

				} else {

					this.setNavVisibility(false, true);

				}

			} else {

				DEBUG_VERBOSE && console.error("No swiper instance available");

			}

	}

	setNavVisibility(visible: boolean, forceUpdate: boolean) {

		if(this.navVisible !== visible || forceUpdate) {

			DEBUG_VERBOSE && console.log(`Setting nav visibility to ${visible}`);

			this.navVisible = visible;
			const nav = this.shadow.querySelector('hhds-buttongroup') as HTMLElement;
			if (nav) { 
				nav.style.display = visible ? 'flex' : 'none';	
			}
		}

	}

	parseAttributes() {
		this.vars.parse(this, ArgSpecs);

		this.type = this.vars.get('type');	

		DEBUG_VERBOSE && console.log(`this.vars`, this.vars);
	}

	render() {
		
		const link = this.vars.get('link');
		DEBUG_VERBOSE && console.log('*** LINK: ', link);

		this.shadow.innerHTML = /* html */ `
		<style>${swiperStyles}</style>
		<style>${globalStyles}</style>
		<style>${css}</style>
		<div class="hhds-peoplecarousel container">
			<slot class="slides"></slot>
			<div class=" hhds-peoplecarouselnav" style="${this.vars.get("nav") === "false" ? "--margin-bottom: 0;" : ""}">
				<div class="grid">

					<hhds-richtext style="--rich-text-spacing-lg: 0px;" class="col-span-4  sm:col-span-7 md:col-span-8">
						<h5>${this.vars.get("title")}</h5>
					</hhds-richtext>	 


					<hhds-buttongroup class="col-span-2 ${!this.vars.get("link") ? `col-start-11 stats` : ""}" type="toolbar" orientation="horizontal">
						<hhds-button type="secondary" class="button-prev">
							<hhds-icon type="arrowleft" slot="start" style="--icon-color: var(--color-neutral-grey-900);"></hhds-icon>
						</hhds-button>
						<hhds-button type="secondary" class="button-next">
							<hhds-icon type="arrowright" slot="start" style="--icon-color: var(--color-neutral-grey-900);"></hhds-icon>
						</hhds-button>	
					</hhds-buttongroup>

					${this.vars.get("link") ? `
						<hhds-link class="col-span-2 col-start-11" href="${this.vars.get("link")}"><hhds-icon type="arrowright" slot="end"></hhds-icon>View All</hhds-link>
					`: ""}

				</div>
			</div>
		</div>

		<div class="wrapper hhds-peoplecarousel-slider container">
			<div class="swiper ${HHDSPeopleCarouselTagName}">
				<div class="swiper-wrapper"></div>
				<!-- Add Pagination -->
				<div class="swiper-pagination"></div>
			</div>
		</div>
		`;


		this.swiperContainer = this.shadowRoot?.querySelector('.swiper') as HTMLElement;

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
		// Copy all elements in the slot into .swiper-wrapper

		const slidesEl = this.shadowRoot?.querySelector('.slides');
		const swiperWrapper = this.shadowRoot?.querySelector('.swiper-wrapper');

		if (slidesEl && slidesEl instanceof HTMLSlotElement) {

			const elements = slidesEl.assignedElements();

			DEBUG_VERBOSE && console.log(`elements`, elements);

			elements.forEach((element) => {
				element.classList.add('swiper-slide');
				swiperWrapper?.appendChild(element);
			});
		}

		this._prevButton = this.shadow.querySelector('.button-prev');
		this._nextButton = this.shadow.querySelector('.button-next');



		// Add our grid classes to the slot elements
		const assignedNodes = swiperWrapper?.childNodes;
		assignedNodes?.forEach(node => {

			if (node.nodeType === Node.ELEMENT_NODE) {
				const element = node as HTMLElement;
				this._postIds.push(element.getAttribute('post') || '');
				element.addEventListener('click', this.onTileClick);  
			}
		});


	}

	// Invoked each time the custom element is disconnected from the document's DOM.
	disconnectedCallback() {
		this.swiperDestroy();
	}

	swiperDestroy() {
		if (this.swiperInstance) {
			this.swiperInstance.destroy(true, true);
		}
	}

	// Invoked each time the custom element is moved to a new document.
	adoptedCallback() {}

	// Invoked each time one of the custom element's attributes is added, removed, or changed.
	attributeChangedCallback(name: string, oldValue: string, newValue: string) {
		DEBUG_VERBOSE && console.log(`Attribute ${name} has changed from ${oldValue} to ${newValue}.`);

		/**
		 * Commented out as causing issue with some default attributes triggering
		 * attributeChangedCallback and the followup code not working as expected.
		 * The swiper instance was being removed and not re-instantiated correctly.
		 * Also unsure as to why some attributes were triggering attributeChangedCallback
		 */

		// this.swiperDestroy();
		// 	this.parseAttributes();
		// 	this.render();
		// 	this.initializeSwiper();
	}

	initializeSwiper() {

		const slidesPerView = this.vars.get<number>(ArgSpecsKey.SlidesPerView);
		const speed = this.vars.get<number>(ArgSpecsKey.TransitionSpeed);

		if (!this.swiperContainer) throw new Error('No swiperContainer');

		// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

		this.swiperInstance = new Swiper(this.swiperContainer, {
			loop: false,
			// Temp disabled pagination
			// pagination: {
			// 	el: this.swiperContainer.querySelector('.swiper-pagination') as HTMLElement,
			// },
			slideFullyVisibleClass: 'swiper-slide-visible',
			slidesPerView: this.type == CarouselType.Stats ? 2.4 : 1.1, // Set for smallest size
			spaceBetween: 16, // Set for smallest size
			speed,
			breakpoints: {
				// when window width is >=
				640: {
					slidesPerView: this.type == CarouselType.Stats ? 4 : 2,
					spaceBetween: 16,
				},
				1024: {
					slidesPerView: this.type == CarouselType.Stats ? 4 : 3,
					spaceBetween: 20,
				},
				// when window width is >=
				1440: {
					slidesPerView: slidesPerView,
					spaceBetween: 24,
				},
			},
			on: {
				init: () => {
					this.setButtonDisabledState();
				},
				slideChange: (swiper: Swiper) => {
					this.setButtonDisabledState();
					this.handleVisibleSlides();
					DEBUG_VERBOSE && console.log('slideChange', swiper);
				},
				resize: () => {
					this.handleVisibleSlides();
				},
			},
		});

		this.swiperInstance.init();

		this._nextButton?.addEventListener('click', () => {
			this.swiperInstance?.slideNext();
		});

		this._prevButton?.addEventListener('click', () => {
			this.swiperInstance?.slidePrev();
		});

		this.checkNavVisibility(true);

	}

	setButtonDisabledState() {
		if (this.swiperInstance?.isBeginning) {
			this._prevButton?.setAttribute('disabled', 'true');
		} else {
			this._prevButton?.removeAttribute('disabled');
		}

		if (this.swiperInstance?.isEnd) {
			this._nextButton?.setAttribute('disabled', 'true');
		} else {
			this._nextButton?.removeAttribute('disabled');
		}
	}

	handleVisibleSlides() {
		if (!this.swiperInstance) throw new Error('No this.swiperInstance!');

		const { realIndex, slides } = this.swiperInstance;

		const slidesPerView = this.swiperInstance.params.slidesPerView as number;

		slides.forEach((slide, index) => {
			const min = realIndex;
			const max = realIndex + slidesPerView;
			const state = index >= min && index < max;

			slide.classList.toggle('swiper-slide-visible', state);
		});
	}


	addClickHandlers() {
		const tiles = this.shadow.querySelectorAll('hhds-tile');
		tiles.forEach(tile => {
		  tile.addEventListener('click', this.onTileClick);
		}); 
	  }
	
	  addModalClickHandlers() {
		const modal = this._modal;
	
		// Close button click handler
		const closeBtn = modal?.querySelector('hhds-link') as HTMLElement;
		if (closeBtn) {
			closeBtn.addEventListener('click', this.closeModal);
			closeBtn.addEventListener('keydown', this.closeModalKeyboard);
		}
	
		// Backdrop click handler
		const backdrop = modal?.querySelector('.hhds-peoplecarousel--modal');
		if (backdrop) {
			backdrop.addEventListener('click', (event) => {
				if (event.target === backdrop) {
					this.closeModal();
				}
			});
		}
	
		// Escape keydown handler
		const handleEscape = (event: KeyboardEvent) => {
			if (event.key === 'Escape' || event.key === 'Esc') {  
				this.closeModal();
				window.removeEventListener('keydown', handleEscape);
			}
		};
		window.addEventListener('keydown', handleEscape);
	
		// Next button click handler
		const nextBtn = modal?.querySelector('hhds-link:last-of-type') as HTMLElement;

		if (nextBtn) {
			nextBtn.addEventListener('click', this.nextPerson);
			nextBtn.addEventListener('keydown', this.nextPersonKeyboard);
		}
	  }
	
	  removeModalClickHandlers() {
	
		const closeBtn = this._modal?.querySelector('hhds-link') as HTMLElement;

        if (closeBtn) {
            closeBtn.removeEventListener('click', this.closeModal);
            closeBtn.removeEventListener('keydown', this.closeModalKeyboard);
            
        }
	
		const nextBtn = this._modal?.querySelector('hhds-link:last-of-type') as HTMLElement;

        if (nextBtn) {
            nextBtn.removeEventListener('click', this.nextPerson);
            nextBtn.removeEventListener('keydown', this.nextPersonKeyboard);
        }
	
	  }

      // ////////////////////////////////////////////////////////////////////

      killPeopleModalCoordinator() {
        if(this.peopleModalCoordinator) this.peopleModalCoordinator.kill();
      }

      // ////////////////////////////////////////////////////////////////////
    
      closeModalKeyboard = (event: KeyboardEvent) => {
        DEBUG_VERBOSE && console.log(CLASS_NAME, 'closeModalKeyboard');
    
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          this.closeModal();
        }
      }
	
	  closeModal = () => {
        this.killPeopleModalCoordinator();
		this.removeModalClickHandlers();
	
		const wrapper = this._modal?.querySelector('#modal_content');
		const backdrop = this._modal?.querySelector('#modal_backdrop'); 
	
		// Check screen width
		if (window.innerWidth < 640) {
			// If screen width is less than 1024px, animate in from the bottom
			gsap.to([wrapper, backdrop], { transform: 'translateY(100%)', duration: 1, ease: 'power2.in', onComplete: () => {
				if (this._modal) this.shadow.removeChild(this._modal);
				document.body.style.overflow = 'scroll';  
			  } });
		} else {
			// Default animation (slide in from the right)
			gsap.to([wrapper, backdrop], { transform: 'translateX(100%)', duration: 1, ease: 'power2.in', onComplete: () => {
			  if (this._modal) this.shadow.removeChild(this._modal);
			  document.body.style.overflow = 'scroll';  
			} });
		}
	
	  }
	
	  async fetchPerson(postId: string): Promise<PersonAJAXResponse | null> { 
	
		if (postId) {
	
		  try {
	
			const response = await fetch(ajax_obj.ajax_url, {
			  method: 'POST',
			  headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			  },
			  body: new URLSearchParams({
				  action: 'fetch_person',
				  post_id: postId,
				  nonce: ajax_obj.nonce,
			  })
			});
	
			const result = await response.json();
	
			if (result.success) {
				return result.data;
			} else {
			  console.error("Fetch request failed.");  
			  return null;
			}
	
		  } catch (error) {
			console.error("Failed to fetch person data.", error);  
			return null;
		  }
	
		} else {
		  console.error("No postId specified. Nothing I can do.");  
		  return null;
		}
	
	  }
	
	  async createModal(postId: string): Promise<void> {
	
		return new Promise(async (resolve, reject) => { 
	
		  const data: PersonAJAXResponse | null = await this.fetchPerson(postId); 
		  if (data) {

			if (!this._modal) {
                this._modal = document.createElement('div');
			}

			this._modal.classList.add('hhds-peoplecarousel--modal');    
			this._modal.setAttribute('post', postId);
			this._modal.setAttribute('tabindex', "0");
			this._modal.innerHTML = /* html */ `
            <div class="hhds-peoplecarousel--modal">
                <div id="modal_backdrop" class="hhds-peoplecarousel--modal--wrapper">      
                    <div class="grid">
                        <div class="hhds-peoplecarousel--modal--backdrop col-span-6 sm:col-span-8 md:col-start-2 md:col-span-11 lg:col-start-4 lg:col-span-9">  
                    </div>
                </div>
            </div>
	  
            <div id="modal_content" class="hhds-peoplecarousel--modal--wrapper">
                <div class="grid modal">
	  
                    <div class="hhds-peoplecarousel--modal--title col-span-6 sm:col-span-8 md:col-start-2 md:col-span-11 lg:col-start-4 lg:col-span-9">
                        <hhds-link tabindex="0" class="md:col-start-3 md:col-start-5 lg:col-start-5 lg:col-span-2"><hhds-icon type="cross" slot="start"></hhds-icon>Close</hhds-link>
                        <hhds-link tabindex="0" class="col-span-6 sm:col-span-4 sm:col-start-5 md:col-start-8 md:col-span-5 lg:col-start-12 lg:col-span-1"><hhds-icon type="arrowright" slot="end"></hhds-icon>Next</hhds-link>
                    </div>

                    <!-- Scroll Container -->

                    <div class="hhds-peoplecarousel--modal--content-wrapper col-span-6 sm:col-span-8 md:col-start-2 md:col-span-11 lg:col-start-4 lg:col-span-9"  ref="scroll-container">
                        
                        <!-- Details -->
                        <div class="hhds-peoplecarousel--modal--content-col-1" ref="col-1">
                            <div ref="block-title">
                                <hhds-badge>BIO</hhds-badge>
                                <hhds-richtext>
                                <h2 class="heading heading--01">${data.name}<br/>${data.job_title ? '<em>' + data.job_title + '</em>' : '' }</h2>
                                </hhds-richtext>
                            </div>
                            <div ref="block-image">
                                <hhds-image alt="A portrait photograph of ${data.name}, ${data.job_title}" style="--aspect-ratio:1/1" src="${data.thumbnail}"></hhds-image>
                            </div>
                        </div>

                        <!-- Copy -->
                        <div class="hhds-peoplecarousel--modal--content-col-2">
                            <hhds-richtext>${data.description}</hhds-richtext>
                        </div>
                        
                    </div>

                    <!-- / Scroll Container -->
                </div>
            </div>
        </div>`;
		
			this.addModalClickHandlers();      
			resolve();
		  } else {
			console.error("Failed to create modal.");
			reject();
		  }
		});
	
	  }

      nextPersonKeyboard = (event: KeyboardEvent) => {
        DEBUG_VERBOSE && console.log(CLASS_NAME, 'nextPersonKeyboard');
    
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          this.nextPerson();
        }
      }
	
	  nextPerson = () => {
        this.killPeopleModalCoordinator();

		let currentId = this._modal?.getAttribute('post');  
		let nextId: string; 
	
		if (currentId) {
	
		  const currentIndex = this._postIds.indexOf(currentId);
		  if (currentIndex !== -1) {
			const nextIndex = (currentIndex + 1) % this._postIds.length;
			nextId = this._postIds[nextIndex];
	
			  this.createModal(nextId).then(() => { 
				this.showModal();
			  });
	
		  }
	
		}
	
	  }
	
	  populateModal() {    
		if (this._modal) this._modal.innerHTML = ``;
	  }
	
	  onTileClick = (event: any) => {  

		const el = event.target as HTMLElement;
		const postId = el.closest('hhds-tile')?.getAttribute('post');  
	
		if (postId) {
		  this.createModal(postId).then(() => {
			this.showModal();
		  });
	   }
	  }

      // ////////////////////////////////////////////////////////////////////

  get blockTitleHeight(){
    return this.blockTitleEl.clientHeight + 24;
  }

  get blockImageHeight(){
    return this.blockImageEl.clientHeight;
  }

    isOverflowing() {

        // If mobile size then we want to return true so the CSS uses the default relative position for the `block-title`
        if (window.innerWidth < 640) return true;

        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

        const computedStyle = window.getComputedStyle(this.blockColumn1El);

        const { paddingBottom, paddingTop } = computedStyle;

        const columnPaddingTop = parseFloat(paddingTop);
        const columnPaddingBottom = parseFloat(paddingBottom);

        const leftColContentHeight = columnPaddingTop + this.blockImageHeight + this.blockTitleHeight + columnPaddingBottom; // Excluding gap between title and image

        const delta = this.scrollContainerEl.clientHeight - leftColContentHeight;

        return delta < 0;
    }

	
    showModal() {
        document.body.style.overflow = 'hidden';  

        const wrapper = this._modal?.querySelector('#modal_content');
        const backdrop = this._modal?.querySelector('#modal_backdrop');

        if (this._modal) this.shadow.appendChild(this._modal);

        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

        const selectorScrollContainer = '[ref="scroll-container"]';

        this.scrollContainerEl = this.shadow.querySelector(selectorScrollContainer) as HTMLElement;

        if (!this.scrollContainerEl) throw new Error(`Unable to derive selector:  ${selectorScrollContainer}`);

        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

        const selectorColumn1 = '[ref="col-1"]';

        this.blockColumn1El = this.shadow.querySelector(selectorColumn1) as HTMLElement;

        if (!this.scrollContainerEl) throw new Error(`Unable to derive selector:  ${selectorColumn1}`);

        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

        const selectorBlockTitle = '[ref="block-title"]';

        this.blockTitleEl = this.shadow.querySelector(selectorBlockTitle) as HTMLElement;

        if (!this.blockTitleEl) throw new Error(`Unable to derive selector: ${selectorBlockTitle}`);

        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -/

        const selectorBlockImage = '[ref="block-image"]';

        this.blockImageEl = this.shadow.querySelector(selectorBlockImage) as HTMLElement;

        if (!this.blockImageEl) throw new Error(`Unable to derive selector: ${selectorBlockImage}`);

        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

        window.addEventListener('resize', this.modalOpenWindowResizeListener);

        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

        const initModalA11y = () => {
    
            if(!this._modal) throw new Error("No modal!");
    
            // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            
            const focusableItems = Array.from(this._modal?.querySelectorAll('[tabindex="0"]')) as HTMLElement[];
    
            this.peopleModalCoordinator = new PeopleModalCoordinator(this._modal, focusableItems);
    
            DEBUG_VERBOSE && console.log(CLASS_NAME, 'initModalA11y', focusableItems);
        }

        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

        requestAnimationFrame(() => {
            // A little time to re-render
            this.updateImageTopProperty();
            this.updateOverflowState();
            initModalA11y();
        });

        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

        // Check screen width
        if (window.innerWidth < 640) {
            // If screen width is less than 1024px, animate in from the bottom
            gsap.set([wrapper, backdrop], { transform: 'translateY(100%)' });  
            gsap.to([wrapper, backdrop], { transform: 'translateY(0)', duration: 1, ease: 'power2.out', delay: 0.2 });
        } else {
            // Default animation (slide in from the right)
            gsap.set([wrapper, backdrop], { transform: 'translateX(100%)' });  
            gsap.to([wrapper, backdrop], { transform: 'translateX(0)', duration: 1, ease: 'power2.out', delay: 0.2 });
        }
    }	

    // ////////////////////////////////////////////////////////////////////

    updateImageTopProperty(){

        const computedStyle = window.getComputedStyle(this.blockColumn1El);

        const { paddingBottom } = computedStyle;

        const imagePaddingBottom = parseFloat(paddingBottom);
        
        const imageTop = this.scrollContainerEl.clientHeight - this.blockImageEl.clientHeight - imagePaddingBottom;

        this.style.setProperty('--image-top', `${imageTop}px`);

        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

        const imageTopPortrait = this.blockTitleHeight + 48;

        this.style.setProperty('--image-top-portrait', `${imageTopPortrait}px`);
        
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        
        const imageTopOverflow = this.scrollContainerEl.clientHeight - this.blockImageHeight - imagePaddingBottom;

        this.style.setProperty('--image-top-overflow', `${imageTopOverflow}px`);
    }

    updateOverflowState(){

        if (this._modal) {
            this._modal.setAttribute('overflow', this.isOverflowing() ? 'true' : 'false');
        }
    }

    // ////////////////////////////////////////////////////////////////////

    modalOpenWindowResizeListener = () => {
        this.updateImageTopProperty();
        this.updateOverflowState();
        this.scrollContainerEl.scrollTop = 0;
    }
}

export const enum ArgSpecsKey {
	SlidesPerView = 'slidesPerView',
	TransitionSpeed = 'transitionSpeed',
	Type = 'type',
	Title = 'title',
	Nav = 'nav',
	Link = 'link',	
}

export const ArgSpecs: ArgSpecDictionary = {
	[ArgSpecsKey.Type]: {
		description: `The type of carousel.`,
		defaultValue: CarouselType.Community,
		type: CarouselType,
	},
	[ArgSpecsKey.Title]: {
		description: `The title of the carousel.`,
		defaultValue: '',
		type: String,
	},
	[ArgSpecsKey.Link]: {
		description: `The URL of the View All page.`,
		defaultValue: '',
		type: String,
	},
	[ArgSpecsKey.Nav]: {
		description: `Whether or not to show the navigation.`,
		defaultValue: 'true',
		type: String,
	},
	[ArgSpecsKey.SlidesPerView]: {
		description: `Number of slides per view (slides visible at the same time on slider's container).`,
		defaultValue: 1,
		type: Number,
	},

	[ArgSpecsKey.TransitionSpeed]: {
		description: `Duration of transition between slides (in ms)`,
		defaultValue: 300,
		type: Number,
	},
};
