import "./mastheadanimated-external.scss";
import css from "./mastheadanimated.scss?inline";
import globalStyles from "../../index.scss?inline";
import { ArgSpecDictionary, TypedVars } from "../component-utils";
import gsap from "gsap";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSMastheadAnimated";
export const HHDSMastheadAnimatedTagName: string = "hhds-mastheadanimated";
const TAG_NAME: string = HHDSMastheadAnimatedTagName;

export const HHDSMastheadAnimatedAttrNames = {
  DurationOnScreen: "durationOnScreen",
  DurationAnimation: "durationAnimation",
  EaseFunction: "easeFunction",
  EaseType: "easeType",
};

const Attrs = HHDSMastheadAnimatedAttrNames;

export enum HHDSMastheadAnimatedEaseFunction {
  Back = "back",
  Bounce = "bounce",
  Circ = "circ",
  Elastic = "elastic",
  Expo = "expo",
  Power1 = "power1",
  Power2 = "power2",
  Power3 = "power3",
  Power4 = "power4",
  Sine = "sine",
}

export enum HHDSMastheadAnimatedEaseType {
  In = "in",
  InOut = "inOut",
  Out = "out",
}

type State = {
  index: number;
  listChildren: HTMLElement[];
};

enum Opacity {
  Max = "1",
  Min = "0",
}

export class HHDSMastheadAnimated extends HTMLElement {
  private vars: TypedVars = new TypedVars(this);
  private shadow: ShadowRoot;
  private titleEl!: HTMLElement;
  private listEl!: HTMLElement;

  private tween!: gsap.core.Tween;

  private state: State = {
    index: 0,
    listChildren: [],
  };

  constructor() {
    super();
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");

    this.shadow = this.attachShadow({ mode: "open" });

    if (!this.shadow)
      throw new Error(`${CLASS_NAME} - Unable to attach shadow!`);

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  }

  static get observedAttributes() {
    return Object.keys(ArgSpecs);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Lifecycle Methods
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

  // Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {
    this.parseAttributes();

    this.render();

    window.addEventListener("resize", this.onResize.bind(this));

    requestAnimationFrame(() => {
      this.init();
    });
  }

  private init() {
    // Access the slot element
    const slot = this.shadow.querySelector("slot");

    if (!slot) throw new Error("Unable to determine the slot!");

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    // Get the assigned elements (the elements that are passed into the slot)
    const assignedElements = slot.assignedElements();

    DEBUG_VERBOSE && console.log({ assignedElements });

    if (assignedElements.length !== 1)
      throw new Error(
        `${CLASS_NAME} - There should only be one child in slot!`
      );

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    this.titleEl = assignedElements[0].querySelector(
      ".hhds-mastheadanimated-title"
    ) as HTMLElement;

    if (!this.titleEl)
      throw new Error(`${CLASS_NAME} - Unable to get title element!`);

    this.listEl = assignedElements[0].querySelector("ul") as HTMLElement;

    if (!this.listEl)
      throw new Error(`${CLASS_NAME} - Unable to derive list element!`);

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    DEBUG_VERBOSE &&
      console.log(
        `[${TAG_NAME}] Initialised`,
        assignedElements,
        this.listEl,
        this.listEl.children.length,
        this.titleEl.clientHeight
      );

    this.state.listChildren = Array.from(this.listEl.children) as HTMLElement[];

    this.state.listChildren.forEach((child, index) => {
      if (index !== 0) {
        child.style.opacity = Opacity.Min;
      }
    });

    this.animateList();
  }

  private animateList() {
    // Use the list child height for the scroll distance
    const nextY = this.state.listChildren[0].clientHeight * (this.state.index + 1) * -1;

    // Need to convert the duration from ms to seconds
    const durationOnScreen =
      this.vars.get<number>(Attrs.DurationOnScreen) / 1000;
    const durationAnimation =
      this.vars.get<number>(Attrs.DurationAnimation) / 1000;

    const easeFunction = this.vars.get<string>(Attrs.EaseFunction);
    const easeType = this.vars.get<string>(Attrs.EaseType);

    let ease = `${easeFunction}.${easeType}`;

    // `back` takes an argument
    // https://gsap.com/docs/v3/Eases/

    if (easeFunction === HHDSMastheadAnimatedEaseFunction.Back) {
      ease += "(2)";
    }

    DEBUG_VERBOSE && console.log({ easeFunction, easeType, ease });

    this.tween = gsap.to(this.listEl, {
      delay: durationOnScreen,
      duration: durationAnimation,
      y: nextY,
      ease,

      onStart: () => {
        // Ensure the current and next nodes are visible

        this.state.listChildren.forEach((child, index) => {
          const nextIndex =
            this.state.index < this.listEl.children.length - 1
              ? this.state.index + 1
              : 0;

          if (index === this.state.index || index === nextIndex) {
            child.style.opacity = Opacity.Max;
          } else {
            child.style.opacity = Opacity.Min;
          }
        });
      },

      onComplete: () => {
        // Set the next index considering the loop
        if (this.state.index < this.listEl.children.length - 2) {
          this.state.index += 1;
        } else {
          this.resetList();
        }

        // Ensure only the active child is visible once the animation is complete
        this.state.listChildren.forEach((child, index) => {
          if (index === this.state.index) {
            child.style.opacity = Opacity.Max;
          } else {
            child.style.opacity = Opacity.Min;
          }
        });

        this.animateList();
      },
    });
  }

  onResize() {
    if (this.tween) this.tween.kill();

    this.resetList();

    this.animateList();
  }

  resetList() {
    // Move to original position
    this.state.index = 0;
    gsap.set(this.listEl, { y: 0 });

    this.state.listChildren.forEach((child, index) => {
      if (index === 0) {
        child.style.opacity = Opacity.Max;
      } else {
        child.style.opacity = Opacity.Min;
      }
    });
  }

  parseAttributes() {
    this.vars.parse(this, ArgSpecs);
  }

  render() {
    this.shadow.innerHTML = `
      <style>${globalStyles}</style>
			<style>${css}</style>
			<div class="hhds-mastheadanimated">
				<div class="container">
					<div class="grid">
						<div class="col-span-6 sm:col-span-7 md:col-span-9">
							<hhds-richtext>
								<slot></slot>
							</hhd-richtext>
						</div>
					<div>
				</div>
			</div>
		`;
  }

  // Invoked each time the custom element is disconnected from the document's DOM.
  disconnectedCallback() {
    window.removeEventListener("resize", this.onResize.bind(this));
  }

  // Invoked each time the custom element is moved to a new document.
  adoptedCallback() {}

  // Invoked each time one of the custom element's attributes is added, removed, or changed.
  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    DEBUG_VERBOSE &&
      console.log(
        `Attribute ${name} has changed from ${oldValue} to ${newValue}.`
      );
    this.parseAttributes();
    this.render();
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.DurationOnScreen]: {
    description: "The duration of the pause between animations in ms",
    defaultValue: 1000,
    type: Number,
  },
  [Attrs.DurationAnimation]: {
    description: "The duration of the animation in ms",
    defaultValue: 600,
    type: Number,
  },
  [Attrs.EaseFunction]: {
    description: "Ease function",
    defaultValue: HHDSMastheadAnimatedEaseFunction.Back,
    type: HHDSMastheadAnimatedEaseFunction,
    typeString: "HHDSMastheadAnimatedEaseFunction",
  },
  [Attrs.EaseType]: {
    description: "Ease type",
    defaultValue: HHDSMastheadAnimatedEaseType.InOut,
    type: HHDSMastheadAnimatedEaseType,
    typeString: "HHDSMastheadAnimatedEaseType",
  },
};
