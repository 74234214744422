import css from "./video.scss?inline";
import globalStyles from "../../index.scss?inline";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSVideoYoutubeTagName } from "../VideoYoutube/VideoYoutube";
import { HHDSVideoDirectTagName } from "../VideoDirect/VideoDirect";
import { HHDSVideoVimeo, HHDSVideoVimeoTagName } from "../VideoVimeo/VideoVimeo";
import { Component } from "../../utils/Component";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSVideo";
export const HHDSVideoTagName: string = "hhds-video";
const TAG_NAME: string = HHDSVideoTagName;

export const HHDSVideoAttrNames = {
  service: "service",
  src: "src",
  cover: "cover",
  controls: "controls",
  autoplay: "autoplay",
  loop: "loop",
  aspect: "aspect",
};

const Attrs = HHDSVideoAttrNames;

export enum VideoServiceType {
  vimeo = "vimeo",
  youtube = "youtube",
  direct = "direct",
}

export class HHDSVideo extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    const videoServiceHtml = this.constructVideoServiceHtml();
    console.log("videoServiceHtml", videoServiceHtml);
    this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="${TAG_NAME}">${videoServiceHtml}<slot></slot></div>
		`;
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    this.observeSlotChanges(false);
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }

  async isPlaying(): Promise<boolean> {
    const service = this.vars.get<VideoServiceType>(Attrs.service);
    const slot = this.shadow.querySelector("slot");
    const video = slot?.assignedElements()[0];
    switch (service) {
      case VideoServiceType.vimeo:
        return (video as HHDSVideoVimeo).isPlaying();
      default:
        console.log(`pause() for '${service}' not implemented.`);
        break;
    }
    return Promise.resolve(false);
  }

  pause(): void {
    const service = this.vars.get<VideoServiceType>(Attrs.service);
    const slot = this.shadow.querySelector("slot");
    const video = slot?.assignedElements()[0];
    switch (service) {
      case VideoServiceType.vimeo:
        (video as HHDSVideoVimeo).pause();
        break;
      default:
        console.log(`pause() for '${service}' not implemented.`);
        break;
    }
  }

  play(): void {
    const service = this.vars.get<VideoServiceType>(Attrs.service);
    const slot = this.shadow.querySelector("slot");
    const video = slot?.assignedElements()[0];
    switch (service) {
      case VideoServiceType.vimeo:
        (video as HHDSVideoVimeo).play();
        break;
      default:
        console.log(`pause() for '${service}' not implemented.`);
        break;
    }
  }

  constructVideoServiceHtml(): string {
    // Define which attributes should be carried over directly from this Video component to the video service nested component.
    const passthroughAttrs: string[] = [
      Attrs.src,
      Attrs.cover,
      Attrs.controls,
      Attrs.autoplay,
      Attrs.loop,
      Attrs.aspect,
    ];
    // Determine which video service to use.
    let tag: string = "";
    const service = this.vars.get<VideoServiceType>(Attrs.service);
    switch (service) {
      case VideoServiceType.vimeo:
        tag = HHDSVideoVimeoTagName;
        break;
      case VideoServiceType.youtube:
        tag = HHDSVideoYoutubeTagName;
        break;
      case VideoServiceType.direct:
        tag = HHDSVideoDirectTagName;
        break;
    }
    // Construct the HTML for the video service component.
    let html: string = `<${tag}`;
    const attributes: { [key: string]: string } = {};
    Object.values(passthroughAttrs).forEach((attr) => {
      if (this.vars.has(attr)) attributes[attr] = this.vars.get<string>(attr);
    });
    Object.entries(attributes).forEach(([key, value]) => {
      html += ` ${key}="${value}"`;
    });
    html += `></${tag}>`;
    return html;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.service]: {
    description: "The video service to use.",
    defaultValue: VideoServiceType.vimeo,
    type: VideoServiceType,
    typeString: "VideoServiceType",
  },
  [Attrs.src]: {
    description: "The video source string",
    defaultValue: "",
    type: String,
  },
  [Attrs.cover]: {
    description: "The URL of the cover image.",
    defaultValue: "/video-cover.jpg",
    type: String,
  },
  [Attrs.controls]: {
    description: "Whether to show the video controls.",
    defaultValue: true,
    type: Boolean,
  },
  [Attrs.autoplay]: {
    description: "Whether to autoplay the video.",
    defaultValue: false,
    type: Boolean,
  },
  [Attrs.loop]: {
    description: "Whether to loop the video.",
    defaultValue: false,
    type: Boolean,
  },
  [Attrs.aspect]: {
    description: "The aspect ratio of the video.",
    defaultValue: "16/9",
    type: String,
  },
};
