import css from './resourcestableitem.scss?inline';
import globalStyles from '../../index.scss?inline';
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";

const DEBUG_VERBOSE: boolean  = false;
const CLASS_NAME: string = 'HHDSResourcesTableItem';
export const HHDSResourcesTableItemTagName: string = "hhds-resources-table-item";
const TAG_NAME: string = HHDSResourcesTableItemTagName;

export const HHDSResourcesTableItemAttrNames = {
  name: "name",
  type: "type",
  size: "size",
  file: "file",
};

const Attrs = HHDSResourcesTableItemAttrNames;

export class HHDSResourcesTableItem extends Component {

  constructor() {
    super();
    // The base class's constructor handles attachmennt of a shadow root and
    // adopted global styles. Access the shadow root via this.shadow.
    //
    // Use the constructor only for anything that will never need to be destroyed as part of the
    // component's update lifecycle. init() and destroy() are called for connectedCallback and
    // disconnectedCallback, and a destroy() init() pair is called if reinit() is utilised.
  }

  protected override init(): void {
    // The base class responds to connectedCallback() by collecting attributes into
    // this.vars, then calling init(). A call to super.init() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    const name = this.vars.get<string>(Attrs.name),
      type = this.vars.get<string>(Attrs.type),
      size = this.vars.get<string>(Attrs.size),
      file = this.vars.get<string>(Attrs.file);

    // For some components, re-assigning innerHTML may be appropriate on attribute and slot changes,
    // and without the need for a reinit(). In other cases, assigning innerHTML explicitly as part of
    // the init() routine is more appropriate.
		this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
      <div class="${TAG_NAME}">
        <a href="${file}" class="${TAG_NAME}__link" download></a>
        <p class="${TAG_NAME}__name table">${name}</p>
        <p class="${TAG_NAME}__type table">${type}</p>
        <p class="${TAG_NAME}__size table">${size}</p>
        <div class="${TAG_NAME}__icon">
          <hhds-icon type="downloadsimple" style="--icon-color: var(--color-primary-primary-500);">
        </div>
      </div>
		`;

    // If the component uses slots, use observeSlotChanges().
    // this.observeSlotChanges(true);
  }

  protected override destroy(): void {
    // The base class responds to disconnectedCallback() by collecting attributes into
    // this.vars, then calling destroy(). A call to super.destroy() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    // If the component uses slots, stop observing slot changes.
    // this.observeSlotChanges(false);
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    // Either call reinit() to have the component's destroy and init methods each be called,
    // or skip this step and handle update of the attribute directly. 'this.vars' will already
    // have been updated by the base Component class, so it can be immediately used to access
    // the new value.
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    // The base Component class must have access to this superclass's ArgSpecs.
    return ArgSpecs;
  }

}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.name]: {
    description: "Name of the file",
    defaultValue: "",
    type: String,
  },
  [Attrs.type]: {
    description: "Type of file",
    defaultValue: "",
    type: String,
  },
  [Attrs.size]: {
    description: "Size of the file",
    defaultValue: "",
    type: String,
  },
  [Attrs.file]: {
    description: "Link to the file",
    defaultValue: "",
    type: String,
  },
};

