import css from './logogrid.scss?inline';
import globalStyles from '../../index.scss?inline';

import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";

const DEBUG_VERBOSE: boolean  = false;
const CLASS_NAME: string = 'HHDSLogoGrid';
export const HHDSLogoGridTagName: string = "hhds-logogrid";
const TAG_NAME: string = HHDSLogoGridTagName;

export const HHDSLogoGridAttrNames = {
  myNumericArg: "my-numeric-arg",
};

const Attrs = HHDSLogoGridAttrNames;

export class HHDSLogoGrid extends Component {

  constructor() {
    super();
    // The base class's constructor handles attachmennt of a shadow root and
    // adopted global styles. Access the shadow root via this.shadow.
    //
    // Use the constructor only for anything that will never need to be destroyed as part of the
    // component's update lifecycle. init() and destroy() are called for connectedCallback and
    // disconnectedCallback, and a destroy() init() pair is called if reinit() is utilised.
  }

  protected override init(): void {
    // The base class responds to connectedCallback() by collecting attributes into
    // this.vars, then calling init(). A call to super.init() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    const argValue = this.vars.get<number>(Attrs.myNumericArg);
    console.log(`${Attrs.myNumericArg}: ${argValue} (type: ${typeof argValue})`);

    // For some components, re-assigning innerHTML may be appropriate on attribute and slot changes,
    // and without the need for a reinit(). In other cases, assigning innerHTML explicitly as part of
    // the init() routine is more appropriate.
		this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
      <div class="${TAG_NAME} container">
        <div class="grid grid--logo">
          <slot></slot>
        </div>
      </div>
		`;

    const contentSlot = this.shadowRoot?.querySelector('slot') as HTMLSlotElement;
    const slottedContent = contentSlot?.assignedNodes({ flatten: true });    
    
    slottedContent?.forEach((node: Node) => {
      if (node.nodeType === Node.ELEMENT_NODE && (node as HTMLElement).tagName.toLowerCase() === 'hhds-image') {
        const element = node as HTMLElement;
    
        const wrapperDiv = document.createElement('div');
        wrapperDiv.classList.add('logogrid--image', 'col-span-2');    
        element.parentNode?.insertBefore(wrapperDiv, element);
        wrapperDiv.appendChild(element);
      }
    });    


    // If the component uses slots, use observeSlotChanges().
    // this.observeSlotChanges(true);
  }

  protected override destroy(): void {
    // The base class responds to disconnectedCallback() by collecting attributes into
    // this.vars, then calling destroy(). A call to super.destroy() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    // If the component uses slots, stop observing slot changes.
    // this.observeSlotChanges(false);
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    // Either call reinit() to have the component's destroy and init methods each be called,
    // or skip this step and handle update of the attribute directly. 'this.vars' will already
    // have been updated by the base Component class, so it can be immediately used to access
    // the new value.
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    // The base Component class must have access to this superclass's ArgSpecs.
    return ArgSpecs;
  }

}

export const ArgSpecs: ArgSpecDictionary = {
  /*my-enum: {
    description: "Some enum-based argument.",
    defaultValue: MyEnum.myValue,
    typeString: "MyEnum",
    type: MyEnum,
  },*/
  [Attrs.myNumericArg]: {
    description: "An argument to demonstrate the typed args system.",
    defaultValue: 42,
    //category: "General",
    type: Number,
  },
};

