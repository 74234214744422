import css from "./imagefeature.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSImageFeature";
export const HHDSImageFeatureTagName: string = "hhds-image-feature";
const TAG_NAME: string = HHDSImageFeatureTagName;

export const HHDSImageFeatureAttrNames = {
  bgSrc: "bg-src",
  logoSrc: "logo-src",
  logoAlt: "logo-alt",
  subheading: "subheading",
  overlay: "overlay",
  useHhdsImage: "use-hhds-image",
};

export const HHDSImageFeatureSlotNames = {
  heading: "heading",
  buttons: "buttons",
};

const Attrs = HHDSImageFeatureAttrNames;
const Slots = HHDSImageFeatureSlotNames;

export class HHDSImageFeature extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    const bgImageSrc = this.vars.get<string>(Attrs.bgSrc);
    const subheadingText = this.vars.get<string>(Attrs.subheading);
    const logoSrc = this.vars.get<string>(Attrs.logoSrc);
    const logoAlt = this.vars.get<string>(Attrs.logoAlt);
    const showOverlay = this.vars.get<string>(Attrs.overlay);

    let bgImageHtml: string = "";
    if (bgImageSrc) {
      if (this.vars.get<boolean>(Attrs.useHhdsImage)) {
        bgImageHtml = `<hhds-image 
          class="bg-image" 
          animate="true" 
          src="${bgImageSrc}"
          ${this.getAttribute('srcSet') !== null ? `srcSet="${this.getAttribute('srcSet')}"` : ``} 
          ${this.getAttribute('sizes') !== null ? `sizes="${this.getAttribute('sizes')}"` : ``} 
          alt="Background image" />`;
      } else {
        bgImageHtml = `<img class="bg-image" src="${bgImageSrc}" alt="Background image" />`;
      }
    }

    const logoHtml = logoSrc ? `<img class="logo-img" src="${logoSrc}" ${logoAlt && `alt="${logoAlt}" aria-label="${logoAlt}" tabindex="1"`} />` : "";

    let badgeHtml: string = "";
    if (!logoSrc && subheadingText?.length) {
      badgeHtml = `<hhds-badge class="subheading">${subheadingText}</hhds-badge>`;
    }

    let bgOverlayHtml: string = "";
    if (showOverlay) {
      bgOverlayHtml = showOverlay ? `<div class="bg-overlay"></div>` : "";
    }

    this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
      <div class="container">
        <div class="${TAG_NAME}">
          <div class="main-content-container">
            ${badgeHtml}
            ${logoHtml}
            <slot name=${Slots.heading}></slot>
          </div>
          <div class="button-group-container">
              <slot name="${Slots.buttons}"></slot>
          </div>
          ${bgOverlayHtml}
          ${bgImageHtml}
        </div>
      </div>
		`;
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.subheading]: {
    description: "Subheading text (appears in a badge)",
    defaultValue: "",
    type: String,
  },
  [Attrs.logoSrc]: {
    description: "Logo image source",
    defaultValue: "",
    type: String,
  },
  [Attrs.logoAlt]: {
    description: "Logo image alt-text",
    defaultValue: "",
    type: String,
  },
  [Attrs.bgSrc]: {
    description: "Background image source",
    defaultValue: "",
    type: String,
  },
  [Attrs.overlay]: {
    description: "Whether to show an overlay on the background image",
    defaultValue: "",
    type: Boolean,
  },
  [Attrs.useHhdsImage]: {
    description: "Whether to use the hhds-image component for the background image",
    defaultValue: true,
    type: Boolean,
  },
};
