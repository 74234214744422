import css from "./taggroup.scss?inline";
import globalStyles from "../../index.scss?inline";
import { ArgSpecDictionary } from "../component-utils";
import { Component } from "../../utils/Component";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSTagGroup";
export const HHDSTagGroupTagName: string = "hhds-taggroup";

export class HHDSTagGroup extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="hhds-taggroup"><slot></slot></div>
		`;
  }

  protected override destroy(): void {}

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {};
