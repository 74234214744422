export class FocusHandler {
  private target: HTMLElement;
  private trapFocusFunc: any;
  private firstFocusableElement!: HTMLElement;
  private lastFocusableElement!: HTMLElement;
  private customFocusableElements: HTMLElement[] | null = null;
  private preTrapFocusedElement: HTMLElement | null = null;
  private focusIndex: number = -1;

  constructor(target: HTMLElement) {
    this.target = target;
    this.trapFocusFunc = this.handleTrapFocus.bind(this);
  }

  updateFocusableElements(focusableElements: HTMLElement[] | null = null): void {
    this.customFocusableElements = focusableElements;
  }

  setPreTrapFocusedElement(element: HTMLElement | null) {
    this.preTrapFocusedElement = element;
  }

  trap(): void {
    this.focusIndex = 0;
    this.firstFocusableElement = this.focusableElements[this.focusIndex] as HTMLElement;
    this.lastFocusableElement = this.focusableElements[this.focusableElements.length - 1] as HTMLElement;
    this.focusableElements.forEach((element, index) => {
      element.setAttribute("tabindex", (index + 10000).toString());
    });
    this.firstFocusableElement?.focus();
    document.addEventListener("keydown", this.trapFocusFunc);
  }

  untrap(): void {
    this.customFocusableElements = null;
    document.removeEventListener("keydown", this.trapFocusFunc);
    this.preTrapFocusedElement?.focus();
    this.preTrapFocusedElement = null;
  }

  dispose(): void {
    this.untrap();
  }

  private get focusableElements(): HTMLElement[] {
    if (this.customFocusableElements) {
      return this.customFocusableElements;
    }
    const nodeList = this.target.querySelectorAll(
      "button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])"
    ) as NodeListOf<HTMLElement>;
    return Array.from(nodeList);
  }

  private handleTrapFocus(event: KeyboardEvent): void {
    const isTabPressed = event.key === "Tab";
    if (!isTabPressed) return;
    if (this.focusableElements.length === 1) {
      event.preventDefault();
      return;
    }
    if (event.shiftKey) {
      this.focusIndex--;
      if (this.focusIndex < 0) {
        event.preventDefault();
        this.lastFocusableElement.focus();
        this.focusIndex = this.focusableElements.length - 1;
      }
    } else {
      this.focusIndex++;
      if (this.focusIndex >= this.focusableElements.length) {
        event.preventDefault();
        this.firstFocusableElement.focus();
        this.focusIndex = 0;
      }
    }
  }
}
