import css from "./linkpreview.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSIconType } from "../Icon/IconType";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSLinkPreview";
export const HHDSLinkPreviewTagName: string = "hhds-link-preview";
const TAG_NAME: string = HHDSLinkPreviewTagName;

export enum HHDSLinkPreviewType {
  internal = "internal",
  external = "external",
  download = "download",
}

export const HHDSLinkPreviewAttrNames = {
  type: "type",
  label: "label",
  href: "href",
  target: "target",
  imageSrc: "image-src",
  description: "description",
};

const Attrs = HHDSLinkPreviewAttrNames;

export class HHDSLinkPreview extends Component {
  private listenerFuncs: { [key: string]: any } = {};
  private component: HTMLElement | null = null;

  constructor() {
    super();

    this.listenerFuncs["mouseover"] = this.handleMouseOver.bind(this);
    this.listenerFuncs["mouseout"] = this.handleMouseOut.bind(this);
  }

  private get iconForLinkType(): string {
    switch (this.vars.get<HHDSLinkPreviewType>(Attrs.type)) {
      case HHDSLinkPreviewType.internal:
        return HHDSIconType.ArrowRight;
      case HHDSLinkPreviewType.external:
        return HHDSIconType.ArrowUpRight;
      case HHDSLinkPreviewType.download:
        return HHDSIconType.DownloadSimple;
    }
    return "";
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    let type = this.vars.get<HHDSLinkPreviewType>(Attrs.type);
    let target = type == HHDSLinkPreviewType.external ? "_blank" : "_self";  
    let href = this.vars.get<string>(Attrs.href);

    this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="${TAG_NAME}">
        <a href="${href}" target="${target}" ${type == HHDSLinkPreviewType.download ? 'download' : ''}>
          <hhds-image
            animate="true"
            src="${this.vars.get<string>(Attrs.imageSrc)}"
            ${this.getAttribute('srcSet') !== null ? `srcset="${this.getAttribute('srcSet')}` : `` }"
            ${this.getAttribute('sizes') !== null ? `sizes="${this.getAttribute('sizes')}` : `` }"
            alt="${this.vars.get<string>(Attrs.label)}"></hhds-image>
          <div class="hhds-link-preview__spacer"></div>
          <div class="hhds-link-preview__content">
            <div class="hhds-link-preview__left">
              <p class="body hhds-link-preview__label">${this.vars.get<string>(Attrs.label)}</p>
              <p class="body hhds-link-preview__description">${this.vars.get<string>(Attrs.description)}</p>
            </div>
            <div class="hhds-link-preview__right">
              <hhds-icon type="${this.iconForLinkType}"></hhds-icon>
            </div>
          </div>
        </a>
	    </div>
  	`;

    this.component = this.shadow.querySelector(`.${TAG_NAME}`);
    this.component?.addEventListener("mouseover", this.listenerFuncs["mouseover"]);
    this.component?.addEventListener("mouseout", this.listenerFuncs["mouseout"]);
  }

  private handleMouseOver(): void {}

  private handleMouseOut(): void {}

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    this.component?.removeEventListener("mouseover", this.listenerFuncs["mouseover"]);
    this.component?.removeEventListener("mouseout", this.listenerFuncs["mouseout"]);
    this.component?.removeEventListener("click", this.listenerFuncs["click"]);
    this.component = null;
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.type]: {
    description: "The type of link",
    defaultValue: HHDSLinkPreviewType.download,
    typeString: "HHDSLinkPreviewType",
    type: HHDSLinkPreviewType.download,
  },
  [Attrs.label]: {
    description: "The text displayed under the image.",
    defaultValue: "Label",
    type: String,
  },
  [Attrs.href]: {
    description: "The URL the link points to.",
    defaultValue: "",
    type: String,
  },
  [Attrs.target]: {
    description: "The target of the link.",
    defaultValue: "_blank",
    type: String,
  },
  [Attrs.imageSrc]: {
    description: "The URL of the image to display.",
    defaultValue: "",
    type: String,
  },
  [Attrs.description]: {
    description: "The description of the URL or download.",
    defaultValue: "Description",
    type: String,
  },
};
