import css from './select.scss?inline';
import globalStyles from '../../index.scss?inline';
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";

import { HHDSSelectState } from "./SelectState";


const DEBUG_VERBOSE: boolean  = false;
const CLASS_NAME: string = 'HHDSSelect';
export const HHDSSelectTagName: string = "hhds-select";

export const HHDSSelectAttrNames = {

	"required": "required",
	"id": "id",
	"placeholder": "placeholder",
	"options": "options",
	"label": "label",
	"state": "state"
};

const Attrs = HHDSSelectAttrNames;

export class HHDSSelect extends Component {

	public value: string = '';

  constructor() {
    super();
    // The base class's constructor handles attachmennt of a shadow root and
    // adopted global styles. Access the shadow root via this.shadow.
    //
    // Use the constructor only for anything that will never need to be destroyed as part of the
    // component's update lifecycle. init() and destroy() are called for connectedCallback and
    // disconnectedCallback, and a destroy() init() pair is called if reinit() is utilised.
  }

  protected override init(): void {
    // The base class responds to connectedCallback() by collecting attributes into
    // this.vars, then calling init(). A call to super.init() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

	const required = this.vars.get<boolean>("required"),
		  id = this.vars.get<string>("id"),
		  label = this.vars.get<string>("label"),
		  placeholder = this.vars.get<string>("placeholder"),
		  state = this.vars.get<HHDSSelectState>("state"),
		  options = JSON.parse(this.vars.get<Object>("options"));


    // For some components, re-assigning innerHTML may be appropriate on attribute and slot changes,
    // and without the need for a reinit(). In other cases, assigning innerHTML explicitly as part of
    // the init() routine is more appropriate.

	// const optionsHTML = options
    // 	.filter((item: any) => item.length > 0)
    // 	.map((item: any) => `
    //     	<option value="${item.value}">${item.name}</option>
    // 	`)
    // 	.join('');

	let optionsHTML = '';
	options.forEach((item: any) => {	
		optionsHTML  += `<option value="${item.value}">${item.name}</option>`;
	});

	this.shadow.innerHTML = `
		<style>${globalStyles}</style>
		<style>${css}</style>
		<div class="hhds-select ${state == 'error' ? 'hhds-select--error' : ""}">

		<select class="hhds-select__select ${placeholder.length > 0 ? "hhds-select__select--placeholder":""} ${label.length > 0 ? 'hhds-select__select--label': ''} form-text" ${required ? "required" : "" } ${id ? `id="${id}" name="${id}"` : ""} aria-label="Select">
			${placeholder.length > 0 ? `<option class="hhds-select__option" value="" disabled selected>${placeholder}</option>` : ``}
			${optionsHTML}
			${label.length > 0 ? `<label class="hhds-select__label label" ${id ? ` for="${id}" ` : ''}">${label}</label>` : ""}
			${required && state == 'error' ? `<span class="hhds-select__required label">Required</span>` : ""}
		</div>`;

    // If the component uses slots, use observeSlotChanges().
    // this.observeSlotChanges(true);

	this.addListeners();	

  }

  private addListeners(): void {	
	const select = this.shadow.querySelector('select') as HTMLSelectElement;
	select.addEventListener('change', () => {	
		this.value = select.value;	
		this.emitEvent('selectChanged', this.value);
	})
  }

  protected override destroy(): void {
    // The base class responds to disconnectedCallback() by collecting attributes into
    // this.vars, then calling destroy(). A call to super.destroy() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    // If the component uses slots, stop observing slot changes.
    // this.observeSlotChanges(false);
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    // Either call reinit() to have the component's destroy and init methods each be called,
    // or skip this step and handle update of the attribute directly. 'this.vars' will already
    // have been updated by the base Component class, so it can be immediately used to access
    // the new value.
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    // The base Component class must have access to this superclass's ArgSpecs.
    return ArgSpecs;
  }

}

export const ArgSpecs: ArgSpecDictionary = {
	[Attrs.required]: {
		description: "The requried attribute that is assigned to the input",
		defaultValue: false,
		type: Boolean,
	},
	[Attrs.id]: {
		description: "Assigned to the following attributes: select's name, select's id and label's for",
		defaultValue: '',
		type: String,
	},
	[Attrs.placeholder]: {
		description: "The default placeholder that is assigned to the select",
		defaultValue: '',
		type: String,
	},
	[Attrs.options]: {
		description: "String encoded JSON array of name/value pairs",
		defaultValue: '',
		type: String,
	},
	[Attrs.label]: {
		description: "The label that is assigned to the select",
		defaultValue: '',
		type: String,
	},
	[Attrs.state]: {
		description: "The select state",
		defaultValue: HHDSSelectState.Default,
		type: HHDSSelectState,
	},
};
