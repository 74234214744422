import css from "./scrolldownbutton.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSHero } from "../Hero/Hero";
import { HHDSButton, HHDSButtonTagName } from "../Button/Button";

const DEBUG_VERBOSE: boolean = false;
export const HHDSScrollDownButtonTagName: string = "hhds-scroll-down-button";
const TAG_NAME: string = HHDSScrollDownButtonTagName;
const LOG_PREFIX: string = `[${TAG_NAME}]`;

export const HHDSScrollDownButtonAttrNames = {
  heroSelector: "hero-selector",
  fadeAfter100vh: "fade-after-100vh",
};
const Attrs = HHDSScrollDownButtonAttrNames;

const SYNC_TO_ANIMATION_FRAME: boolean = false;

export class HHDSScrollDownButton extends Component {
  private scrollFunc: any;
  private clickFunc: any;
  private resizeFunc: any;
  private isScrolling: boolean = false;
  private hero: HHDSHero | null = null;

  constructor() {
    super();
    this.scrollFunc = this.onScroll.bind(this);
    this.resizeFunc = this.onResize.bind(this);
    this.clickFunc = this.onClick.bind(this);
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(LOG_PREFIX, "init");
    this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
      <div class="${TAG_NAME}">
        <div class="container">
          <div class="scroll-down-button-container">
            <hhds-button type='overlay'><hhds-icon slot="end" type="arrowdown"></hhds-icon></hhds-button>
          </div>
        </div>
      </div>
		`;

    window.addEventListener("scroll", this.scrollFunc);
    window.addEventListener("resize", this.resizeFunc);

    const button = this.shadow.querySelector(HHDSButtonTagName);
    button?.addEventListener("click", this.clickFunc);

    const heroSelector = this.vars.get(Attrs.heroSelector);

    DEBUG_VERBOSE && console.log(LOG_PREFIX, "hero-selector: " + heroSelector);
    if (heroSelector?.length) this.hero = document.body.querySelector(heroSelector);
    DEBUG_VERBOSE && console.log(LOG_PREFIX, "hero:", this.hero);
    if (this.hero) {
      const cursor = this.hero.getCursor();
      const button = this.shadow.querySelector(HHDSButtonTagName) as HHDSButton;
      cursor?.addObstacle(button);
    }

    this.updateButtonPosition();
  }

  private onClick(_event: Event): void {
    const anchor = this.hero?.bottomAnchor;
    if (!anchor) return;
    //anchor.scrollIntoView({ behavior: "smooth", block: "start" });
    const rect = anchor.getBoundingClientRect();
    const yPos = rect.top + window.scrollY;
    window.scrollTo({ top: yPos, behavior: "smooth" });
  }

  private updateButtonPosition(): void {
    const component = this.shadow.querySelector(`.${TAG_NAME}`) as HTMLElement;
    if (!component) return;
    const heroRect = this.hero?.getBoundingClientRect();
    const heroBottom = heroRect?.bottom || Infinity;
    const viewportBottom = window.innerHeight;
    const thisRect = component.getBoundingClientRect();
    const top = Math.min(heroBottom, viewportBottom) - thisRect.height;
    //component.style.top = `${top}px`;
    component.style.top = `0px`;
    component.style.transform = `translateY(${top}px)`;

    if (top < -10) this.hide();
  }

  private onScroll(_event: Event): void {
    if (this.vars.get(Attrs.fadeAfter100vh)) {
      if (window.scrollY > window.innerHeight) {
        this.shadow.querySelector(`.${TAG_NAME}`)?.classList.add("faded");
      }
    }
    if (!this.isScrolling) {
      if (SYNC_TO_ANIMATION_FRAME) {
        window.requestAnimationFrame(() => {
          this.updateButtonPosition();
          this.isScrolling = false;
        });
        this.isScrolling = true;
      } else {
        this.updateButtonPosition();
        this.isScrolling = false;
      }
    }
  }

  private onResize(_event: Event): void {
    this.updateButtonPosition();
  }

  hide(): void {
    const component = this.shadow.querySelector(`.${TAG_NAME}`);
    component?.classList.add("hidden");
    this.hero?.getCursor()?.clearObstacleState();
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(LOG_PREFIX, "destroy");
    this.hero = null;
    window.removeEventListener("scroll", this.scrollFunc);
    window.removeEventListener("resize", this.resizeFunc);
    const button = this.shadow.querySelector(HHDSButtonTagName);
    button?.addEventListener("click", this.clickFunc);
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(LOG_PREFIX, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(LOG_PREFIX, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(LOG_PREFIX, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.heroSelector]: {
    description: "The selector for the hero element to interact with.",
    type: String,
    defaultValue: "",
  },
  [Attrs.fadeAfter100vh]: {
    description: "Whether to fade the button after 100vh.",
    type: Boolean,
    defaultValue: true,
  },
};
