import css from './tilegrid.scss?inline';
import globalStyles from '../../index.scss?inline';
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSTileGridSource, HHDSTileGridType } from './TileGridType';
import { HHDSTileGridColumns } from './TileGridColumns';

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = 'HHDSTileGrid';
export const HHDSTileGridTagName: string = "hhds-tilegrid";

export const HHDSTileGridAttrNames = {
  type: "type",
  source: "source",
  columns: "columns",
  collapse: "collapse"
};

const Attrs = HHDSTileGridAttrNames;
const CAROUSEL_BREAKPOINT = 640;

interface InsightAJAXResponse {
  remaining: number;
  posts: InsightAJAXPost[];
}

interface InsightAJAXPost {
  name: string;
  thumbnails: any;
  alt: string;
  description: string;
  external: string;
  link: string;
  post: string;
  categories: any[];
  date?: string;
}

interface WPAjaxObject {
  ajax_url: string;
  nonce: string;
}

declare var ajax_obj: WPAjaxObject;

export class HHDSTileGrid extends Component {
  private isGrid: boolean = true;
  private btnLoadMore: HTMLElement | null = null;
  private numLoaded: number = 0;  
  private loading: boolean = false;
  private loadMoreObserver: IntersectionObserver | null = null;

  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");
    this.renderAsGrid();

    if (this.vars.get('collapse')) {
      this.addResizeListener();
      if (window.innerWidth < CAROUSEL_BREAKPOINT) {
        this.renderAsCarousel();
      }
    }

    this.btnLoadMore = this.querySelector('hhds-button');  
    if (this.btnLoadMore) {
      this.addLoadMoreListener();
    }

  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    this.removeResizeListener();
    this.removeLoadMoreListener();
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }

  private addResizeListener() {
    window.addEventListener('resize', this.onResize);
  }

  private removeResizeListener() {
    window.removeEventListener('resize', this.onResize);
  }

  private onResize = () => {
    if (window.innerWidth < CAROUSEL_BREAKPOINT) {
      if (this.isGrid) {
        this.renderAsCarousel();
      }
    } else {
      if (!this.isGrid) {
        this.renderAsGrid();
      }
    }
  }

  private addInfiniteScrollListener() { 

    if (this.btnLoadMore && !this.loadMoreObserver) {

      const callback = (entries: IntersectionObserverEntry[], observer:IntersectionObserver) => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !this.loading) {
            observer.unobserve(entry.target);
            this.loadMore();
          }
        });
      };

      this.loadMoreObserver = new IntersectionObserver(callback, {
        root: null,
        rootMargin: '0px',
        threshold: 1.0 
      });

      this.loadMoreObserver.observe(this.btnLoadMore);

    }

  }

  private removeInfiniteScrollListener() {
    if (this.loadMoreObserver) {
      this.loadMoreObserver.disconnect();
      this.loadMoreObserver = null;
    }
  }

  private addLoadMoreListener() {
    this.btnLoadMore?.addEventListener('click', this.loadMore.bind(this));
    this.addInfiniteScrollListener();
  }

  private removeLoadMoreListener() {
    this.btnLoadMore?.removeEventListener('click', this.loadMore.bind(this));
  }

  private async loadMore() {

    this.removeInfiniteScrollListener();

    if (this.btnLoadMore) {
      this.btnLoadMore.innerHTML = "Loading...";
    }

    const data: InsightAJAXResponse | null = await this.fetchMoreData(this.numLoaded);

    if (data && data.posts) {
      this.numLoaded += data.posts.length;      
      this.renderFetchedData(data.posts);

      const remaining = data.remaining;

      if (remaining > 0 && this.btnLoadMore) {
        this.btnLoadMore.innerHTML = "Load More";
        this.addInfiniteScrollListener();
      } else {
        this.removeLoadMoreButton();
      }
    } else {
      this.removeLoadMoreButton();
    }

  }

  private async fetchMoreData(offset: number = 0 ): Promise<InsightAJAXResponse | null> {
    try {
      const response = await fetch(ajax_obj.ajax_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          action: 'fetch_more',
          offset: offset.toString(),
          nonce: ajax_obj.nonce,
          source: this.vars.get('source')
        })
      });

      const result = await response.json();

      if (result.success) {
        return result.data;
      } else {
        console.error("Fetch request failed.");
        return null;
      }

    } catch (error) {
      console.error("Failed to fetch data.", error);
      return null;
    }
  }

  private renderFetchedData(data: InsightAJAXPost[]) {

    data.forEach(item => {

      const tile = document.createElement('hhds-tile');

      let aspect = 'auto';
      let object_fit = 'fill';

      switch (this.vars.get('source')) {

        case HHDSTileGridSource.Community: 
          tile.classList.add("col-span-6","sm:col-span-4","md:col-span-4");
          break;
        case HHDSTileGridSource.Insight: 
          tile.classList.add("col-span-3","sm:col-span-4","md:col-span-4");
          break;
        case HHDSTileGridSource.Office: 
          aspect = '1/1';
          object_fit = 'cover';
          tile.classList.add("col-span-3","sm:col-span-4");
          break;
        case HHDSTileGridSource.Home: 
          aspect = '4/3';
          object_fit = 'cover';
          break;
        case HHDSTileGridSource.People: 
          aspect = '1/1';
          object_fit = 'cover';
          tile.classList.add("col-span-3","sm:col-span-2","md:col-span-3");
          break;
        case HHDSTileGridSource.Press: 
          aspect = '16/9';
          object_fit = 'cover'; 
          tile.classList.add("col-span-6","sm:col-span-4","md:col-span-4");
          break;

      }

      if (this.vars.get('source') == 'press' && item.date) {
        tile.setAttribute('date', item.date);
      } 

      tile.setAttribute('source', this.vars.get('source'));  
      tile.setAttribute('label', item.name);
      tile.setAttribute('description', item.description); 
      tile.setAttribute('target', item.external ? '_blank' : '_self');  
      tile.setAttribute('url', item.link); 

      if (item.thumbnails?.base) {
        tile.innerHTML = `
          <hhds-image src="${item.thumbnails.base}" srcset="
              ${item.thumbnails['640']} 640w,
              ${item.thumbnails['960']} 960w,
              ${item.thumbnails['1280']} 1280w
            " size="
              (min-width: 1440px) 1280px,
              (min-width: 640px) 960px,
              100vw
            " alt="${item.alt}" style="--image-aspect-ratio: ${aspect}; --image-object-fit: ${object_fit};" animate="true" slot="image">
          </hhds-image>      
        `;
      }

      if (item.categories) {

        let html = '<hhds-taggroup slot="tags">'; 

        for (let i=0; i<2; i++){

          if (item.categories[i]) {
            html += `<hhds-tag>${item.categories[i].name}</hhds-tag>`;
          }

        }

        if (item.categories.length > 2) { 
          html += `<hhds-tag>+${item.categories.length - 2}</hhds-tag>`;  
        }

        html += `</hhds-taggroup>`;

        tile.innerHTML += html;        

      }

      if (item.external) {
        tile.innerHTML += `<hhds-icon slot="icon" type="arrowupright"></hhds-icon>`;
      }

      this.btnLoadMore?.before(tile); 

    });

  }

  private removeLoadMoreButton() {
    if (this.btnLoadMore) {
      this.removeInfiniteScrollListener();
      this.btnLoadMore.remove();
    }
  }

  private renderAsCarousel() {
    this.isGrid = false;

    const slotElement = this.shadow.querySelector('slot');
    const assignedNodes = slotElement?.assignedNodes({ flatten: true });

    if (assignedNodes) {
      const content = assignedNodes
        .map(node => (node as HTMLElement).outerHTML)
        .join('') || '';

      this.shadow.innerHTML = `
        <hhds-carousel nav="false" type="custom" slidesperview="3">
          ${content}
        </hhds-carousel>
      `;
    }
  }

  private renderAsGrid() {
    this.isGrid = true;

    const classes = (this.vars.get('type') == 'custom') ? 'grid--tiles grid--tiles--spacing' : `grid--tiles--${this.vars.get('source')} grid--tiles--${this.vars.get('source')}--spacing`;

    this.shadow.innerHTML = `
      <style>${globalStyles}</style>
      <style>${css}</style>
      <div class="container">
        <div class="grid ${classes}">
          <slot></slot>
        </div>
      </div>
    `;

    const slot = this.shadow.querySelector('slot');
    const assignedNodes = slot?.assignedNodes({ flatten: true });

    assignedNodes?.forEach(node => {
      if (node.nodeType === Node.ELEMENT_NODE && (node as HTMLElement).tagName.toLowerCase() === 'hhds-tile') {

        this.numLoaded++;

        const element = node as HTMLElement;
        if (this.vars.get('columns') == '4') {
          element.classList.add('col-span-3', 'sm:col-span-2', 'md:col-span-3');
        } else if (this.vars.get('columns') == '3') {
          element.classList.add("col-span-6", "sm:col-span-4", "md:col-span-4");
        }
      }
    });

    if (this.vars.get('type') == HHDSTileGridType.Posts && this.numLoaded == 6) {

      // We only want to show the load more button if the grid is not collapsed or if not set to collapse
      if (!this.vars.get('collapse') || window.matchMedia("(min-width: 640px)").matches) {
        // this.addLoadMoreButton();
      }

    }

  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.columns]: {
    description: "The number of columns to show in the grid.",
    defaultValue: 3,
    type: HHDSTileGridColumns
  },
  [Attrs.collapse]: {
    description: "Collapse to a carousel on small screens.",
    defaultValue: false,
    type: Boolean
  },
  [Attrs.type]: {
    description: "The type of grid",
    defaultValue: HHDSTileGridType.Posts,
    type: HHDSTileGridType
  },
  [Attrs.source]: {
    description: "The source of data for the grid",
    defaultValue: HHDSTileGridSource.Community,
    type: HHDSTileGridSource
  },
};