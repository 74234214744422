import css from "./videopreview.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSVideoVimeoAttrNames } from "../VideoVimeo/VideoVimeo";
import { HHDSModal, HHDSModalEvent } from "../Modal/Modal";
import { HHDSIconType } from "../Icon/IconType";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSVideoPreview";
export const HHDSVideoPreviewTagName: string = "hhds-video-preview";
//const TAG_NAME: string = HHDSVideoPreviewTagName;

export const HHDSVideoPreviewAttrNames = {
  imgSrc: "img-src",
  alt: "alt",
  caption: "caption",
  modalSrc: "modal-src",
  modalCover: "modal-cover",
  icon: "icon",
};

const Attrs = HHDSVideoPreviewAttrNames;

export class HHDSVideoPreview extends Component {
  private clickFunc: any;
  private modalShowFunc: any;
  private modalHideFunc: any;
  private clickListenerAdded: boolean = false;

  constructor() {
    super();
    this.createEventCallbacks();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    const createVideoAttributes = (): string => {
      let videoAttributes = {
        [HHDSVideoVimeoAttrNames.src]: this.vars.get<string>(Attrs.modalSrc),
        [HHDSVideoVimeoAttrNames.autoplay]: "false",
        [HHDSVideoVimeoAttrNames.loop]: "false",
        [HHDSVideoVimeoAttrNames.controls]: "true",
        [HHDSVideoVimeoAttrNames.hidePlayButton]: "true",
      };
      const cover = this.vars.get<string>(Attrs.modalCover);
      if (cover) videoAttributes[HHDSVideoVimeoAttrNames.cover] = cover;
      let attributesString: string = "";
      Object.keys(videoAttributes).forEach(
        (key) => (attributesString += `${key}="${videoAttributes[key]}" `)
      );
      return attributesString;
    };
    const createCaptionHtml = (captionText: string): string => {
      if (captionText?.length > 0) {
        return `<hhds-caption>${captionText}</hhds-caption>`;
      }
      return "";
    };

    const captionText = this.vars.get<string>(Attrs.caption);
    const altText = this.vars.get<string>(Attrs.alt);
    const captionHtml: string = createCaptionHtml(captionText);
    const icon = this.vars.get<string>(Attrs.icon);
    let iconHtml: string = "";
    if (icon?.length) {
      iconHtml = `<div class="play-icon-container"><hhds-icon type="${icon}"></hhds-icon></div>`;
    }

    this.shadow.innerHTML = `
    <style>${globalStyles}</style>
    <style>${css}</style>
    <div class="${HHDSVideoPreviewTagName}">
      <hhds-image
        animate="true"
        src="${this.vars.get<string>(Attrs.imgSrc)}"
          ${this.getAttribute('srcSet') !== null ? `srcset="${this.getAttribute('srcSet')}` : `` }"
          ${this.getAttribute('sizes') !== null ? `sizes="${this.getAttribute('sizes')}` : `` }"
        alt="${altText}">${captionHtml}
      </hhds-image>
      ${iconHtml}
      <hhds-modal><hhds-video-vimeo id="in-modal" ${createVideoAttributes()}></hhds-video-vimeo></hhds-modal>
    </div>`;

    const modal = this.shadow.querySelector("hhds-modal") as HHDSModal;
    modal?.addEventListener(HHDSModalEvent.show, this.modalShowFunc);
    modal?.addEventListener(HHDSModalEvent.hide, this.modalHideFunc);

    this.enableClick = true;
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    const component = this.shadow.querySelector(`.${HHDSVideoPreviewTagName}`);
    component?.removeEventListener("click", this.clickFunc);
    const modal = this.shadow.querySelector("hhds-modal") as HHDSModal;
    modal?.removeEventListener(HHDSModalEvent.show, this.modalShowFunc);
    modal?.removeEventListener(HHDSModalEvent.hide, this.modalHideFunc);
  }

  private createEventCallbacks(): void {
    this.clickFunc = () => {
      const modal = this.shadow.querySelector("hhds-modal") as HHDSModal;
      if (modal && !modal.showing) {
        if (!modal.coordinator) {
          console.log("Creating coordinator");
          modal.createCoordinator();
        }
        this.updateModalVisibility(true, true);
      }
    };
    this.modalShowFunc = (_event: Event) => {
      this.enableClick = false;
    };
    this.modalHideFunc = (_event: Event) => {
      this.updateModalVisibility(false, false);
      window.setTimeout(() => {
        this.enableClick = true;
      }, 200);
    };
  }

  private set enableClick(enable: boolean) {
    if (enable && this.clickListenerAdded) return;
    if (!enable && !this.clickListenerAdded) return;
    const component = this.shadow.querySelector(`.${HHDSVideoPreviewTagName}`);
    if (enable) {
      component?.addEventListener("click", this.clickFunc);
      this.clickListenerAdded = true;
    } else {
      component?.removeEventListener("click", this.clickFunc);
      this.clickListenerAdded = false;
    }
  }

  updateModalVisibility(visible: boolean, applyToModal: boolean): void {
    const modal = this.shadow.querySelector("hhds-modal") as HHDSModal;
    DEBUG_VERBOSE && console.log("[VideoPreview] updateModalVisibility, visible=" + visible);
    if (!modal) return;
    if (applyToModal) {
      if (visible) {
        modal.show();
        modal.setVideoPlaying(true, false);
      } else {
        modal.hide();
        modal.setVideoPlaying(false);
      }
    }
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.imgSrc]: {
    description: "The URL of the image to display.",
    defaultValue: "",
    type: String,
  },
  [Attrs.alt]: {
    description: "The alt text for the image.",
    defaultValue: "",
    type: String,
  },
  [Attrs.caption]: {
    description: "The caption to display under the image.",
    defaultValue: "",
    type: String,
  },
  [Attrs.modalSrc]: {
    description: "URL to a Vimeo-hosted video that will open in a modal when the hero is activated.",
    defaultValue: "",
    type: String,
  },
  [Attrs.modalCover]: {
    description: "The URL of the cover image for the modal, if applicable.",
    defaultValue: "",
    type: String,
  },
  [Attrs.icon]: {
    description: "The icon to display over the image.",
    defaultValue: HHDSIconType.Play,
    type: String,
  },
};
