import css from './buttongroup.scss?inline';
import globalStyles from '../../index.scss?inline';	
import { ArgSpecDictionary, TypedVars } from "../component-utils";
import { HHDSButtonGroupOrientation, HHDSButtonGroupType } from './ButtonGroupTypes';

const DEBUG_VERBOSE: boolean  = false;
const CLASS_NAME: string = 'HHDSButtonGroup';
const TAG_NAME: string = 'hhds-buttongroup';
export const HHDSButtonGroupTagName: string = "hhds-buttongroup";

// ////////////////////////////////////////////////////////////////////

export class HHDSButtonGroup extends HTMLElement {

  	private vars: TypedVars = new TypedVars(this);
	private shadow: ShadowRoot;	

	private type: HHDSButtonGroupType = HHDSButtonGroupType.Default;	
	private orientation: HHDSButtonGroupOrientation = HHDSButtonGroupOrientation.Horizontal;	

	constructor() {
		super();
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'constructed');

		this.shadow = this.attachShadow({ mode: 'open' });
		if (!this.shadow) {
			return;
		}

	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// Define observed attributes
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.



	static get observedAttributes() {
		return Object.keys(ArgSpecs);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// Lifecycle Methods
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.

	connectedCallback() {

		this.parseAttributes();

		this.render();

		DEBUG_VERBOSE && console.log(`[${TAG_NAME}] Initialised`);
	}

  	parseAttributes() {
    	this.vars.parse(this, ArgSpecs);
	}

	render() {

		this.type = this.vars.get(<HHDSButtonGroupType>'type');
		this.orientation = this.vars.get(<HHDSButtonGroupOrientation>'orientation');

		this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="hhds-buttongroup ${this.type} ${this.orientation ? 'hhds-buttongroup_' + this.orientation : ''} "><slot></slot></div>
		`;
	}

	// Invoked each time the custom element is disconnected from the document's DOM.
	disconnectedCallback() {}

	// Invoked each time the custom element is moved to a new document.
	adoptedCallback() {}

	// Invoked each time one of the custom element's attributes is added, removed, or changed.
	attributeChangedCallback(name: string, oldValue: string, newValue: string) {
		DEBUG_VERBOSE && console.log(`Attribute ${name} has changed from ${oldValue} to ${newValue}.`);
		this.parseAttributes();
		this.render();
	}

}

export const ArgSpecs: ArgSpecDictionary = {
	"type": {
		description: "Type of button group",
		defaultValue: HHDSButtonGroupType.Default,
		type: HHDSButtonGroupType
	},
	"orientation": {
		description: "How does the button group display",
		defaultValue: HHDSButtonGroupOrientation.Horizontal,
		type: HHDSButtonGroupOrientation
	},

};

