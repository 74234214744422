import { HHDSHero, HHDSHeroTagName } from "../components/Hero/Hero";
import { HHDSModal, HHDSModalEvent } from "../components/Modal/Modal";
import { HHDSVideo, HHDSVideoTagName } from "../components/Video/Video";
import { HHDSVideoVimeo, HHDSVideoVimeoTagName } from "../components/VideoVimeo/VideoVimeo";
import { FocusHandler } from "./FocusHandler";

const VERBOSE_LOGGING: boolean = true;

export class ModalCoordinator {
  private modal: HHDSModal | null = null;
  private showFunc: any;
  private hideFunc: any;
  private scope: HTMLElement | Document;
  private modifiedElements: HTMLElement[] = [];
  focus: FocusHandler;

  private firstFocusableElement!: HTMLElement;
  private lastFocusableElement!: HTMLElement;
  private trapFocusFunc: any;
  private focusableElements: HTMLElement[] = [];
  private preModalFocusedElement: HTMLElement | null = null;

  constructor(modal: HHDSModal, scope: HTMLElement | Document = document) {
    this.modal = modal;
    this.scope = scope;
    this.focus = new FocusHandler(modal);

    const noScrollClassName = "modal-coordinator-no-scroll";
    const noScrollCss = `.${noScrollClassName} { overflow: hidden; }`;
    let style = document.createElement("style");
    style.appendChild(document.createTextNode(noScrollCss));
    document.head.appendChild(style);

    this.showFunc = () => {
      this.updateComponents(true);
      document.body.classList.add(noScrollClassName);
      this.focus.updateFocusableElements(this.modal?.focusableElements);
      this.focus.trap();
      // also add the class to the document element to prevent scrolling on iOS
      document.documentElement.classList.add(noScrollClassName);
    };
    this.hideFunc = () => {
      this.updateComponents(false);
      this.focus.untrap();
      document.body.classList.remove(noScrollClassName);
      document.documentElement.classList.remove(noScrollClassName);
    };

    this.modal?.addEventListener(HHDSModalEvent.show, this.showFunc);
    this.modal?.addEventListener(HHDSModalEvent.hide, this.hideFunc);
  }

  setPreModalFocusedElement(element: HTMLElement | null) {
    this.preModalFocusedElement = element;
  }

  updateComponents(showing: Boolean): void {
    let elements: NodeListOf<HTMLElement>;

    VERBOSE_LOGGING && console.log("[ModalCoordinator] updateComponents() Modal showing:", showing);

    //
    // Pause/resume logic for:
    // HTML video
    //
    elements = this.scope.querySelectorAll("video");
    const html5Videos: HTMLVideoElement[] = Array.from(elements) as HTMLVideoElement[];
    html5Videos.forEach((video: HTMLVideoElement) => {
      if (showing) {
        if (!video.paused) {
          VERBOSE_LOGGING && console.log("[ModalCoordinator] Pausing video ", video);
          video.pause();
          this.modifiedElements.push(video);
        }
      } else if (this.modifiedElements.includes(video)) {
        VERBOSE_LOGGING && console.log("[ModalCoordinator] Resuming video ", video);
        video.play();
      }
    });

    //
    // Pause/resume logic for:
    // HHDSVideo
    //
    elements = this.scope.querySelectorAll(HHDSVideoTagName);
    const hhdsVideos: HHDSVideo[] = Array.from(elements) as HHDSVideo[];
    hhdsVideos.forEach((video: HHDSVideo) => {
      if (showing) {
        video.isPlaying().then((playing) => {
          if (playing) {
            VERBOSE_LOGGING && console.log("[ModalCoordinator] Pausing video ", video);
            video.pause();
            this.modifiedElements.push(video);
          }
        });
      } else if (this.modifiedElements.includes(video)) {
        VERBOSE_LOGGING && console.log("[ModalCoordinator] Resuming video ", video);
        video.play();
      }
    });

    //
    // Pause/resume logic for:
    // HHDSVideoVimeo
    //
    elements = this.scope.querySelectorAll(HHDSVideoVimeoTagName);
    const hhdsVimeoVideos: HHDSVideoVimeo[] = Array.from(elements) as HHDSVideoVimeo[];
    hhdsVimeoVideos.forEach((video: HHDSVideoVimeo) => {
      if (showing) {
        video.isPlaying().then((playing) => {
          if (playing) {
            VERBOSE_LOGGING && console.log("[ModalCoordinator] Pausing video ", video);
            video.pause(true, true);
            this.modifiedElements.push(video);
          }
        });
      } else if (this.modifiedElements.includes(video)) {
        VERBOSE_LOGGING && console.log("[ModalCoordinator] Resuming video ", video);
        video.play();
      }
    });

    //
    // Pause/resume logic for:
    // HHDSHero
    //
    elements = this.scope.querySelectorAll(HHDSHeroTagName);
    const hhdsHeroes: HHDSHero[] = Array.from(elements) as HHDSHero[];
    hhdsHeroes.forEach((hero: HHDSHero) => {
      if (showing) {
        hero.isPlaying().then((playing) => {
          if (playing) {
            VERBOSE_LOGGING && console.log("[ModalCoordinator] Pausing hero ", hero);
            hero.pause();
            this.modifiedElements.push(hero);
          }
        });
      } else if (this.modifiedElements.includes(hero)) {
        VERBOSE_LOGGING && console.log("[ModalCoordinator] Resuming hero ", hero);
        hero.play();
      }
    });

    // If hiding the modal, forget all modified elements
    if (!showing) {
      this.modifiedElements = [];
    }
  }

  dispose(): void {
    this.focus?.dispose();
    this.modal?.removeEventListener(HHDSModalEvent.show, this.showFunc);
    this.modal?.removeEventListener(HHDSModalEvent.hide, this.hideFunc);
    this.modifiedElements = [];
    this.modal = null;
  }

  handleTrapFocus(event: KeyboardEvent): void {
    const isTabPressed = event.key === "Tab";
    if (!isTabPressed) return;
    console.log("FOCUSABLE ELEMENT COUNT: ", this.focusableElements.length);
    if (this.focusableElements.length === 1) {
      event.preventDefault();
      return;
    }
    if (event.shiftKey) {
      if (document.activeElement === this.firstFocusableElement) {
        event.preventDefault();
        this.lastFocusableElement.focus();
      }
    } else {
      console.log("Active element: ", document.activeElement, this.lastFocusableElement);
      if (document.activeElement === this.lastFocusableElement) {
        event.preventDefault();
        this.firstFocusableElement.focus();
      }
    }
  }

  trapFocus(modal: HHDSModal) {
    /*const focusableElements = modal.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );*/
    this.focusableElements = [];
    if (modal.closeButton.buttonElement) {
      this.focusableElements.push(modal.closeButton.buttonElement);
    }
    this.firstFocusableElement = this.focusableElements[0] as HTMLElement;
    this.lastFocusableElement = this.focusableElements[this.focusableElements.length - 1] as HTMLElement;
    if (this.firstFocusableElement) {
      this.firstFocusableElement.focus();
    }
    document.addEventListener("keydown", this.trapFocusFunc);
  }

  untrapFocus() {
    this.focusableElements = [];
    document.removeEventListener("keydown", this.trapFocusFunc);
    if (this.preModalFocusedElement) {
      this.preModalFocusedElement.focus();
    }
    this.preModalFocusedElement = null;
  }
}
