import css from "./button.scss?inline";
import globalStyles from "../../index.scss?inline";
import { ArgSpecDictionary, TypedVars } from "../component-utils";

import { HHDSButtonType } from "./ButtonType";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSButton";
const TAG_NAME: string = "hhds-button";
export const HHDSButtonTagName: string = "hhds-button";

// ////////////////////////////////////////////////////////////////////

export class HHDSButton extends HTMLElement {
  private vars: TypedVars = new TypedVars(this);
  private shadow: ShadowRoot;

  private listenerFuncs: { [key: string]: any } = {};

  constructor() {
    super();
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");

    this.shadow = this.attachShadow({ mode: "open" });
    if (!this.shadow) {
      return;
    }
  }

  static get observedAttributes() {
    return Object.keys(ArgSpecs);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Lifecycle Methods
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

  // Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {
    this.parseAttributes();
    this.render();
    this.addButtonEvents();
    DEBUG_VERBOSE && console.log(`[${TAG_NAME}] Initialised`);
  }

  private addButtonEvents(): void {
    const buttonEl = this.shadow.querySelector("button");
    if (!buttonEl) return;

    // Define callbacks for events separately rather than inline
    // in addEventListener, so they can be removed later.
    this.listenerFuncs["click"] = (event: PointerEvent) => {
      event.preventDefault();
      const href = this.vars.get<string>("href");
      const target = this.vars.get<string>("target");
      const download = this.getAttribute("download");

      if (download && download.length > 0) {
        fetch(download, { method: "get", mode: "no-cors", referrerPolicy: "no-referrer" })
          .then((res) => res.blob())
          .then((res) => {
            const aElement = document.createElement("a");
            aElement.setAttribute("download", "file");
            const href = URL.createObjectURL(res);
            aElement.href = href;
            aElement.setAttribute("target", "_blank");
            aElement.click();
            URL.revokeObjectURL(href);
          });
      }

      if (href?.length > 0) {
        switch (target) {
          case "blank":
          case "_blank":
            window.open(href, "_blank");
            break;
          case "self":
          case "_self":
          default:
            window.open(href, "_self");
        }
      }

      this.emitEvent(HHDSButtonEvent.click);
    };
    this.listenerFuncs["pointerdown"] = (event: Event) => {
      //event.stopPropagation();
      event.preventDefault();
      this.emitEvent(HHDSButtonEvent.down);
    };
    this.listenerFuncs["pointerup"] = (event: Event) => {
      event.preventDefault();
      this.emitEvent(HHDSButtonEvent.up);
    };
    this.listenerFuncs["pointerenter"] = (event: Event) => {
      event.preventDefault();
      this.emitEvent(HHDSButtonEvent.over);
    };
    this.listenerFuncs["pointerleave"] = (event: Event) => {
      event.preventDefault();
      this.emitEvent(HHDSButtonEvent.out);
    };
    this.listenerFuncs["focus"] = (event: Event) => {
      event.preventDefault();
      this.emitEvent(HHDSButtonEvent.focus);
    };
    this.listenerFuncs["blur"] = (event: Event) => {
      event.preventDefault();
      this.emitEvent(HHDSButtonEvent.unfocus);
    };

    // Add all event listeners, pointing to their respective callback functions.
    // This approach allows the events to be cleanly removed later.
    const events = Object.keys(this.listenerFuncs);
    events.forEach((eventName) => buttonEl?.addEventListener(eventName, this.listenerFuncs[eventName]));

    // Alternative, simpler layout:
    // buttonEl?.addEventListener("click", this.listenerFuncs["click"]);
    // buttonEl?.addEventListener("pointerdown", this.listenerFuncs["pointerdown"]);
    // buttonEl?.addEventListener("pointerup", this.listenerFuncs["pointerup"]);
    // buttonEl?.addEventListener("pointerenter", this.listenerFuncs["pointerenter"]);
    // buttonEl?.addEventListener("pointerleave", this.listenerFuncs["pointerleave"]);
    // buttonEl?.addEventListener("focus", this.listenerFuncs["focus"]);
  }

  private removeButtonEvents(): void {
    const buttonEl = this.shadow.querySelector("button");
    if (!buttonEl) return;
    const events = Object.keys(this.listenerFuncs);
    events.forEach((eventName) => buttonEl?.removeEventListener(eventName, this.listenerFuncs[eventName]));
  }

  get buttonElement(): HTMLButtonElement | null {
    return this.shadow.querySelector("button");
  }

  parseAttributes() {
    this.vars.parse(this, ArgSpecs);
  }

  render() {
    const type = this.vars.get<HHDSButtonType>("type"),
      label = this.vars.get<string>("label"),
      disabled = this.vars.get<boolean>("disabled"),
      role = this.vars.get<string>("role");

    this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<button
                class="hhds-button hhds-button--${type} ${disabled && `hhds-button--${type}--disabled`} ui"
                aria-label="${label}"
                ${role && `aria-role="${role}"`}
            >
				<slot name="start"></slot>
				${label}<slot></slot>
				<slot name="end"></slot>
			</button>
		`;
  }

  // Invoked each time the custom element is disconnected from the document's DOM.
  disconnectedCallback() {
    this.removeButtonEvents();
  }

  // Invoked each time the custom element is moved to a new document.
  adoptedCallback() {}

  // Invoked each time one of the custom element's attributes is added, removed, or changed.
  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    DEBUG_VERBOSE && console.log(`Attribute ${name} has changed from ${oldValue} to ${newValue}.`);
    this.removeButtonEvents();
    this.parseAttributes();
    this.render();
    this.addButtonEvents();
  }

  private emitEvent(eventName: string, data: any = null) {
    const event = new CustomEvent(eventName, { detail: data });
    this.dispatchEvent(event);
  }
}

export enum HHDSButtonEvent {
  click = "hhds-button-click",
  over = "hhds-button-over",
  out = "hhds-button-out",
  down = "hhds-button-down",
  up = "hhds-button-up",
  focus = "hhds-button-focus",
  unfocus = "hhds-button-unfocus",
}

export const ArgSpecs: ArgSpecDictionary = {
  href: {
    description: "Navigate to this URL on click",
    defaultValue: "",
    type: String,
  },
  target: {
    description: "Target for the link",
    defaultValue: "_blank",
    type: String,
  },
  type: {
    description: `Type of button: "primary", "secondary", "overlay".`,
    defaultValue: HHDSButtonType.Primary,
    type: HHDSButtonType,
  },
  label: {
    description: "Text label for the button, can be multi-line.",
    defaultValue: "",
    type: String,
  },
  role: {
    description: "Role of the button",
    defaultValue: "",
    type: String,
  },
  disabled: {
    description: "Disabled state of button",
    defaultValue: "false",
    type: Boolean,
  },
};
