import { HHDSCheckboxGroupState } from "./CheckboxGroupState";
import css from "./checkboxgroup.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSCheckboxButton } from "../CheckboxButton/CheckboxButton";
import { HHDSCheckboxGroupBehaviour } from "./CheckboxGroupBehaviour";
import { HHDSCheckboxButtonEvents } from "../CheckboxButton/CheckboxButtonEvents";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSCheckboxGroup";
export const HHDSCheckboxGroupTagName: string = "hhds-checkbox-group";
const TAG_NAME: string = HHDSCheckboxGroupTagName;

export const HHDSCheckboxGroupAttrNames = {
  ID: "id",
  Required: "required",
  Name: "name",
  Disabled: "disabled",
  Label: "label",
  State: "state",
  ErrorMessage: "error_message",
  Behaviour: "behaviour",
};

const Attrs = HHDSCheckboxGroupAttrNames;

export class HHDSCheckboxGroup extends Component {
  private slotEl!: HTMLSlotElement;

  private checkboxes!: HHDSCheckboxButton[];

  constructor() {
    super();
    // The base class's constructor handles attachmennt of a shadow root and
    // adopted global styles. Access the shadow root via this.shadow.
    //
    // Use the constructor only for anything that will never need to be destroyed as part of the
    // component's update lifecycle. init() and destroy() are called for connectedCallback and
    // disconnectedCallback, and a destroy() init() pair is called if reinit() is utilised.
  }

  protected override init(): void {
    // The base class responds to connectedCallback() by collecting attributes into
    // this.vars, then calling init(). A call to super.init() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    const attrId = this.vars.get<number>(Attrs.ID);
    const attrLabel = this.vars.get<number>(Attrs.Label);
    const attrName = this.vars.get<number>(Attrs.Name);
    const attrDisabled = this.vars.get<number>(Attrs.Disabled);
    const attrRequired = this.vars.get<boolean>(Attrs.Required);
    const attrState = this.vars.get<HHDSCheckboxGroupState>(Attrs.State);
    const attrErrorMessage = this.vars.get<boolean>(Attrs.ErrorMessage);
    const attrBehaviour = this.vars.get<boolean>(Attrs.Behaviour);

    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, "init", {
        attrId,
        attrLabel,
        attrName,
        attrDisabled,
        attrRequired,
        attrErrorMessage,
      });

    // For some components, re-assigning innerHTML may be appropriate on attribute and slot changes,
    // and without the need for a reinit(). In other cases, assigning innerHTML explicitly as part of
    // the init() routine is more appropriate.
    this.shadow.innerHTML = `
        <style>${globalStyles}</style>
        <style>${css}</style>
        <fieldset class="${TAG_NAME}" name="${attrName}" disbled="${attrDisabled}" ${attrId && `aria-describedby="${Component.getAriaDescriptionAttributeValueFromId(attrId)}"`}>
            <div class="${TAG_NAME}__text">
                ${this.renderLabel(attrLabel)}
                ${this.renderErrorMessage(attrId, attrErrorMessage, attrState)}
            </div>
            <div class="${TAG_NAME}__checkboxes">
                <slot></slot>
            </div>
        </fieldset>
		`;

    this.slotEl = this.shadow.querySelector("slot") as HTMLSlotElement;

    if (!this.slotEl)
      throw new Error(`${CLASS_NAME} - Unable to derive slot element!`);

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    this.checkboxes = this.slotEl.assignedElements() as HHDSCheckboxButton[];

    if (attrBehaviour === HHDSCheckboxGroupBehaviour.Radio) {
      this.checkboxes.forEach((checkbox) => {
        checkbox.addEventListener(
          HHDSCheckboxButtonEvents.Changed,
          this.handleCheckboxChangedEvent.bind(this)
        );
      });
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    // If the component uses slots, use observeSlotChanges().
    this.observeSlotChanges(true);
  }

  private handleCheckboxChangedEvent(event: any) {
    const { target, checked } = event.detail;

    DEBUG_VERBOSE &&
      console.log(
        CLASS_NAME,
        this.handleCheckboxChangedEvent.name,
        { event },
        target
      );

    this.checkboxes.forEach((checkbox) => {
      if (checkbox !== target && checked) {
        checkbox.resetCheckedState();
      }
    });
  }

  private renderErrorMessage(
    id: string,
    messageStr: string,
    state: HHDSCheckboxGroupState
  ) {
    if (messageStr.length === 0) return "";

    return `<p
                class="${TAG_NAME}__error-message label"
                aria-hidden="true"
                ${state === HHDSCheckboxGroupState.Error ? 'style="display: block;"' : 'style="display: none;"'}
                ${id && `id="${Component.getAriaDescriptionAttributeValueFromId(id)}"`}
            >${messageStr}</p>`;
  }

  private renderLabel(labelStr: string) {
    return labelStr.length > 0
      ? `<legend class="${TAG_NAME}__label label">${labelStr}</legend>`
      : "";
  }

  public isValid() {
    const attrRequired = this.vars.get<boolean>(Attrs.Required);

    let isValidState;

    if (attrRequired === false) {
      isValidState = true;
    } else {
      const assignedElements =
        this.slotEl.assignedElements() as HHDSCheckboxButton[];

      const hasAtLeastOneCheckedValue = assignedElements.some((obj) => {
        return obj.checked;
      });

      DEBUG_VERBOSE &&
        console.log(CLASS_NAME, this.isValid.name, {
          hasAtLeastOneCheckedValue,
          attrRequired,
        });

      isValidState = hasAtLeastOneCheckedValue;
    }

    const nextAttrStateValue = isValidState
      ? HHDSCheckboxGroupState.Default
      : HHDSCheckboxGroupState.Error;

    this.setAttribute(Attrs.State, nextAttrStateValue);

    return isValidState;
  }

  protected override destroy(): void {
    // The base class responds to disconnectedCallback() by collecting attributes into
    // this.vars, then calling destroy(). A call to super.destroy() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    // If the component uses slots, stop observing slot changes.
    this.observeSlotChanges(false);
  }

  override onAttributeChanged(
    name: string,
    _oldValue: string,
    newValue: string
  ): void {
    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    // Either call reinit() to have the component's destroy and init methods each be called,
    // or skip this step and handle update of the attribute directly. 'this.vars' will already
    // have been updated by the base Component class, so it can be immediately used to access
    // the new value.
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    // The base Component class must have access to this superclass's ArgSpecs.
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.ID]: {
    description: "The id associated with the group.",
    defaultValue: "",
    type: String,
  },
  [Attrs.Required]: {
    description: "Whether at least one selection is required",
    defaultValue: false,
    type: Boolean,
  },
  [Attrs.Label]: {
    description: "The label associated with the group.",
    defaultValue: "",
    type: String,
  },
  [Attrs.Name]: {
    description: "The name associated with the group.",
    defaultValue: "",
    type: String,
  },
  [Attrs.Disabled]: {
    description: "The state of all form controls that are descendants.",
    defaultValue: false,
    type: Boolean,
  },
  [Attrs.State]: {
    description: "The state of all form controls that are descendants.",
    defaultValue: HHDSCheckboxGroupState.Default,
    type: HHDSCheckboxGroupState,
  },
  [Attrs.Behaviour]: {
    description:
      "Whether the group should act as a group of checkboxes (default) or as radio buttons",
    defaultValue: HHDSCheckboxGroupBehaviour.Default,
    type: HHDSCheckboxGroupState,
  },
  [Attrs.ErrorMessage]: {
    description: "The message displayed when there is an error",
    defaultValue: "Error",
    type: String,
  },
};
