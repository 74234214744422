import css from "./videoyoutube.scss?inline";
import globalStyles from "../../index.scss?inline";
import YouTubePlayer from "youtube-player";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSVideoEvent } from "../../utils/VideoTypes";
import PlayerStates from "youtube-player/dist/constants/PlayerStates";
import { Options } from "youtube-player/dist/types";
import { Component } from "../../utils/Component";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSVideoYoutube";
export const HHDSVideoYoutubeTagName: string = "hhds-video-youtube";
const TAG_NAME: string = HHDSVideoYoutubeTagName;

export const HHDSVideoYoutubeAttrNames = {
  src: "src",
  cover: "cover",
  controls: "controls",
  autoplay: "autoplay",
  loop: "loop",
  aspect: "aspect",
  loopPoint: "loop-point",
  startPoint: "start-point",
};

const Attrs = HHDSVideoYoutubeAttrNames;

export class HHDSVideoYoutube extends Component {
  private listenerFuncs: { [key: string]: EventListener } = {};
  private player: any = null;
  private loopPoint: number = 0;
  private startPoint: number = 0;
  private _playing: boolean = false;
  private _timeUpdateInterval: number | null = null;
  private _awaitingTimeResponse: boolean = false;
  private coverElement: HTMLElement | null = null;

  constructor() {
    super();
    this.listenerFuncs["click"] = () => this.play();
    this.listenerFuncs["stateChange"] = (event: any) => this.onPlayerStateChange(event);
  }

  protected override init(): void {
    const src = this.vars.get<string>(Attrs.src);
    if (!src) return;

    this.loopPoint = this.vars.get<number>(Attrs.loopPoint);
    this.startPoint = this.vars.get<number>(Attrs.startPoint);

    const aspect = this.vars.get<string>(Attrs.aspect);

    let buttonHtml: string;
    if (this.vars.get<boolean>(Attrs.autoplay)) {
      buttonHtml = "";
    } else {
      buttonHtml = `<button class="${TAG_NAME}__button">PLAY</button>`;
    }

    this.shadow.innerHTML = `
      <style>${globalStyles}</style>
      <style>${css}</style>
      <div class="${TAG_NAME}" style="--video-youtube-aspect-ratio: ${aspect};">
        <div class="${TAG_NAME}__container" id="youtube-container"></div>
        <slot></slot>
        <div class="${TAG_NAME}__cover">
          ${buttonHtml}
        </div>
      </div>
    `;

    this.coverElement = this.shadow.querySelector(`.${TAG_NAME}__cover`) as HTMLElement;
    const coverImageUrlString = this.vars.get<string>(Attrs.cover);
    if (coverImageUrlString) {
      this.coverElement.style.backgroundImage = `url('${coverImageUrlString}')`;
    } else {
      this.coverElement.remove();
    }

    const videoId = this.extractVideoId(src);
    let container = this.shadow.querySelector(`.${TAG_NAME}__container`) as HTMLElement;
    const allowControls = this.vars.get<boolean>(Attrs.controls);
    container.classList.toggle("no-interaction", !allowControls);
    let options: Options = {
      videoId: videoId,
      playerVars: {
        modestbranding: 1, // Remove YouTube logo
        rel: 0, // Disable related videos
        fs: 0, // Disable fullscreen
        autoplay: this.vars.get<boolean>(Attrs.autoplay) ? 1 : 0,
        controls: allowControls ? 1 : 0,
        playsinline: 1,
      },
    };
    let player;
    player = YouTubePlayer(container, options);
    this.player = player;
    this.addListeners();
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    this.player?.stopVideo();
    this.toggleTimeUpdateChecking(false);
    this.removeListeners();
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }

  get playerState(): Promise<PlayerStates> {
    return this.player.getPlayerState();
  }

  extractVideoId(url: string): string {
    const urlObj = new URL(url);
    return urlObj.searchParams.get("v") || urlObj.pathname.split("/").pop() || "";
  }

  addListeners(): void {
    const button = this.shadow.querySelector(`.${TAG_NAME}__button`) as HTMLElement;
    button?.addEventListener("click", this.listenerFuncs["click"]);
    this.player.on("stateChange", this.listenerFuncs["stateChange"]);
  }

  removeListeners(): void {
    const button = this.shadow.querySelector(`.${TAG_NAME}__button`) as HTMLElement;
    button?.removeEventListener("click", this.listenerFuncs["click"]);
    //this.player.off("stateChange", this.listenerFuncs["stateChange"]);
  }

  play() {
    if (this.player) {
      this.player.playVideo();
    }
  }

  pause() {
    if (this.player) {
      this.player.pauseVideo();
    }
  }

  onPlayerStateChange(event: any) {
    const state: PlayerStates = event.data;

    DEBUG_VERBOSE && console.log(`[VideoYoutube] ${this.stateDescription(state)}`);
    switch (state) {
      case PlayerStates.PLAYING:
        this.onVideoPlay();
        break;
      case PlayerStates.PAUSED:
        this.onVideoPaused();
        break;
      case PlayerStates.ENDED:
        this.onVideoEnded();
        break;
      case PlayerStates.BUFFERING:
        break;
      case PlayerStates.UNSTARTED:
        break;
      case PlayerStates.VIDEO_CUED:
        break;
    }

    if (this.loopPoint > 0 && this.player.getCurrentTime() >= this.loopPoint) {
      this.loopPlayback();
    }
  }

  stateDescription(state: PlayerStates): string {
    switch (state) {
      case PlayerStates.PLAYING:
        return "Playing";
      case PlayerStates.PAUSED:
        return "Paused";
      case PlayerStates.ENDED:
        return "Ended";
      case PlayerStates.BUFFERING:
        return "Buffering";
      case PlayerStates.UNSTARTED:
        return "Unstarted";
      case PlayerStates.VIDEO_CUED:
        return "Cued";
      default:
        return "Unknown";
    }
  }

  onVideoPaused() {
    this._playing = false;
    this.toggleTimeUpdateChecking(false);
    this.emitEvent(HHDSVideoEvent.pause);
  }

  onVideoPlay() {
    this.toggleCover(false);
    if (!this._playing) {
      this.emitEvent(HHDSVideoEvent.play);
      this._playing = true;
      this.toggleTimeUpdateChecking(true);
    }
  }

  private toggleTimeUpdateChecking(enabled: boolean): void {
    if (enabled) {
      if (!this._timeUpdateInterval) {
        this._timeUpdateInterval = window.setInterval(() => {
          this.checkTime();
        }, 1000 / 30);
      }
    } else {
      if (this._timeUpdateInterval) window.clearInterval(this._timeUpdateInterval);
      this._timeUpdateInterval = null;
    }
  }

  checkTime(): void {
    if (!this._awaitingTimeResponse) {
      this._awaitingTimeResponse = true;
      this.player.getCurrentTime().then((currentTime: number) => {
        //console.log("Time: ", currentTime);
        this._awaitingTimeResponse = false;
        this.emitEvent(HHDSVideoEvent.timeUpdate);
        if (this.loopPoint > 0 && currentTime >= this.loopPoint) {
          this.loopPlayback();
        }
      });
    }
  }

  onVideoEnded() {
    if (!this.vars.get<boolean>(Attrs.controls) || this.vars.get<boolean>(Attrs.loop)) {
      this.loopPlayback();
    } else {
      this._playing = false;
      this.toggleTimeUpdateChecking(false);
      this.emitEvent(HHDSVideoEvent.ended);
      this.toggleCover(true);
    }
  }

  loopPlayback() {
    DEBUG_VERBOSE && console.log(`Should loop at ${this.loopPoint} to ${this.startPoint}`);
    if (this.player) {
      this.player.seekTo(this.startPoint);
      this.play();
    }
  }

  toggleCover(visible: boolean) {
    if (this.coverElement) this.coverElement.style.display = visible ? "flex" : "none";
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.src]: {
    description: "The YouTube video URL.",
    defaultValue: "",
    type: String,
  },
  [Attrs.cover]: {
    description: "The URL of the cover image.",
    defaultValue: "/video-cover.jpg",
    type: String,
  },
  [Attrs.controls]: {
    description: "Whether to show the video controls.",
    defaultValue: true,
    type: Boolean,
  },
  [Attrs.autoplay]: {
    description: "Whether to autoplay the video.",
    defaultValue: false,
    type: Boolean,
  },
  [Attrs.loop]: {
    description: "Whether to loop the video.",
    defaultValue: false,
    type: Boolean,
  },
  [Attrs.aspect]: {
    description: "The aspect ratio of the video.",
    defaultValue: "16/9",
    type: String,
  },
  [Attrs.loopPoint]: {
    description: "A custom time (instead of the end of the video) at which to loop the video.",
    defaultValue: 0,
    type: Number,
  },
  [Attrs.startPoint]: {
    description: "The time to which the video should be rewinded after looping.",
    defaultValue: 0,
    type: Number,
  },
};
