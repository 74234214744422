import css from "./layeredimage.scss?inline";
import globalStyles from "../../index.scss?inline";
import { ArgSpecDictionary, TypedVars } from "../component-utils";
import { HHDSImage, HHDSImageTagName } from "../Image/Image";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSLayeredImage";
const TAG_NAME: string = "hhds-layeredimage";
export const HHDSLayeredImageTagName: string = "hhds-layeredimage";

// ////////////////////////////////////////////////////////////////////

export class HHDSLayeredImage extends HTMLElement {
  private vars: TypedVars = new TypedVars(this);
  private shadow: ShadowRoot;

  constructor() {
    super();
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");

    this.shadow = this.attachShadow({ mode: "open" });
    if (!this.shadow) {
      return;
    }

  }

  get image(): HHDSImage | null {
    return this.getSlotted<HHDSImage>(HHDSImageTagName);
  }

  getSlotted<T extends HTMLElement>(selector: string, slotName?: string): T | null {
    let slot: HTMLSlotElement | null = null;
    if (slotName) {
      slot = this.shadow.querySelector(`slot[name="${slotName}"]`) as HTMLSlotElement;
    } else {
      slot = this.shadow.querySelector("slot") as HTMLSlotElement;
    }
    if (!slot) return null;
    const assignedElements = slot.assignedElements();
    return assignedElements.find((el) => el.matches(selector)) as T;
  }

  static get observedAttributes() {
    return Object.keys(ArgSpecs);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Lifecycle Methods
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

  // Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {
    this.parseAttributes();

    this.render();

    DEBUG_VERBOSE && console.log(`[${TAG_NAME}] Initialised`);
  }

  parseAttributes() {
    this.vars.parse(this, ArgSpecs);
  }

  render() {
    this.shadow.innerHTML = `
      <style>${globalStyles}</style>
			<style>${css}</style>
			<div class="hhds-layeredimage">
				<div class="hhds-layeredimage__image">
					<slot name="image"></slot>
				</div>
			</div>
		`;
  }

  // Invoked each time the custom element is disconnected from the document's DOM.
  disconnectedCallback() {}

  // Invoked each time the custom element is moved to a new document.
  adoptedCallback() {}

  // Invoked each time one of the custom element's attributes is added, removed, or changed.
  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    DEBUG_VERBOSE && console.log(`Attribute ${name} has changed from ${oldValue} to ${newValue}.`);
    this.parseAttributes();
    this.render();
  }
}

export const ArgSpecs: ArgSpecDictionary = {};
