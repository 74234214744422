import css from "./modal.scss?inline";
import globalStyles from "../../index.scss?inline";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSButton, HHDSButtonEvent } from "../Button/Button";
import { ModalCoordinator } from "../../utils/ModalCoordinator";
import { HHDSVideo } from "../Video/Video";
import { HHDSVideoVimeo, HHDSVideoVimeoAttrNames } from "../VideoVimeo/VideoVimeo";
import { HHDSVideoYoutube } from "../VideoYoutube/VideoYoutube";
import { HHDSVideoDirect } from "../VideoDirect/VideoDirect";
import { Component } from "../../utils/Component";
import { HHDSImage, HHDSImageTagName } from "../Image/Image";
import { HHDSImageLite, HHDSImageLiteTagName } from "../ImageLite/ImageLite";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSModal";
const TAG_NAME: string = "hhds-modal";
export const HHDSModalTagName: string = "hhds-modal";

export const HHDSModalAttrNames = {
  escapeKey: "escape-key",
  clickClose: "click-close",
  closeButton: "close-button",
  startHidden: "start-hidden",
};

export enum HHDSModalEvent {
  show = "show",
  hide = "hide",
}

const Attrs = HHDSModalAttrNames;

export class HHDSModal extends Component {
  private container!: HTMLElement;
  closeButton!: HHDSButton;
  private keyDownFunc: any;
  private clickContainerFunc: any;
  private buttonClickFunc: any;
  coordinator: ModalCoordinator | null = null;

  constructor() {
    super();
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");

    this.keyDownFunc = this.keyDownFunc = (event: KeyboardEvent) => {
      if (event.key === "Escape") this.hide();
    };
    this.clickContainerFunc = (event: Event) => {
      if (event.target === this.container || event.target == this) this.hide();
    };
    this.buttonClickFunc = (_event: Event) => this.hide();
  }

  private get usingVideo(): boolean {
    if (this.getSlotted<HHDSVideo>("hhds-video") != null) return true;
    if (this.getSlotted<HHDSVideoVimeo>("hhds-video-vimeo") != null) return true;
    if (this.getSlotted<HHDSVideoYoutube>("hhds-video-youtube") != null) return true;
    if (this.getSlotted<HHDSVideoDirect>("hhds-video-direct") != null) return true;
    return false;
  }

  get focusableElements(): HTMLElement[] {
    let elements: HTMLElement[] = [];
    let imageLite: HHDSImageLite | null = this.getSlotted<HHDSImageLite>(HHDSImageLiteTagName);
    if (imageLite) {
      const imgElement = imageLite.shadowRoot?.querySelector("img") as HTMLImageElement;
      console.log(imageLite);
      elements.push(imgElement);
    }
    let image: HHDSImage | null = this.getSlotted<HHDSImage>(HHDSImageTagName);
    if (image) {
      const imgElement = image.shadowRoot?.querySelector("img") as HTMLImageElement;
      console.log(imgElement);
      elements.push(imgElement);
    }
    if (this.closeButton.buttonElement) elements.push(this.closeButton.buttonElement);
    return elements;
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");
    const showCloseButton = this.vars.has(Attrs.closeButton) && this.vars.get<boolean>(Attrs.closeButton);
    const closeIcon = `<hhds-icon type="cross" slot="start" style="--icon-color: var(--color-neutral-white);"></hhds-icon>`;
    const closeButton = `<hhds-button type="round" role="">${closeIcon}</hhds-button>`;
    this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="${TAG_NAME}">
        <div class="backing"></div>
        <div class="main">
          <div class="container">
            <div class="content">
              <slot></slot>
              ${showCloseButton ? closeButton : ""}
            </div>
          </div>
        </div>
      </div>
		`;

    this.container = this.shadow.querySelector(`.${TAG_NAME}`) as HTMLElement;
    this.closeButton = this.shadow.querySelector("hhds-button") as HHDSButton;
    this.addListeners();
    const hidden = this.vars.get<boolean>(Attrs.startHidden);
    hidden ? this.hide() : this.show();

    this.observeSlotChanges(true);
  }

  show(): void {
    this.container = this.shadow.querySelector(`.${TAG_NAME}`) as HTMLElement;
    this.container.classList.toggle("hidden", false);
    // Emit the show event after an animation frame to allow the
    // modal to pick up slotted elements
    requestAnimationFrame(() => this.emitEvent(HHDSModalEvent.show));
  }

  hide(): void {
    this.container = this.shadow.querySelector(`.${TAG_NAME}`) as HTMLElement;
    this.container.classList.toggle("hidden", true);
    this.setVideoPlaying(false);
    this.emitEvent(HHDSModalEvent.hide);
  }

  get showing(): boolean {
    return !this.container.classList.contains("hidden");
  }

  createCoordinator(): ModalCoordinator {
    this.coordinator = new ModalCoordinator(this);
    return this.coordinator;
  }

  setVideoPlaying(playing: boolean, onlyIfAutoplay: boolean = false): void {
    DEBUG_VERBOSE &&
      console.log("[HHDSModal] setVideoPlaying=" + playing + ", onlyIfAutoplay=" + onlyIfAutoplay);
    const video = this.getSlotted<HHDSVideo>("hhds-video");
    if (video) {
      if (playing) {
        if (!onlyIfAutoplay || video.getAttribute("autoplay") === "true") {
          //console.log("[HHDSModal] will play slotted HHDSVideo");
          video.play();
        }
      } else {
        //console.log("[HHDSModal] will pause slotted HHDSVideo");
        video.pause();
      }
    }
    const videoVimeo = this.getSlotted<HHDSVideoVimeo>("hhds-video-vimeo");
    if (videoVimeo) {
      const recreatePlayer =
        videoVimeo.vars?.has(HHDSVideoVimeoAttrNames.recreatePlayer) &&
        videoVimeo.vars?.get<boolean>(HHDSVideoVimeoAttrNames.recreatePlayer);
      if (playing) {
        if (!onlyIfAutoplay || videoVimeo.getAttribute("autoplay") === "true") {
          //console.log("[HHDSModal] will play slotted HHDSVideoVimeo");
          if (videoVimeo.play) {
            if (recreatePlayer) videoVimeo.createPlayer();
            videoVimeo.play();
          }
        }
      } else {
        //console.log("[HHDSModal] will pause slotted HHDSVideoVimeo");
        if (videoVimeo.pause) {
          if (recreatePlayer) videoVimeo.destroyPlayer();
          videoVimeo.pause();
        }
      }
    }
    const youtubeVideo = this.getSlotted<HHDSVideoYoutube>("hhds-video-youtube");
    if (youtubeVideo) {
      if (playing) {
        if (!onlyIfAutoplay || youtubeVideo.getAttribute("autoplay") === "true") {
          //console.log("[HHDSModal] will play slotted HHDSVideoYoutube");
          youtubeVideo.play();
        }
      } else {
        //console.log("[HHDSModal] will pause slotted HHDSVideoYoutube");
        youtubeVideo.pause();
      }
    }
    const directVideo = this.getSlotted<HHDSVideoDirect>("hhds-video-direct");
    if (directVideo) {
      if (playing) {
        if (!onlyIfAutoplay || directVideo.getAttribute("autoplay") === "true") {
          //console.log("[HHDSModal] will play slotted HHDSVideoDirect");
          directVideo.play();
        }
      } else {
        //console.log("[HHDSModal] will pause slotted HHDSVideoDirect");
        directVideo.pause();
      }
    }
  }

  private addListeners(): void {
    if (this.vars.get<boolean>(Attrs.escapeKey)) {
      window.addEventListener("keydown", this.keyDownFunc);
    }
    if (this.vars.get<boolean>(Attrs.clickClose)) {
      this.addEventListener("click", this.clickContainerFunc);
    }
    if (this.vars.get<boolean>(Attrs.closeButton)) {
      this.closeButton?.addEventListener(HHDSButtonEvent.click, this.buttonClickFunc);
    }
  }

  private removeListeners(): void {
    if (this.vars.get<boolean>(Attrs.escapeKey)) {
      window.removeEventListener("keydown", this.keyDownFunc);
    }
    if (this.vars.get<boolean>(Attrs.clickClose)) {
      this.removeEventListener("click", this.clickContainerFunc);
    }
    if (this.vars.get<boolean>(Attrs.closeButton)) {
      this.closeButton?.removeEventListener(HHDSButtonEvent.click, this.buttonClickFunc);
    }
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    this.removeListeners();
    this.observeSlotChanges(false);
    this.coordinator?.dispose();
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }

  override onAttributeChanged(_name: string, _oldValue: string, _newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", _name, _oldValue, _newValue);
    this.reinit();
    this.vars.log();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      console.log(CLASS_NAME, "Slot emptied");
    } else {
      console.log(CLASS_NAME, "Slot changed");
    }
    const container = this.shadow.querySelector(`.container`) as HTMLElement;
    container?.classList.toggle("container--fullwidth", this.usingVideo);
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  "escape-key": {
    description: "Whether the escape key should close the modal.",
    type: Boolean,
    defaultValue: true,
  },
  "click-close": {
    description: "Whether clicking outside the modal's content should close the modal.",
    type: Boolean,
    defaultValue: true,
  },
  "close-button": {
    description: "Whether the modal should have a close button.",
    type: Boolean,
    defaultValue: true,
  },
  "start-hidden": {
    description: "Whether the modal should start hidden.",
    type: Boolean,
    defaultValue: true,
  },
};
