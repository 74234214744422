import css from './input.scss?inline';
import globalStyles from '../../index.scss?inline';
import { ArgSpecDictionary, TypedVars } from "../component-utils";

import { HHDSInputType } from "./InputType";
import { HHDSInputState } from "./InputState";
import { Component } from '../../utils/Component';

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = 'HHDSInput';
const TAG_NAME: string = 'hhds-input';
export const HHDSInputTagName: string = "hhds-input";

export const HHDSInputAttrNames = {
    ID: "id",
    Required: "required",
    ErrorMessage: "error_message",
    Name: "name",
    Label: "label",
    Placeholder: "placeholder",
    Value: "value",
    Type: "type",
    State: "state",
};

const Attrs = HHDSInputAttrNames;

// ////////////////////////////////////////////////////////////////////

export class HHDSInput extends HTMLElement {

    private vars: TypedVars = new TypedVars(this);
	private shadow: ShadowRoot;
	private inputElement!: HTMLInputElement;
	private value: string | undefined = '' ;

	constructor() {
		super();
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'constructed');

		this.shadow = this.attachShadow({ mode: 'open' });
		if (!this.shadow) {
			return;
		}

	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// Lifecycle Methods
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.
	connectedCallback() {

		this.parseAttributes();

		this.render();

        this.attachFormDataListener();

		DEBUG_VERBOSE && console.log(`[${TAG_NAME}] Initialised`);
	}

	parseAttributes() {
        this.vars.parse(this, ArgSpecs);

        DEBUG_VERBOSE && console.log(CLASS_NAME, this.parseAttributes.name, `this.vars`, this.vars);
	}

	render() {
		const attrId = this.vars.get<string>(Attrs.ID);
		const attrRequired = this.vars.get<boolean>(Attrs.Required);
		const attrErrorMessage = this.vars.get<string>(Attrs.ErrorMessage);
		const attrLabel = this.vars.get<string>(Attrs.Label);
		const attrPlaceholder = this.vars.get<string>(Attrs.Placeholder);
		const attrType = this.vars.get<HHDSInputType>(Attrs.Type);
		const attrState = this.vars.get<HHDSInputState>(Attrs.State);
		const attrName = this.vars.get<string>(Attrs.Name);

        let attrValue = this.vars.get<string>(Attrs.Value);

        if (this.value) {
            DEBUG_VERBOSE && console.log(CLASS_NAME, this.render.name, `Using fallback value of ${this.value}`);
            attrValue = this.value;
        }

        const hhdsInputClass = attrState === "error" ? "hhds-input--error" : "";
        const hhdsInputInputClass = attrLabel.length > 0 ? "hhds-input__input--label": "";

		this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="hhds-input ${hhdsInputClass}">
                ${this.renderInputElement(hhdsInputInputClass, attrId, attrType, attrName, attrPlaceholder, attrValue, attrRequired, attrLabel)}
				${this.renderLabel(attrName, attrLabel, attrRequired)}
				${this.renderError(attrId, attrState, attrErrorMessage)}
			</div>
		`;

        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        // We need to set this.inputElement after every render as the isvalid method can trigger a re-render

        this.inputElement = this.shadow.querySelector('input') as HTMLInputElement;

        if(!this.inputElement) throw new Error(`${CLASS_NAME} - Unable to derive input element!`);

        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        // Store the value any time it's updated. This value is also used when the component re-renders

		this.inputElement.addEventListener('input', () => {
			this.value = this.inputElement.value;
			DEBUG_VERBOSE && console.log(CLASS_NAME, this.connectedCallback.name, `[${TAG_NAME}] Value is now ` + this.value);
		});
	}

    renderInputElement(inputClass: string, id: string, type: string, name: string, placeHolder: string, value : string, required: boolean, label: string ){
        const classes = ['hhds-input__input', 'form-text'];

        if(inputClass) classes.push(inputClass);

        return `
        <input
            ${id && `id="${id}"`}
            class="${classes.join(' ')}"
            ${type && `type="${type}"`}
            ${name && `name="${name}"`}
            ${placeHolder && `placeholder="${placeHolder}"`}
            ${value && `value="${value}"`}
            ${name && `name="${name}"`}
            ${label && `aria-label="${label}"`}
            ${id && `aria-describedby="${Component.getAriaDescriptionAttributeValueFromId(id)}"`}
            ${required && "required" }
            >`;

    }

    public isValid(){
        DEBUG_VERBOSE && console.log(CLASS_NAME, this.isValid.name);
        DEBUG_VERBOSE && console.dir(this.inputElement );

        const attrRequired = this.vars.get<boolean>(Attrs.Required);

        let isValidState;

        if (attrRequired === false) {
            isValidState = true;
        } else {
            isValidState = this.isTypeValid();
        }

        const nextAttrStateValue = isValidState
        ? HHDSInputState.Default
        : HHDSInputState.Error;

        this.setAttribute(Attrs.State, nextAttrStateValue);

        return isValidState;
    }

    isTypeValid(){
        const attrType = this.vars.get<HHDSInputType>(Attrs.Type);

        const {value} = this.inputElement;

        DEBUG_VERBOSE && console.log(CLASS_NAME, this.isTypeValid.name, {attrType, value});

        switch (attrType) {
            case 'text':
                return value.length > 0 ? true : false;

            case 'email':
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailRegex.test(value);

            case 'tel':
                return /^[0-9]+$/.test(value);

            default:
                throw new Error(`Type: ${attrType} NOT handled!`);
        }
    }

    renderError(attrId: string, attrState : string, attrErrorMessage : string){
        return  `<span
            aria-hidden="true"
            ${attrId && `id="${Component.getAriaDescriptionAttributeValueFromId(attrId)}"`}
            style="display: ${attrState === "error" ? 'block' : 'none'};"
            class="hhds-input__required label">${attrErrorMessage}</span>`;
    }

    renderLabel(attrName : string, attrLabel: string, attrRequired: boolean){
        DEBUG_VERBOSE && console.log(CLASS_NAME, this.renderLabel.name, {attrName, attrLabel, attrRequired});

        return attrLabel.length > 0 ? `<label class="hhds-input__label label" aria-hidden="true" ${attrName && `for="${attrName}"`}>${attrLabel}</label>` : "";
    }

	attachFormDataListener() {
		const form = this.closest('form');
		if (form) {
		  form.addEventListener('formdata', (event) => {
			console.log("+++++++");
			console.log("Form data event. Appending: ");
			console.log(this.vars.get<string>("name"), this.inputElement ? this.inputElement.value : '');
			console.log("+++++++");
			event.formData.append(this.vars.get<string>("name"), this.inputElement ? this.inputElement.value : '');
		  });
		}
	  }

	// Invoked each time the custom element is disconnected from the document's DOM.
	disconnectedCallback() {}

	// Invoked each time the custom element is moved to a new document.
	adoptedCallback() {}

	// Invoked each time one of the custom element's attributes is added, removed, or changed.
	attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        DEBUG_VERBOSE && console.log(CLASS_NAME, this.attributeChangedCallback.name, {name, oldValue, newValue});

        this.parseAttributes();
		this.render();
    }

    static get observedAttributes() {
        return Object.keys(ArgSpecs);
    }
}

export const ArgSpecs: ArgSpecDictionary = {
    [Attrs.ID]: {
		description: "The id that is assigned to the input",
		defaultValue: '',
		type: String,
	},
	[Attrs.Required]: {
		description: "The requried attribute that is assigned to the input",
		defaultValue: false,
		type: Boolean,
	},
	[Attrs.ErrorMessage]: {
		description: "The error message to be displayed when input is invalid",
		defaultValue: 'Required',
		type: String,
	},
	[Attrs.Name]: {
		description: "The name that is assigned to the input",
		defaultValue: '',
		type: String,
	},
	[Attrs.Label]: {
		description: "The label that is assigned to the input",
		defaultValue: '',
		type: String,
	},
	[Attrs.Placeholder]: {
		description: "The placeholder that is assigned to the input",
		defaultValue: '',
		type: String,
	},
	[Attrs.Value]: {
		description: "The value that is assigned to the input",
		defaultValue: '',
		type: String,
	},
	[Attrs.Type]: {
		description: "The input type",
		defaultValue: HHDSInputType.Text,
		type: HHDSInputType,
	},
	[Attrs.State]: {
		description: "The input state",
		defaultValue: HHDSInputState.Default,
		type: HHDSInputState,
	},
};

