import css from "./contactlistitem.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSIconType } from "../Icon/IconType";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSContactListItem";
export const HHDSContactListItemTagName: string = "hhds-contact-list-item";
const TAG_NAME: string = HHDSContactListItemTagName;

export const HHDSContactListItemAttrNames = {
  name: "name",
  number: "number",
  url: "url",
  label: "label",
  target: "target",
  icon: "icon",
};

const Attrs = HHDSContactListItemAttrNames;

export class HHDSContactListItem extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    const name = this.vars.get<string>(Attrs.name);
    const number = this.vars.get<string>(Attrs.number);
    let linkHtml: string = "";
    if (number?.length) {
      linkHtml = `<hhds-link href="tel:${number}"><hhds-icon slot="start" type="${HHDSIconType.Phone}"></hhds-icon><span>${number}</span></hhds-link>`;
    } else {
      const url = this.vars.get<string>(Attrs.url);
      const label = this.vars.get<string>(Attrs.label);
      const target = this.vars.get<string>(Attrs.target);
      const icon = this.vars.get<string>(Attrs.icon);
      let iconHtml: string = '';
      if (icon?.length) iconHtml = `<hhds-icon slot="start" type="${icon}"></hhds-icon>`;
      linkHtml = `<hhds-link href="${url}" label="${label}" target="${target}">${iconHtml}<span>${label}</span></hhds-link>`;
    }

    this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="${TAG_NAME} grid grid--contact-list-item">
        <div class="col-span-6 sm:col-span-3 md:col-span-6"><h2 class="heading heading--sans-01 heading--no-margin">${name}</h2></div>
        <div class="col-span-6 sm:col-span-3 md:col-span-4"><hhds-richtext><address><slot></slot></address></hhds-richtext></div>
        <div class="col-span-6 sm:col-span-2">${linkHtml}</div>
      </div>
		`;
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.name]: {
    description: "Name of the contact",
    defaultValue: "",
    type: String,
  },
  [Attrs.number]: {
    description: "Phone number of the contact",
    defaultValue: "",
    type: String,
  },
  [Attrs.url]: {
    description: "Custom, non-phone number link",
    defaultValue: "",
    type: String,
  },
  [Attrs.label]: {
    description: "Custom link label",
    defaultValue: "",
    type: String,
  },
  [Attrs.target]: {
    description: "Target for the custom link",
    defaultValue: "",
    type: String,
  },
  [Attrs.icon]: {
    description: "Icon for the custom link",
    defaultValue: "",
    type: String,
  },
};
