const DEBUG_VERBOSE = false;
const CLASS_NAME = `PeopleModalCoordinator`;

export default class PeopleModalCoordinator {
  private index = 0;

  constructor(
    private owner: HTMLElement,
    private focusableElements: HTMLElement[]
  ) {
    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, this.owner, this.focusableElements);

    this.owner.focus();

    window.addEventListener("keydown", this.keydownListener);
  }

  // ////////////////////////////////////////////////////////////////////

  keydownListener = (event: KeyboardEvent) => {
    DEBUG_VERBOSE && console.log(CLASS_NAME, this.keydownListener.name);

    const { key } = event;

    if (key === "Tab") {
      event.preventDefault();
      this.focusableElements[this.index].focus();
      this.incrementIndex();
    }
  };

  // ////////////////////////////////////////////////////////////////////

  incrementIndex() {
    DEBUG_VERBOSE && console.log(CLASS_NAME, this.incrementIndex.name);

    if (this.index < this.focusableElements.length - 1) {
      this.index += 1;
    } else {
      this.index = 0;
    }
  }

  // ////////////////////////////////////////////////////////////////////

  kill() {
    DEBUG_VERBOSE && console.log(CLASS_NAME, this.kill.name);

    window.removeEventListener("keydown", this.keydownListener);

    this.focusableElements = [];
  }
}
