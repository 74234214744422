import { componentList } from "./components/index";

export namespace HHDS {
  export function register(verbose: boolean = false, note: string = ""): void {
    let targetNames: string[] = [];
    componentList.forEach((target: any) => {
      if (!customElements.get(target.name)) {
        targetNames.push(target.name);
        customElements.define(target.name, target.component);
      }
    });
    if (targetNames.length && verbose)
      console.log(`${note} registered components: ${targetNames.sort().join(", ")}`);
  }
}
