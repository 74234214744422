import css from "./accordionitem.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";

const DEBUG_VERBOSE: boolean = false;
export const HHDSAccordionItemTagName: string = "hhds-accordion-item";
const TAG_NAME: string = HHDSAccordionItemTagName;
const LOG_PREFIX: string = `[${TAG_NAME}]`;

export const HHDSModalAttrNames = {
  heading: "heading",
};

const Attrs = HHDSModalAttrNames;

export enum HHDSAccordionItemEvent {
  open = "open",
  close = "close",
}

export class HHDSAccordionItem extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(LOG_PREFIX, "init");
    this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="${TAG_NAME}">
        <div class="upper">
          <div class="left"><h3 class="link">${this.vars.get(Attrs.heading)}</h3></div>
          <hhds-icon class="right" type="plus"></hhds-icon>
        </div>
        <div class="content">
          <div class="spacer"></div>
          <slot></slot>
        </div>
      </div>
		`;

    const upper = this.shadow.querySelector(".upper") as HTMLElement;
    upper.addEventListener("click", () => this.setExpanded(!this.expanded, true, true));
  }

  get expanded(): boolean {
    const accordionItem = this.shadow.querySelector(`.${TAG_NAME}`) as HTMLElement;
    return accordionItem.classList.contains("expanded");
  }

  get heading(): string {
    return this.vars.get(Attrs.heading);
  }

  setExpanded(expanded: boolean, animated: boolean, userInvoked: boolean = false) {
    const accordionItem = this.shadow.querySelector(`.${TAG_NAME}`) as HTMLElement;
    const content = accordionItem.querySelector(".content") as HTMLElement;
    const icon = accordionItem.querySelector("hhds-icon") as HTMLElement;
    icon.classList.toggle("rotated", expanded);

    content.classList.toggle("animated", animated);
    icon.classList.toggle("animated", animated);

    if (expanded) {
      const height = `${content.scrollHeight}px`;
      content.style.maxHeight = height;
      if (!accordionItem.classList.contains("expanded")) {
        DEBUG_VERBOSE && console.log(LOG_PREFIX, "Will emit open event");
        this.emitEvent(HHDSAccordionItemEvent.open, { userInvoked });
        accordionItem.classList.add("expanded");
      }
    } else {
      content.style.maxHeight = content.scrollHeight + "px";
      /*setTimeout(() => {
        content.style.maxHeight = "0";
      }, 2);*/
      content.style.maxHeight = "0";
      if (accordionItem.classList.contains("expanded")) {
        accordionItem.classList.remove("expanded");
        this.emitEvent(HHDSAccordionItemEvent.close, { userInvoked });
      }
    }
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(LOG_PREFIX, "destroy");
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(LOG_PREFIX, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(LOG_PREFIX, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(LOG_PREFIX, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.heading]: {
    description: "The heading text.",
    type: String,
    defaultValue: "Heading",
  },
};
