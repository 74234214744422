import css from './icon.scss?inline';
import globalStyles from '../../index.scss?inline';	

import { ArgSpecDictionary, TypedVars } from "../component-utils";

import { HHDSIconType, HHDSIcons } from "./IconType";

const DEBUG_VERBOSE: boolean  = false;
const CLASS_NAME: string = 'HHDSIcon';
const TAG_NAME: string = 'hhds-icon';
export const HHDSIconTagName: string = "hhds-icon";

// ////////////////////////////////////////////////////////////////////

export class HHDSIcon extends HTMLElement {

  private vars: TypedVars = new TypedVars(this);
	private shadow: ShadowRoot;	

	constructor() {

		super();
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'constructed');

		this.shadow = this.attachShadow({ mode: 'open' });
		if (!this.shadow) {
			return;
		}

	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// Lifecycle Methods
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {

		this.parseAttributes();

		this.render();

		DEBUG_VERBOSE && console.log(`[${TAG_NAME}] Initialised`);
	}

	parseAttributes() {
    	this.vars.parse(this, ArgSpecs);
	}

	render() {

		const iconType = this.vars.get<string>("type") as HHDSIconType || HHDSIconType.None;
		const iconContent = HHDSIcons[iconType] ? HHDSIcons[iconType] : '';

		if (iconType !== HHDSIconType.None) {
			this.shadow.innerHTML = `
				<style>${globalStyles}</style>
				<style>${css}</style>
				<div class="hhds-icon">${iconContent}</div>
			`;
		}

	}

	// Invoked each time the custom element is disconnected from the document's DOM.
	disconnectedCallback() {}

	// Invoked each time the custom element is moved to a new document.
	adoptedCallback() {}

	// Invoked each time one of the custom element's attributes is added, removed, or changed.
	attributeChangedCallback() {}

}

export const ArgSpecs: ArgSpecDictionary = {
  "type": {
    description: "The type of icon to be displayed.",
    defaultValue: HHDSIconType.None,
    typeString: "HHDSIconType",
    type: HHDSIconType,
  },
};

