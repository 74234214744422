import css from "./text.scss?inline";
import globalStyles from "../../index.scss?inline";
import { ArgSpecDictionary } from "../component-utils";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSText";
export const HHDSTextTagName: string = "hhds-text";
//const TAG_NAME: string = HHDSTextTagName;

import { HHDSTextType } from "./TextType.ts";
import { Component } from "../../utils/Component.ts";

export const HHDSTextAttrNames = {
  type: "type",
};

const Attrs = HHDSTextAttrNames;

export class HHDSText extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");
    const type = this.vars.get<HHDSTextType>(Attrs.type);
    this.shadow.innerHTML = ` 
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="hhds-text hhds-text--${type.toLowerCase()}">
				<div class="container">
					<div class="grid grid--text">
						${
              type !== "F"
                ? `
							<div class="col-span-6 sm:col-span-8 md:col-span-12">
								<slot name="badge"></slot>
							</div>
						`
                : ""
            }
						<div class="
							${type == "A" || type == "G" || type == "H" ? `col-span-6 sm:col-span-4 md:col-span-6` : ""}
							${type == "B" ? `col-span-6 sm:col-span-7 md:col-span-9 lg:col-span-8` : ""}
							${type == "C" || type == "D" ? `col-span-6 sm:col-span-8 md:col-span-9` : ""}
							${type == "E" ? `col-span-6 sm:col-span-6 md:col-span-7 lg:col-span-8` : ""}
							${type == "F" || type == "I" ? `col-span-6 sm:col-span-5 md:col-span-12` : ""}
						">
							<slot name="heading"></slot>
						</div>

						${
              type == "A" || type == "G" || type == "H"
                ? `
							<div class="col-span-6 sm:col-start-5 md:col-start-9 sm:col-span-4">
								<slot name="text"></slot>
								<div class="hhds-text__link">
									<slot name="link"></slot>
								</div>
							</div>`
                : ""
            }
					</div>
				 
					${
            type == "G" || type == "H" || type == "B" || type == "E"
              ? `
						<div class="grid grid--text grid--text--spacing"> 
							<div class="col-span-6 sm:col-start-5 md:col-start-9 sm:col-span-4">
								<slot name="text"></slot>
								<div class="hhds-text__link">
									<slot name="link"></slot>
								</div>
							</div>
						</div>
					`
              : ""
          }

					${
            type == "C" || type == "D" || type == "F" || type == "I"
              ? `
						<slot name="repeater-text"></slot>
					`
              : ""
          }

					${
            type == "D" || type == "E"
              ? `
						<div class="hhds-text__stats">
							<slot name="stats"></slot>
						</div>
					`
              : ""
          }

				</div>
			</div>
		`;
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.type]: {
    description: "Layout variant",
    defaultValue: HHDSTextType.A,
    typeString: "HHDSTextType",
    type: HHDSTextType,
  },
};
