import css from "./link.scss?inline";
import globalStyles from "../../index.scss?inline";
import { ArgSpecDictionary } from "../component-utils";
import { Component } from "../../utils/Component";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "[HHDSLink]";
export const HHDSLinkTagName: string = "hhds-link";
//const TAG_NAME: string = HHDSLinkTagName;

export const HHDSLinkAttrNames = {
  href: "href",
  target: "target",
  content: "content",
};

const Attrs = HHDSLinkAttrNames;

export class HHDSLink extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");
    const href = this.vars.get(Attrs.href);
    const target = this.vars.get(Attrs.target);
    const hrefHtml = `${href?.length ? `href="${href}"` : ''}`;
    const targetHtml = `target="${target?.length ? target : "_self"}"`;
    this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<a class="hhds-link" ${hrefHtml} ${targetHtml}>
				<slot name="start"></slot>
        <span>
          <slot></slot>
        </span>
				<slot name="end"></slot>
			</a>
		`;
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.href]: {
    description: "The URL that the link points to.",
    defaultValue: "",
    type: String,
  },
  [Attrs.target]: {
    description:
      "Where to display the linked URL, as the name for a browsing context (a tab, window, or iframe).",
    defaultValue: "",
    type: String,
  },
  [Attrs.content]: {
    description: "Content",
    defaultValue: "",
    type: String,
  },
};
