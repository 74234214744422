import css from "./imageslider.scss?inline";
import globalStyles from "../../index.scss?inline";
import { ArgSpecDictionary } from "../component-utils";
import swiperStyles from "../../../node_modules/swiper/swiper-bundle.min.css?inline";
import Swiper from "swiper";
import { HHDSModal } from "../Modal/Modal";
import { Component } from "../../utils/Component";
import { HHDSImage } from "../Image/Image";
import { Scrollbar } from "swiper/modules";
import { ScrollbarOptions } from "swiper/types";

Swiper.use([Scrollbar]);

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSImageSlider";
export const HHDSImageSliderTagName: string = "hhds-imageslider";
//const TAG_NAME: string = HHDSImageSliderTagName;

export const HHDSImageSliderAttrNames = {
  transitionSpeed: "transition-speed",
};
const Attrs = HHDSImageSliderAttrNames;

export class HHDSImageSlider extends Component {
  private swiperContainer: HTMLElement | null = null;
  private swiperInstance: Swiper | null = null;
  private prevButton: HTMLElement | null = null;
  private nextButton: HTMLElement | null = null;
  private modal: HHDSModal | null = null;

  constructor() {
    super();
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");
  }

  protected override init(): void {
    const guid = Math.random().toString(36).substring(2, 15);
    this.shadow.innerHTML = `
		<style>${swiperStyles}</style>
		<style>${globalStyles}</style>
		<style>${css}</style>
		<slot class="slides"></slot>
		<div class="container">
			<div class="grid">
				<div class="wrapper">
					<div class="swiper ${HHDSImageSliderTagName} bottom-padding">
					  <div class="swiper-wrapper"></div>
            <div class="swiper-scrollbar-container">
              <div class="swiper-scrollbar"></div>
            </div>
            <hhds-button type="secondary" class="button-prev" role="" style="--button-secondary-border: 0;"><hhds-icon type="directionleft" slot="start" style="--icon-color: var(--color-neutral-grey-900);"></hhds-icon></hhds-button>
						<hhds-button type="secondary" class="button-next" role="" style="--button-secondary-border: 0;"><hhds-icon type="directionright" slot="start" style="--icon-color: var(--color-neutral-grey-900);"></hhds-icon></hhds-button>
				</div>
			</div>
		</div>
    <hhds-modal id="${guid}"></hhds-modal>
		`;

    // Obtain references
    this.swiperContainer = this.shadowRoot?.querySelector(".swiper") as HTMLElement;
    this.modal = this.shadowRoot?.querySelector("hhds-modal") as HHDSModal;
    if (!this.modal?.coordinator) this.modal?.createCoordinator();
    this.prevButton = this.shadow.querySelector(".button-prev");
    this.nextButton = this.shadow.querySelector(".button-next");

    this.nextButton?.setAttribute("tabindex", "-1");
    this.prevButton?.setAttribute("tabindex", "-1");

    // Copy all elements in the slot into .swiper-wrapper
    const slidesEl = this.shadowRoot?.querySelector(".slides");
    if (slidesEl && slidesEl instanceof HTMLSlotElement) {
      const swiperWrapper = this.shadowRoot?.querySelector(".swiper-wrapper");
      const elements = slidesEl.assignedElements();
      DEBUG_VERBOSE && console.log(`elements`, elements);
      elements.forEach((element, _index) => {
        element.classList.add("swiper-slide");
        element.setAttribute("animate", "true");
        swiperWrapper?.appendChild(element);
        element.addEventListener("click", (event) => this.onSlideClicked(event.currentTarget as HHDSImage));
      });
    }

    // Wait one frame, then initialize swiper
    requestAnimationFrame(() => this.initializeSwiper());
  }

  private onSlideClicked(hhdsImage: HHDSImage) {
    const src = hhdsImage.getAttribute("src");
    //this.modal?.setSlotContent(`<hhds-image src="${src}"></hhds-image>`);
    if (this.modal) {
      this.modal.innerHTML = `<hhds-image-lite src="${src}"></hhds-image-lite>`;
    }
    this.modal?.coordinator?.focus?.setPreTrapFocusedElement(hhdsImage);
    this.modal?.show();
  }

  override onAttributeChanged(_name: string, _oldValue: string, _newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed; reinitialising");
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }

  protected override destroy(): void {
    this.swiperContainer = null;
    this.modal = null;
    this.prevButton = null;
    this.nextButton = null;
    this.swiperInstance?.destroy(true, true);
    this.swiperInstance = null;
  }

  private initializeSwiper() {
    if (!this.swiperContainer) throw new Error("No swiperContainer");

    const speed = this.vars.get<number>(Attrs.transitionSpeed);

    const scrollbarElement = this.swiperContainer.querySelector(".swiper-scrollbar") as HTMLElement;
    const scrollbarOptions: ScrollbarOptions = {
      el: scrollbarElement,
      hide: false,
      draggable: false,
    };

    this.swiperInstance = new Swiper(this.swiperContainer, {
      loop: false,
      slideFullyVisibleClass: "swiper-slide-visible",
      slidesPerView: 1, // Set for smallest size
      scrollbar: scrollbarOptions,
      spaceBetween: 16, // Set for smallest size
      speed,
      breakpoints: {
        // when window width is >=
        1024: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        // when window width is >=
        1440: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
      },
      on: {
        slideChange: (swiper: Swiper) => {
          this.setButtonDisabledState();
          this.handleVisibleSlides();
          DEBUG_VERBOSE && console.log("slideChange", swiper);
        },
        resize: () => {
          this.handleVisibleSlides();
        },
      },
    });

    this.swiperInstance.init();

    this.setButtonDisabledState();

    this.nextButton?.addEventListener("click", () => {
      this.swiperInstance?.slideNext();
    });

    this.prevButton?.addEventListener("click", () => {
      this.swiperInstance?.slidePrev();
    });

    this.swiperInstance.slides.forEach((slide, index) => {
      slide.setAttribute("tabindex", `${index + 1}`);
      slide.addEventListener("keydown", function (event: KeyboardEvent) {
        if (event.key === "Enter" || event.key === " ") {
          event.preventDefault();
          slide.click();
        }
      });
      slide.addEventListener("focus", (_event: Event) => {
        this.setButtonDisabledState();
        this.handleVisibleSlides();
        this.swiperInstance?.slideTo(index);
      });

      const hhdsImage = slide as HHDSImage;
      hhdsImage.disableTabbing();
    });
  }

  private setButtonDisabledState() {
    const { isBeginning, isEnd } = this.swiperInstance as any;

    if (isBeginning == undefined || isEnd === undefined || !this.prevButton || !this.nextButton) {
      return;
    }

    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, this.setButtonDisabledState.name, `isBeginning`, isBeginning, `isEnd`, isEnd);

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    if (isBeginning) {
      this.prevButton.setAttribute("disabled", "true");
    } else {
      this.prevButton.removeAttribute("disabled");
    }

    this.prevButton.style.opacity = isBeginning ? "0" : "1";

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    if (isEnd) {
      this.nextButton.setAttribute("disabled", "true");
    } else {
      this.nextButton.removeAttribute("disabled");
    }

    this.nextButton.style.opacity = isEnd ? "0" : "1";

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  }

  private handleVisibleSlides() {
    if (!this.swiperInstance) throw new Error("No this.swiperInstance!");

    const { realIndex, slides } = this.swiperInstance;

    const slidesPerView = this.swiperInstance.params.slidesPerView as number;

    slides.forEach((slide, index) => {
      const min = realIndex;
      const max = realIndex + slidesPerView;
      const visible = index >= min && index < max;
      slide.classList.toggle("swiper-slide-visible", visible);
    });
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.transitionSpeed]: {
    description: `Duration of transition between slides (in ms)`,
    defaultValue: 300,
    type: Number,
  },
};
