import css from './mailchimpform.scss?inline';
import globalStyles from '../../index.scss?inline';
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSButton, HHDSButtonEvent } from "../Button/Button";
import { HHDSInput } from "../Input/Input";

const DEBUG_VERBOSE: boolean  = false;
const CLASS_NAME: string = 'HHDSMailchimpForm';
export const HHDSMailchimpFormTagName: string = "hhds-mailchimp-form";
const TAG_NAME: string = HHDSMailchimpFormTagName;

export class HHDSMailchimpForm extends Component {

  private submitButton!: HHDSButton;
  private emailInput!: HHDSInput;
  private formSuccess!: HTMLDivElement;
  private formError!: HTMLDivElement;
  private form!: HTMLFormElement;
  private formSmallPrint!: HTMLParagraphElement;

	private buttonClickFunc: any;

  constructor() {
    super();
    // The base class's constructor handles attachmennt of a shadow root and
    // adopted global styles. Access the shadow root via this.shadow.
    //
    // Use the constructor only for anything that will never need to be destroyed as part of the
    // component's update lifecycle. init() and destroy() are called for connectedCallback and
    // disconnectedCallback, and a destroy() init() pair is called if reinit() is utilised.

    this.buttonClickFunc = (_event: Event) => {
			this.submit();
		};
  }

  async submit() {
    const input = this.shadow.querySelector('hhds-input') as HTMLInputElement; 
    const email = input?.value;
    const formAction = this.form.action; // Get the form's action URL (Mailchimp URL)
    const formData = new FormData();
    formData.append("EMAIL", email);
    formData.append("b_XXXXXXXXX_XXXXXXXXX", ""); // Hidden field from the Mailchimp form (can be omitted if using API)

    // validation
    function validateEmail(email: String) {
      // Regular expression to validate email format
      const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return re.test(String(email).toLowerCase());
    }

    // Validate the email
    if (!validateEmail(email)) {
      this.formError.style.display = "block";
      this.emailInput.style.cssText = "--input-width: 100%; --input-border-color: var(--color-secondary-red-500); --input-border-color-focus: var(--color-secondary-red-500); --input-box-shadow-focus: var(--utilities-focus-error);";
      return;
    }
    
    // handling
    await fetch(formAction, {
        method: "POST",
        body: formData,
        headers: {
            "Accept": "application/json"
        },
        mode: 'no-cors' // Use no-cors mode if the server doesn’t support CORS
    }).then(() => {
      // Since no-cors limits response, assume success if no errors occur
      this.formSuccess.style.display = "flex";
      this.formError.style.display = "none";
      this.form.style.display = "none";
      this.formSmallPrint.style.display = "none";
    })
    .catch(error => {
        // Handle any errors that occur during the fetch
        this.formSuccess.style.display = "none";
        DEBUG_VERBOSE && console.error('There was an error!', error); 
    });
  }

  protected override init(): void {
    // The base class responds to connectedCallback() by collecting attributes into
    // this.vars, then calling init(). A call to super.init() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    // For some components, re-assigning innerHTML may be appropriate on attribute and slot changes,
    // and without the need for a reinit(). In other cases, assigning innerHTML explicitly as part of
    // the init() routine is more appropriate.
		this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
        <div class="${TAG_NAME}">
          <div id="mc_embed_signup">
            <form action="https://howardhughes.us14.list-manage.com/subscribe/post-json?u=a4dcf7f5e24ab07ebee8edf71&amp;id=18fc72c59f&c=?" method="get" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
              <div id="mc_embed_signup_scroll">
                <div class="mc-field-group">
                  <input type="hidden" value="website" name="SOURCE">
                  <input type="hidden" value="True" name="HOWARDHUGHES">
                  <div class="wrap-inputs">
                    <label for="mce-EMAIL" class="screen-reader-text">Email Address</label>
                    <hhds-input
                      type="email"
                      name="EMAIL"
                      required="true"
                      state="default"
                      placeholder="Email Address"
                      style="--input-width: 100%;"
                    ></hhds-input>
                    <hhds-button
                      type="secondary"
                      style="--button-secondary-bg-color: transparent;"
                    >SUBSCRIBE</hhds-button>
                    <div class="mce_inline_error hhds-mailchimp-form__error">Enter a valid email</div>
                  </div>
                </div>

                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div style="position: absolute; left: -5000px;"><input type="text" name="b_9684bbce9ec8413a5614ca7c3_116fd11541" tabindex="-1" value=""></div>
                <div class="clear text-center"></div>
              </div>
              <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_a4dcf7f5e24ab07ebee8edf71_18fc72c59f" tabindex="-1" value=""></div>
            </form>

            <div class="hhds-mailchimp-form__success hhds-mailchimp-form__success--hidden">
              <hhds-icon style="--icon-color: var(--color-secondary-green-500);" type="checkcircle"></hhds-icon>
              <p class="body">You've successfully signed-up to our mailing list</p>
            </div>
            </div>
  
            <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script>

            <p class="small small--footer">
              ${this.getAttribute('text')}
            </p>
        </div>
		`;

    // If the component uses slots, use observeSlotChanges().
    // this.observeSlotChanges(true);

    this.submitButton = this.shadow.querySelector("hhds-button") as HHDSButton;
    this.emailInput = this.shadow.querySelector("hhds-input") as HHDSInput;
    this.form = this.shadow.getElementById("mc-embedded-subscribe-form") as HTMLFormElement;
    this.formSuccess = this.shadow.querySelector(".hhds-mailchimp-form__success") as HTMLDivElement;
    this.formError = this.shadow.querySelector(".hhds-mailchimp-form__error") as HTMLDivElement;
    this.formSmallPrint = this.shadow.querySelector(".small") as HTMLParagraphElement;

		this.submitButton?.addEventListener(HHDSButtonEvent.click, this.buttonClickFunc);
  }

  protected override destroy(): void {
    // The base class responds to disconnectedCallback() by collecting attributes into
    // this.vars, then calling destroy(). A call to super.destroy() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    // If the component uses slots, stop observing slot changes.
    // this.observeSlotChanges(false);
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    // Either call reinit() to have the component's destroy and init methods each be called,
    // or skip this step and handle update of the attribute directly. 'this.vars' will already
    // have been updated by the base Component class, so it can be immediately used to access
    // the new value.
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    // The base Component class must have access to this superclass's ArgSpecs.
    return ArgSpecs;
  }

}

export const ArgSpecs: ArgSpecDictionary = {};

