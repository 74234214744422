import css from './homesgrid.scss?inline';
import globalStyles from '../../index.scss?inline';
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";

const DEBUG_VERBOSE: boolean  = false;
const CLASS_NAME: string = 'HHDSHomesGrid';
export const HHDSHomesGridTagName: string = "hhds-homesgrid";

export const HHDSHomesGridAttrNames = {
  title: "title",
  categories: "categories"
};

const Attrs = HHDSHomesGridAttrNames;

interface Home {
  name: string;
  description: string;
  thumbnail: string;
  post: string;
  content: string;
}

type HomesResponse = Home[];
interface WPAjaxObject {
  ajax_url: string;
  nonce: string;
}

declare var ajax_obj: WPAjaxObject;
export class HHDSHomesGrid extends Component {

  constructor() {
    super();
    // The base class's constructor handles attachmennt of a shadow root and
    // adopted global styles. Access the shadow root via this.shadow.
    //
    // Use the constructor only for anything that will never need to be destroyed as part of the
    // component's update lifecycle. init() and destroy() are called for connectedCallback and
    // disconnectedCallback, and a destroy() init() pair is called if reinit() is utilised.


  }

  protected override init(): void {
    // The base class responds to connectedCallback() by collecting attributes into
    // this.vars, then calling init(). A call to super.init() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    // const argValue = this.vars.get<number>(Attrs.myNumericArg);
    // console.log(`${Attrs.myNumericArg}: ${argValue} (type: ${typeof argValue})`);

    // For some components, re-assigning innerHTML may be appropriate on attribute and slot changes,
    // and without the need for a reinit(). In other cases, assigning innerHTML explicitly as part of
    // the init() routine is more appropriate.

    const title = this.vars.get<string>(Attrs.title);

    let titleHTML = title ? `<hhds-richtext class="col-span-3 sm:col-span-4 md:col-span-6">
      <h3>${title}</h3>
    </hhds-richtext>` : ``; 

		this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
      <div class="container">
        <div class="grid grid--homes grid--homes--spacing" ${title ? 'style="--padding-top: 0;"' : ''}>
          ${titleHTML}
          <div class="grid--homes--filter col-span-6 sm:col-span-4 md:col-span-6">
            <slot name="filter"></slot>
          </div>
          <slot name="content"></slot>
        </div>
      </div>
    `;

    // Add our grid classes to the slot elements

    const filterSlot = this.shadowRoot?.querySelector('slot[name="filter"]') as HTMLSlotElement;
    const filterContent = filterSlot?.assignedNodes({ flatten: true });    
    filterContent?.forEach((node: Node) => {
      if (node.nodeType === Node.ELEMENT_NODE && (node as HTMLElement).tagName.toLowerCase() === 'hhds-select') {
          const element = node as HTMLElement;
          element.classList.add("col-span-6", "sm:col-span-4", "md:col-span-6");
      }
    });

    const contentSlot = this.shadowRoot?.querySelector('slot[name="content"]') as HTMLSlotElement;
    const slottedContent = contentSlot?.assignedNodes({ flatten: true });    
    slottedContent?.forEach((node: Node) => {
      if (node.nodeType === Node.ELEMENT_NODE && (node as HTMLElement).tagName.toLowerCase() === 'hhds-tile') {
          const element = node as HTMLElement;
          element.classList.add("col-span-3", "sm:col-span-4", "md:col-span-4");
      }
    });

    this.addListenerToFilterSlot();

  }

  private addListenerToFilterSlot() { 

    const filterSlot = this.shadowRoot?.querySelector('slot[name="filter"]') as HTMLSlotElement;  
    const select = filterSlot.assignedElements()[0] as HTMLSelectElement; 
    select.addEventListener('selectChanged', (event: Event) => {
      const customEvent = event as CustomEvent;
      if (customEvent.detail) {
        this.filterGrid(customEvent.detail);    
      }
    });

  }

  private async filterGrid(communityId: string) {

    const contentSlot = this.shadowRoot?.querySelector('slot[name="content"]') as HTMLSlotElement;

    if (contentSlot) {

      const homes = await this.fetchHomes(communityId);

      // Fetch the currently assigned nodes to the slot
      const assignedNodes = contentSlot.assignedNodes();
      
      // Remove all elements assigned to the slot
      assignedNodes.forEach((node: Node) => {
          if (node instanceof HTMLElement) {
              node.remove();
          }
      });

      while (contentSlot.firstChild) {
        contentSlot.removeChild(contentSlot.firstChild);
      }
    
      if (homes) {
        homes.forEach((home) => {
          this.createTile(home);  
        });
      }

    }

  }

  private createTile(data: any) {  

      const contentSlot = this.shadowRoot?.querySelector('slot[name="content"]');

      // Create an instance of the custom component
      const tile = document.createElement('hhds-tile');
      tile.innerHTML = `
        <hhds-image animate="true" src="${data.thumbnail}" alt="" style="--image-aspect-ratio: 4/3; --image-object-fit: cover;" slot="image"></hhds-image>
      `;

      if (data.external) {
        tile.innerHTML += `<hhds-icon slot="icon" type="arrowupright"></hhds-icon>`;
      }

      tile.setAttribute('slot', 'content');
      tile.setAttribute('post', data.post); 
      tile.setAttribute('type', 'press');
      tile.setAttribute('date', data.community_name);
      tile.setAttribute('label', data.name);
      tile.setAttribute('url', data.link);      
      tile.setAttribute('target', data.external ? '_blank' : '_self');        
      tile.classList.add("col-span-3","sm:col-span-4","md:col-span-4");

      // Append the custom component to the body (or any other container in the DOM)
      contentSlot?.appendChild(tile);

  };

  async fetchHomes(communityId: string): Promise<HomesResponse | null> { 

    const catIds = this.vars.get<string>(Attrs.categories);

      try {


        const response = await fetch(ajax_obj.ajax_url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
              action: 'fetch_homes',
              categories: catIds,
              community_id: communityId,
              nonce: ajax_obj.nonce,
          })
        });

        const result = await response.json();

        if (result.success) {
            return result.data;
        } else {
          console.error("Fetch request failed.");  
          return null;
        }

      } catch (error) {
        console.error("Failed to fetch person data.", error);  
        return null;
      }

  }


  protected override destroy(): void {
    // The base class responds to disconnectedCallback() by collecting attributes into
    // this.vars, then calling destroy(). A call to super.destroy() is not required.
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    // If the component uses slots, stop observing slot changes.
    // this.observeSlotChanges(false);
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    // Either call reinit() to have the component's destroy and init methods each be called,
    // or skip this step and handle update of the attribute directly. 'this.vars' will already
    // have been updated by the base Component class, so it can be immediately used to access
    // the new value.
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    // The base Component class must have access to this superclass's ArgSpecs.
    return ArgSpecs;
  }

}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.title]: {
    description: "An optional title for the grid",
    defaultValue: '',
    type: String,
  },
  [Attrs.categories]: {
    description: "The categories to filter by",
    defaultValue: '',
    type: String,
  }
};

