import css from "./tag.scss?inline";
import globalStyles from "../../index.scss?inline";
import { ArgSpecDictionary } from "../component-utils";
import { Component } from "../../utils/Component";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "[HHDSTag]";
//const TAG_NAME: string = "hhds-tag";
export const HHDSTagTagName: string = "hhds-tag";

export class HHDSTag extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<span class="hhds-tag sub-heading"><slot></slot></span>
		`;
  }

  protected override destroy(): void {}

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {};
