import css from "./imagelite.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSImageLite";
export const HHDSImageLiteTagName: string = "hhds-image-lite";
//const TAG_NAME: string = HHDSImageLiteTagName;

export const HHDSImageLiteAttrNames = {
  src: "src",
  srcSet: "srcset",
  sizes: "sizes",
  alt: "alt",
};

const Attrs = HHDSImageLiteAttrNames;

export class HHDSImageLite extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "init");

    const srcSet = this.vars.get<string>(Attrs.srcSet);
    const srcSetHtml = srcSet?.length ? ` srcset="${srcSet}"` : ``;
    const sizes = this.vars.get<string>(Attrs.sizes);
    const sizesHtml = sizes?.length ? ` sizes="${sizes}"` : ``;
    const alt = this.vars.get<string>(Attrs.alt);
    const altHtml = alt?.length ? ` alt="${alt}"` : ``;

    this.shadow.innerHTML = `
      <style>${globalStyles}</style>
			<style>${css}</style>
			<img
        src="${this.vars.get<string>(Attrs.src)}"
        ${altHtml}
        ${srcSetHtml}
        ${sizesHtml}
      />
		`;
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.src]: {
    description: "The image source URL.",
    defaultValue: "",
    type: String,
  },
  [Attrs.srcSet]: {
    description: "The image source set.",
    defaultValue: "",
    type: String,
  },
  [Attrs.sizes]: {
    description: "The image sizes.",
    defaultValue: "",
    type: String,
  },
  [Attrs.alt]: {
    description: "The alt text for the image.",
    defaultValue: "Applicable description ",
    type: String,
  },
};
