export enum HHDSTileGridSource {    
    People = 'people',
    Office = 'office',
    Location = 'location',  
    Community = 'community',
    Home = 'home',
    Press = 'press',
    Insight = 'insight',
    Default = 'custom'
}

export enum HHDSTileGridType {
    Posts = 'dynamic',
    SelectedPosts = 'filtered',
    Category = 'category',
    Custom = 'custom' 
}