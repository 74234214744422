export type SlotChangeCallback = (slot: HTMLSlotElement, target?: HTMLElement) => void;

export function watchSlot(shadow: ShadowRoot, onChange: SlotChangeCallback, checkNow: boolean = true): void {
  const slot = shadow.querySelector("slot") as HTMLSlotElement;
  if (!slot) return;
  slot.addEventListener("slotchange", (e: any) => onChange(slot, e.target));
  if (checkNow) onChange(slot);
}

export function parseAttributesAsTypedVars(
  component: HTMLElement,
  argSpecs: ArgSpecDictionary,
  vars: TypedVars
): void {
  const urlParams = new URLSearchParams(window.location.search);

  const parseAttribute = (key: string, type: any): any => {
    let value = component.getAttribute(key)!;
    if (type == "boolean") return value == "true" || value == "TRUE" || value == "1";
    if (type === "number") return parseFloat(value);
    if (type === "object") return JSON.parse(value);
    return value;
  };

  const allowUrlParams = false;
  const addParam = (key: string, type: any) => {
    let value: any;
    if (allowUrlParams && urlParams.has(key)) {
      value = urlParams.get(key);
      console.log(`Setting '${key}' via query string: ${urlParams.get(key)}`);
    } else if (component.hasAttribute(key)) {
      value = parseAttribute(key, type);
    } else {
      value = argSpecs[key].defaultValue;
    }
    vars.set(key, value, type);
  };

  Object.entries(argSpecs).forEach(([key, argSpec]: [string, any]) => {
    addParam(key, resolveTypeString(argSpec));
  });
}

export type TypedVar = { type: string; value: any };

export class TypedVars {
  vars: { [key: string]: TypedVar } = {};

  constructor(_element: HTMLElement) {}

  parse(component: HTMLElement, argSpecs: ArgSpecDictionary): void {
    this.vars = {};
    parseAttributesAsTypedVars(component, argSpecs, this);
  }

  log(): void {
    Object.entries(this.vars).forEach(([key, value]: [string, TypedVar]) => {
      console.log(`  ${key}: ${value.value}`);
    });
  }

  destroy(): void {
    this.vars = {};
  }

  update(key: string, value: any, argSpecs: ArgSpecDictionary, vars: TypedVars): void {
    const type = resolveTypeString(argSpecs[key]);
    if (type) {
      vars.set(key, value, type);
    }
  }

  set(key: string, value: any, type: string): void {
    switch (type) {
      case "boolean":
        if (value === null) {
          value = false;
        } else if (value !== undefined) {
          value = value == "true" || value == "TRUE" || value == "1";
        }
        this.vars[key] = {
          type: "boolean",
          value: value,
        };
        break;
      case "number":
        this.vars[key] = { type: "number", value: value === null ? null : parseFloat(value) };
        break;
      case "object":
        this.vars[key] = { type: "object", value: JSON.stringify(value) };
        break;
      case "string":
        this.vars[key] = { type: "string", value: value };
        break;
      default:
        this.vars[key] = { type: "string", value: value.toString() };
    }
  }

  get<T>(key: string): any {
    return this.vars[key].value as T;
  }

  has(key: string): boolean {
    return this.vars[key] != null;
  }
}

export interface ArgSpec<T> {
  description: string;
  category?: string;
  defaultValue: T;
  type: T;
  typeString?: string;
}

export function getArgs(dictionary: ArgSpecDictionary, additionalArgs: { [name: string]: any }): any {
  let args: any = {};
  Object.entries(additionalArgs).forEach(([key, value]: [string, any]) => {
    args[key] = value;
  });
  Object.entries(dictionary).forEach(([key, argSpec]: [string, ArgSpec<any>]) => {
    // if the argSpec type is string, we need to set the value in args too, otherwise the control won't work
    // get a description of argSpec.type
    if (args[key] === undefined) {
      const typeString = resolveTypeString(argSpec);
      if (typeString == "string") args[key] = argSpec.defaultValue;
    }
  });
  return args;
}

/*function isEnum(obj: any): boolean {
  if (typeof obj !== "object" || obj === null) return false;
  const keys = Object.keys(obj);
  const values = Object.values(obj);
  // Check if there is at least one key that maps to a value and one value that maps to a key
  return (
    keys.some((key) => typeof obj[key] === "number" || typeof obj[key] === "string") &&
    values.some((value) => keys.includes(value!.toString()))
  );
}*/

function isEnum(obj: any): boolean {
  if (typeof obj !== "object" || obj === null) return false;

  const keys = Object.keys(obj);
  const values = Object.values(obj);

  // Check if all values are of type number or string
  const allValuesAreStringsOrNumbers = values.every(
    (value) => typeof value === "string" || typeof value === "number"
  );

  // Check if all keys are of type string
  const allKeysAreStrings = keys.every((key) => typeof key === "string");

  return allKeysAreStrings && allValuesAreStringsOrNumbers;
}

export function resolveTypeString(argSpec: ArgSpec<any>): string | undefined {
  if (argSpec.typeString) return argSpec.typeString;
  switch (argSpec.type) {
    case Boolean:
      return "boolean";
    case Number:
      return "number";
    case String:
      return "string";
    case Object:
      return "object";
    default:
      return "string";
  }
}

export function getArgSpecs(dictionary: ArgSpecDictionary): any {
  let argSpecs: { [name: string]: any } = {};
  Object.entries(dictionary).forEach(([key, argSpec]: [string, ArgSpec<any>]) => {
    const typeString = resolveTypeString(argSpec);

    const argDefinition: any = {};
    argDefinition.description = argSpec.description;
    argDefinition.table = {};
    argDefinition.table.category = argSpec.category;
    argDefinition.table.type = { summary: typeString };

    if (argSpec.type === Object) {
      argDefinition.table.defaultValue = { summary: JSON.stringify(argSpec.defaultValue) };
    } else {
      argDefinition.table.defaultValue = {
        summary: argSpec.defaultValue === null ? "null" : argSpec.defaultValue,
      };
    }

    const typeIsAnEnum = isEnum(argSpec.type);

    argSpec.typeString ||= typeString;

    if (typeIsAnEnum) {
      argDefinition.table.type.summary = typeString;
      argDefinition.control = { type: "select" };
      argDefinition.options = Object.values(argSpec.type);
    } else if (argSpec.type === Object) {
      argDefinition.control = { type: "object" };
    } else if (typeString != "string") {
      // The control type should not be supplied if we're dealing with a string
      argDefinition.control = { type: typeString };
    }
    argSpecs[key] = argDefinition;
  });
  return argSpecs;
}

export function isIOS(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    return /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream;
}

export type ArgSpecDictionary = Record<string, ArgSpec<any>>;
