import AOS from 'aos';
import "./scss/aos/aos.scss";


// Some elements (mostly modals) are broken by AOS animations, so this is a temporary exclusion list until we figure out how to address this
const DISABLED_ANIMATION = [

	"hhds-hero",
	"hhds-imageslider",
	"hhds-peoplecarousel",
	"hhds-peoplegrid"

];

function initializeAnimations() {

	const container = document.querySelector('[data-router-view="root"]');
	const hhdsElements = container ? Array.from(container.children).filter((child): child is HTMLElement => child.tagName.toLowerCase().startsWith('hhds-')) : [];

	hhdsElements.forEach((element) => {
			if (element instanceof HTMLElement) {

				const elementTag = element.tagName.toLowerCase();
				if (!DISABLED_ANIMATION.includes(elementTag)) {
					element.dataset.aos = "fade";
				}

			}

	});

	AOS.init({
		offset: 120,
		once: true,
		duration: 750
	});

}

if (document.readyState === 'complete') {
	initializeAnimations();
} else {
	window.addEventListener('load', initializeAnimations);
}
