import css from './badgegroup.scss?inline';
import globalStyles from '../../index.scss?inline';	
import { ArgSpecDictionary, TypedVars } from "../component-utils";

const DEBUG_VERBOSE: boolean  = false;
const CLASS_NAME: string = 'HHDSBadgeGroup';
const TAG_NAME: string = 'hhds-badgegroup';
export const HHDSBadgeGroupTagName: string = "hhds-badgegroup";

// ////////////////////////////////////////////////////////////////////

export class HHDSBadgeGroup extends HTMLElement {

  private vars: TypedVars = new TypedVars(this);
	private shadow: ShadowRoot;	

	constructor() {
		super();
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'constructed');

		this.shadow = this.attachShadow({ mode: 'open' });
		if (!this.shadow) {
			return;
		}

	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// Lifecycle Methods
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {

		this.parseAttributes();

		this.render();

		console.log(`[${TAG_NAME}] Initialised`);
	}

  parseAttributes() {
    this.vars.parse(this, ArgSpecs);
	}

	render() {
		this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="hhds-badgegroup"><slot></slot></div>
		`;
	}

	// Invoked each time the custom element is disconnected from the document's DOM.
	disconnectedCallback() {}

	// Invoked each time the custom element is moved to a new document.
	adoptedCallback() {}

	// Invoked each time one of the custom element's attributes is added, removed, or changed.
	attributeChangedCallback() {}

}

export const ArgSpecs: ArgSpecDictionary = {};

