export class VideoPlayCursor {
  private mouseMoveFunc: any;
  private parentElement: HTMLElement | null = null;
  private deadzonePaddingPx: number = 10;
  private xOffsetPx: number = 0;
  private yOffsetPx: number = 0;
  private updateIntervalId: number | null = null;
  private movingElement: HTMLElement | null = null;
  private inDeadzone: boolean = false;
  private obstacles: Set<HTMLElement> = new Set();

  private _overlappingAnyObstacle: boolean = false;
  private trueMouseX: number = 0;
  private trueMouseY: number = 0;
  private virtualMouseX: number = 0;
  private virtualMouseY: number = 0;
  private movingElementFixedSize: number | null = null;

  onDeadzoneEnter?: () => void;
  onDeadzoneExit?: () => void;
  onObstacleEnter?: () => void;
  onObstacleLeave?: () => void;
  onMove?: (x: number, y: number) => void;

  constructor(
    movingElement: HTMLElement,
    parentElement: HTMLElement,
    xOffsetPx: number = 0,
    yOffsetPx: number = 0,
    movingElementFixedSize: number | null = null
  ) {
    movingElement.style.position = "absolute";
    this.xOffsetPx = xOffsetPx;
    this.yOffsetPx = yOffsetPx;

    this.movingElement = movingElement;
    this.movingElementFixedSize = movingElementFixedSize;

    this.mouseMoveFunc = (event: MouseEvent) => {
      const rect = parentElement.getBoundingClientRect();
      this.trueMouseX = event.clientX - rect.left - movingElement.clientWidth / 2;
      this.trueMouseY = event.clientY - rect.top - movingElement.clientHeight / 2;
    };
    this.setPaused(false);
    this.parentElement = parentElement;
  }

  addObstacle(element: HTMLElement) {
    this.obstacles.add(element);
  }

  removeObstacle(element: HTMLElement) {
    this.obstacles.delete(element);
  }

  clearObstacleState(): void {
    this._overlappingAnyObstacle = false;
  }

  setHidden(hidden: boolean) {
    if (this.movingElement) this.movingElement.classList.toggle("hidden", hidden);
  }

  get overlappingAnyObstacle(): boolean {
    return this._overlappingAnyObstacle;
  }

  private handleDeadzone(mouseX: number, mouseY: number, rect: DOMRect, movingElement: HTMLElement) {
    const deadzoneX =
      mouseX < this.deadzonePaddingPx ||
      mouseX > rect.width - movingElement.clientWidth - this.deadzonePaddingPx;
    const deadzoneY =
      mouseY < this.deadzonePaddingPx ||
      mouseY > rect.height - movingElement.clientHeight - this.deadzonePaddingPx;
    if (deadzoneX || deadzoneY) {
      if (!this.inDeadzone) {
        this.inDeadzone = true;
        this.onDeadzoneEnter && this.onDeadzoneEnter();
      }
    } else {
      if (this.inDeadzone) {
        this.inDeadzone = false;
        this.onDeadzoneExit && this.onDeadzoneExit();
      }
    }
  }

  setPaused(paused: boolean) {
    if (paused) {
      if (this.updateIntervalId) {
        clearInterval(this.updateIntervalId);
        this.updateIntervalId = null;
      }
      this.parentElement?.removeEventListener("mousemove", this.mouseMoveFunc);
    } else {
      if (this.updateIntervalId) {
        clearInterval(this.updateIntervalId);
      }
      this.updateIntervalId = window.setInterval(() => {
        this.update();
      }, 1000 / 60);
      this.parentElement?.addEventListener("mousemove", this.mouseMoveFunc);
    }
  }

  private doObjectsOverlap(
    x1: number,
    y1: number,
    radius1: number,
    x2: number,
    y2: number,
    radius2: number
  ): boolean {
    const dx = x1 - x2;
    const dy = y1 - y2;
    const distance = Math.sqrt(dx * dx + dy * dy);
    return distance < radius1 + radius2;
  }

  private handleObstacleOverlap() {
    let overlappingNow: boolean = false;
    const thisRect = this.movingElement?.getBoundingClientRect();
    if (!thisRect) return;
    const thisCx = thisRect.left + thisRect.width / 2;
    const thisCy = thisRect.top + thisRect.height / 2;
    const thisRadius = this.movingElementFixedSize || Math.max(thisRect.width, thisRect.height) / 2;
    for (let obstacle of this.obstacles) {
      const obstacleRect = obstacle.getBoundingClientRect();
      const obstacleCx = obstacleRect.left + obstacleRect.width / 2;
      const obstacleCy = obstacleRect.top + obstacleRect.height / 2;
      const obstacleRadius = Math.max(obstacleRect.width, obstacleRect.height) / 2;
      const overlap = this.doObjectsOverlap(
        obstacleCx,
        obstacleCy,
        obstacleRadius,
        thisCx,
        thisCy,
        thisRadius
      );
      if (overlap) {
        overlappingNow = true;
        break;
      }
    }
    if (overlappingNow && !this._overlappingAnyObstacle) {
      this.onObstacleEnter && this.onObstacleEnter();
    } else if (!overlappingNow && this._overlappingAnyObstacle) {
      this.onObstacleLeave && this.onObstacleLeave();
    }
    this._overlappingAnyObstacle = overlappingNow;
  }

  get overlappingObstacle(): boolean {
    return this._overlappingAnyObstacle;
  }

  private update() {
    if (!this.movingElement || !this.parentElement) return;
    const rect = this.parentElement.getBoundingClientRect();

    const mouseX = this.trueMouseX;
    const mouseY = this.trueMouseY;

    this.virtualMouseX += (mouseX - this.virtualMouseX) * 0.2;
    this.virtualMouseY += (mouseY - this.virtualMouseY) * 0.2;

    this.movingElement.style.left = this.virtualMouseX + this.xOffsetPx + "px";
    this.movingElement.style.top = this.virtualMouseY + this.yOffsetPx + "px";

    this.handleObstacleOverlap();
    this.handleDeadzone(this.virtualMouseX, this.virtualMouseY, rect, this.movingElement);

    if (!this.inDeadzone) this.onMove && this.onMove(this.trueMouseX, this.trueMouseY);
  }

  dispose(): void {
    this.parentElement?.removeEventListener("mousemove", this.mouseMoveFunc);
    this.parentElement = null;
    this.movingElement = null;
    this.mouseMoveFunc = null;
    this.obstacles.clear();
  }
}
