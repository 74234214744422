import css from "./accordionlayouta.scss?inline";
import globalStyles from "../../index.scss?inline";
import { Component } from "../../utils/Component";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSAccordion } from "../Accordion/Accordion";
import { HHDSAccordionItem, HHDSAccordionItemEvent } from "../AccordionItem/AccordionItem";
import { HHDSLayeredImage, HHDSLayeredImageTagName } from "../LayeredImage/LayeredImage";
import { HHDSImage, HHDSImageEvent, HHDSImageTagName } from "../Image/Image";

const DEBUG_VERBOSE: boolean = false;
export const HHDSAccordionLayoutATagName: string = "hhds-accordion-layout-a";
const TAG_NAME: string = HHDSAccordionLayoutATagName;
const LOG_PREFIX: string = `[${TAG_NAME}]`;

export const HHDSAccordionLayoutAAttrNames = {};

//const Attrs = HHDSAccordionLayoutAAttrNames;

export class HHDSAccordionLayoutA extends Component {
  constructor() {
    super();
  }

  protected override init(): void {
    DEBUG_VERBOSE && console.log(LOG_PREFIX, "init");

    this.shadow.innerHTML = `
			<style>${globalStyles}</style>
			<style>${css}</style>
			<div class="${TAG_NAME}">
      <div class="container container--accordion-layout-a">
        <slot name="badge"></slot>
        <div class="grid grid--accordion-layout-a">
          <div class="col-start-1 col-span-6 sm:col-span-4">
            <hhds-richtext><h4 class="heading heading--no-margin"><slot name="heading"></slot></h4></hhds-richtext>
          </div>
          <div class="col-start-1 md:col-start-9 lg:col-start-10 col-span-5 sm:col-span-4 md:col-span-4 lg:col-span-3">
            <div id="image-container" class="hhds-accordion-layout-a__image image-container-removed">
            </div>
          </div>
          <div class="col-span-6 sm:col-span-4 sm:col-start-5">
            <slot name="accordion"></slot>
          </div>
        </div>
      </div>
		`;

    this.observeSlotChanges(true);
  }

  private addSlotListeners(slot: HTMLSlotElement, elements: Element[]): void {
    if (slot.name === "accordion") {
      const accordion = elements[0] as HHDSAccordion;
      accordion.addEventListener(HHDSAccordionItemEvent.open, (event: any) => {
        DEBUG_VERBOSE && console.log(LOG_PREFIX, "EVENT RECEIVED: Accordion item opened");
        const item = event.detail.item as HHDSAccordionItem;
        const userInvoked = event.detail.userInvoked;
        const associatedImageUrl = item.getAttribute("data-image");
        if (associatedImageUrl) {
          const imageContainer = this.shadow.querySelector("#image-container") as HTMLElement;
          const existingLayeredImages = this.shadow.querySelectorAll(
            HHDSLayeredImageTagName
          ) as NodeListOf<HHDSLayeredImage>;
          const layeredImage = document.createElement(HHDSLayeredImageTagName) as HHDSLayeredImage;
          layeredImage.innerHTML = `<hhds-image slot="image" src="${associatedImageUrl}"></hhds-image>`;
          imageContainer.appendChild(layeredImage);
          layeredImage.style.display = "none";
          const slottedHhdsImage = layeredImage.getSlotted<HHDSImage>(HHDSImageTagName);
          if (slottedHhdsImage) {
            slottedHhdsImage.addEventListener(HHDSImageEvent.load, (_event: any) => {
              if (existingLayeredImages?.length > 0) {
                DEBUG_VERBOSE &&
                  console.log("[AccordionLayoutA] Image loaded; removing existing layered images");
                existingLayeredImages.forEach((existingLayeredImage) => existingLayeredImage.remove());
                layeredImage.style.display = "block";
              } else {
                layeredImage.style.display = "block";
                DEBUG_VERBOSE && console.log(LOG_PREFIX, "First image loaded");
              }
            });
          } else {
            layeredImage.style.display = "block";
          }
          if (userInvoked) {
            imageContainer.classList.toggle("offset", true);
            imageContainer.classList.toggle("animate-offset", false);
            requestAnimationFrame(() => {
              imageContainer.classList.toggle("offset", false);
              imageContainer.classList.toggle("animate-offset", true);
            });
          }
        }
      });
    }
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(LOG_PREFIX, "destroy");
    this.observeSlotChanges(false);
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(LOG_PREFIX, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(slot: HTMLSlotElement, elements: Element[]): void {
    this.addSlotListeners(slot, elements);
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }
}

export const ArgSpecs: ArgSpecDictionary = {};
